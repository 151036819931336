import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Paper,
  Typography, Button, Toolbar, TextField, Dialog, DialogTitle, DialogContent,
  DialogActions, List, ListItem, ListItemText
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import { client as apolloClient } from '../client';
import { QSQL, QSQLVariables } from '../gen/QSQL';
import { ApolloQueryResult, ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import { QCustomReport, QCustomReportVariables } from '../gen/QCustomReport';
import { onGenericError } from '../lib/errorReporter';
import moment from 'moment';
import CompanyName from '../widgets/companyName';
import queryString from 'query-string';

interface ReportsParams {
  company: string;
}

interface Props extends RouteComponentProps<ReportsParams>, WithStyles<typeof styles> { }

interface ReportsState {
  sql: string;
  sqlResult: string;
  result: QueryResult | null;
  openWizard: boolean;
}

// This interface is also defined at server where results are generated
export interface QueryResult {
  columns: string[];
  rows: Array<Array<string | number | null>>;
  truncated: boolean;
}

const styles = (theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
  },
  appsTitle: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
  }
});

class Reports extends React.Component<Props, ReportsState> {
  state = {
    sql: '',
    sqlResult: '', // sql that produced the latest valid result (used for download)
    result: null,
    openWizard: false,
  } as ReportsState;
  componentDidMount() {
    this.parseSearch();
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.search !== this.props.location.search) {
      this.parseSearch();
    }
  }
  parseSearch() {
    // tslint:disable-next-line:no-string-literal
    const sql = queryString.parse(this.props.location.search)['sql'] as string || '';
    this.setState({ sql }, () => {
      this.runSQL();
    });

  }

  runSQL = async () => {
    const sql = this.state.sql;
    if (sql.length > 0 && sql.trim().length > 0) {
      const result = await executeSQL(this.props.match.params.company, sql);
      if (result) {
        this.setState({ result, sqlResult: sql });
      }
    }
  }
  resetState = () => {
    this.setState({
      sql: '',
      sqlResult: '',
      result: null,
      openWizard: false,
    });
  }
  downloadSQL = async () => {
    const result = await downloadSQL(this.props.match.params.company, this.state.sqlResult);
    if (result) {
      window.location.href = result;
    }
  }
  render = () => {
    const classes = this.props.classes;
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.appsTitle}>
          Generación de informes de <CompanyName company={this.props.match.params.company} />
        </Typography>
        <Toolbar className={classes.toolbar} variant="regular" disableGutters={false}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => { this.setState({ openWizard: true }); }}
          >
            Informes predefinidos
          </Button>
          {
            (this.state.sql.length > 0 || this.state.result != null) ? (
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.resetState}
              >
                Limpiar
              </Button>
            ) : null
          }
        </Toolbar>
        {this.state.openWizard ?
          <ReportWizard
            onResult={(sql?: string) => {
              if (sql) {
                this.setState({
                  sql,
                  openWizard: false,
                  sqlResult: '',
                  result: null,
                });
              } else {
                this.setState({ openWizard: false });
              }
            }}
          /> : null}
        <div>
          <TextField
            value={this.state.sql}
            label="Consulta"
            multiline={true}
            rowsMax={10}
            fullWidth={true}
            margin="normal"
            onChange={(e) => { this.setState({ sql: e.target.value }); }}
          />
        </div>
        <Toolbar className={classes.toolbar} variant="regular" disableGutters={false}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={this.runSQL}
            disabled={this.state.sql === '' || this.state.sql.trim() === ''}
          >
            Previsualizar informe
          </Button>
          {this.state.result != null ? (
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.downloadSQL}
            >
              Descargar informe completo
            </Button>
          ) : null}
        </Toolbar>
        {
          this.state.result && (
            <ReportResult result={this.state.result} />
          )
        }
      </Paper>

    );
  }
}
export default withStyles(styles)(Reports);

interface ReportWizardProps {
  onResult: (result?: string) => void;
}
interface ReportWizardState {
  type?: 'docs' | 'tasks' | 'journal' | 'iva_expedidas' | 'iva_recibidas' | 'assets' | 'retenciones';
  fromDate?: string;
  toDate?: string;
}

function getOptions(): Array<{ label: string, sql: string }> {
  return [
    {
      label: 'Documentos',
      sql: `select 
        * 
      from docs 
      where 
        date>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and date<='${moment().endOf('year').format('YYYY-MM-DD')}'`,
    },
    {
      label: 'Tareas',
      sql: `select * from tasks`,
    },
    {
      label: 'Diario',
      sql: `select 
        * 
      from journal 
      where 
        date>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and date<='${moment().endOf('year').format('YYYY-MM-DD')}'`,
    },
    {
      label: 'Libro oficial de IVA de facturas expedidas',
      sql: `select 
        strftime('%d/%m/%Y',fecha_emision) as 'Fecha expedición', 
        '' as 'Fecha operación', 
        serie as 'Serie (Identificación de la factura)', 
        num as 'Número (Identificación de la factura)', 
        '' as 'Número final (Identificación de la factura)', 
        contraparte_id_type as 'Tipo (NIF Destinatario)', 
        contraparte_pais as 'Código País (NIF Destinatario)', 
        contraparte_id as 'Identificación (NIF Destinatario)', 
        contraparte_nombre as 'Nombre Destinatario', 
        '' as 'Factura sustitutiva',  
        '' as 'Clave de operación', 
        printf("%.2f", importe_total/100.0) as 'Total factura', 
        printf("%.2f", base_imponible/100.0) as 'Base imponible',  
        printf("%.2f", tipo_impositivo*100.0) as 'Tipo de IVA',  
        printf("%.2f", cuota_repercutida/100.0) as 'Cuota IVA repercutida',  
        printf("%.2f", tipo_re*100.0) as 'Tipo de recargo eq.',  
        printf("%.2f", cuota_re/100.0) as 'Cuota recargo eq.',  
        '' as 'Fecha (Cobro)',
        '' as 'Importe (Cobro)', 
        '' as 'Medio utilizado (Cobro)', 
        '' as 'Identificación Medio Utilizado (Cobro)' 
      from iva_expedidas  
      where 
        fecha_emision>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and fecha_emision<='${moment().endOf('year').format('YYYY-MM-DD')}' 
      order by fecha_emision asc, serie asc, num asc`,
    },
    {
      label: 'Libro oficial de IVA de facturas recibidas',
      sql: `select 
        strftime('%d/%m/%Y',fecha_registro_contable) as 'Fecha registro contable', 
        strftime('%d/%m/%Y',fecha_emision) as 'Fecha expedición', 
        '' as 'Fecha operación', 
        numyserie as 'Serie-Número (Identificación Factura del Expedidor)',  
        '' as 'Número-final (Identificación Factura del Expedidor)', 
        '' as 'Número recepción', 
        '' as 'Número recepción final', 
        emisor_id_type as 'Tipo (NIF Expedidor)', 
        emisor_pais as 'Código País (NIF Expedidor)', 
        emisor_id as 'Identificación (NIF Expedidor)',  
        emisor_nombre as 'Nombre Expedidor', 
        '' as 'Factura sustitutiva',  
        '' as 'Clave de operación', 
        printf("%.2f", importe_total/100.0) as 'Total factura',  
        printf("%.2f", base_imponible/100.0) as 'Base imponible',  
        printf("%.2f", tipo_impositivo*100.0) as 'Tipo de IVA',  
        printf("%.2f", cuota_soportada/100.0) as 'Cuota IVA soportado',  
        printf("%.2f", cuota_deducible/100.0) as 'Cuota deducible',  
        printf("%.2f", tipo_re*100.0) as 'Tipo de recargo eq.',  
        printf("%.2f", cuota_re/100.0) as 'Cuota recargo eq.'  
      from iva_recibidas  
      where 
        fecha_registro_contable>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and fecha_registro_contable<='${moment().endOf('year').format('YYYY-MM-DD')}' 
      order by  fecha_registro_contable asc`,
    },
    {
      label: 'Activos (oficial)',
      sql: `select 
        asset_id as 'Bien inversión', 
        strftime('%d/%m/%Y',start_date) as 'Inicio amortización', 
        strftime('%d/%m/%Y',last_date) as 'Última amortización realizada hasta la fecha', 
        printf("%.2f", rate*100.0) as 'Porcentaje anual de amortización', 
        printf("%.2f", yearly_amount/100.0) as 'Importe anual amortizable', 
        printf("%.2f", initial_value/100.0) as 'Importe de compra', 
        printf("%.2f", current_value/100.0) as 'Valorización actual', 
        currency as 'Moneda' 
      from assets 
      where 
        start_date<='${moment().endOf('year').format('YYYY-MM-DD')}' 
      order by start_date asc`,
    },
    {
      label: 'Retenciones',
      sql: `select 
        * 
      from retenciones
      where 
        date>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and date<='${moment().endOf('year').format('YYYY-MM-DD')}'`,
    },
    {
      label: 'Impuestos',
      sql: `select 
      * 
      from taxes
      where 
        date>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and date<='${moment().endOf('year').format('YYYY-MM-DD')}'`,
    },
    {
      label: 'Gastos deducibles IRPF',
      sql: `select 
        doc, 
        date, 
        report_amount/100.0 as amount, 
        (select options from journal as j where j.doc=x.doc and j.idx=x.idx and line_idx=0) as options
      from journal as x 
      where 
        date>='${moment().startOf('year').format('YYYY-MM-DD')}' 
        and date<'${moment().endOf('quarter').add(1, 'day').format('YYYY-MM-DD')}' 
        and what_k='expense' 
        and deducible is not 0 
      order by date asc`
    },
    {
      label: 'Gastos en el trimestre actual que podrían ir al anterior',
      sql: `select 
        doc 
      from journal 
      where 
        date >= '${moment().startOf('quarter').format('YYYY-MM-DD')}' 
        and bill_date<'${moment().startOf('quarter').format('YYYY-MM-DD')}'`
    },
    {
      label: 'IVA recibidas',
      sql: `select 
        doc,
        fecha_emision,
        numyserie,  
        emisor_pais, 
        emisor_id,  
        regimen,
        isp,
        base_imponible,  
        tipo_impositivo,  
        cuota_soportada,
        cuota_deducible  
      from iva_recibidas  
      where 
        periodo='${moment().quarter()}T' and year='${moment().format('YYYY')}'`,
    },
    {
      label: 'IVA expedidas',
      sql: `select 
        doc,
        fecha_emision, 
        serie, 
        num, 
        contraparte_pais, 
        contraparte_id, 
        tipo_factura,
        regimen,
        sujeta,
        base_imponible,  
        tipo_impositivo,  
        cuota_repercutida  
      from iva_expedidas  
      where 
        periodo='${moment().quarter()}T' and year='${moment().format('YYYY')}'`
    }
  ];
}

class ReportWizard extends React.Component<ReportWizardProps, ReportWizardState> {
  state = {
    fromDate: '',
    toDate: ''
  } as ReportWizardState;
  render = () => {
    return (
      <Dialog open={true} onClose={() => { this.props.onResult(); }}>
        <DialogTitle>Selecciona el informe</DialogTitle>
        <DialogContent>
          <div>
            <List>
              {
                getOptions().map((item: { label: string, sql: string }) => {
                  return (
                    <ListItem
                      key={item.label}
                      button
                      onClick={() => {
                        this.props.onResult(item.sql);
                      }}
                    >
                      <ListItemText>{item.label}</ListItemText>
                    </ListItem>
                  );
                })}

            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onResult(); }}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

interface ReportResultProps {
  result: QueryResult;
}

class ReportResult extends React.PureComponent<ReportResultProps> {

  render = () => {
    let columns = this.props.result.columns.map((c) => { return { field: c, title: c }; });
    let rows = this.props.result.rows.map((r) => {
      let index = 0;
      const res = {};
      for (const c of r) {
        res[this.props.result.columns[index]] = c;
        index += 1;
      }
      return res;
    });
    return (
      <MaterialTable
        columns={columns}
        data={rows}
        options={{
          doubleHorizontalScroll: true,
          padding: 'dense',
          search: false,
          paging: false,
          filtering: false,
          sorting: false,
          toolbar: false,
          showTitle: false
        }}
      />
    );
  }
}

async function executeSQL(companyId: string, sql: string): Promise<any | null> {
  return await apolloClient.query<QSQL, QSQLVariables>({
    query: gql`
      query QSQL($companyId: ID!, $sql: String!) {
          company(id: $companyId) {
            id
            name
            sql(sql: $sql)
          }
        }
      `,
    variables: { companyId, sql }
  }).then((result: ApolloQueryResult<QSQL>) => {
    if (result.data.company) {
      return result.data.company.sql;
    }
    return null;
  }).catch((e: ApolloError) => {
    onGenericError(e);
    return null;
  });
}

async function downloadSQL(companyId: string, sql: string): Promise<string | null> {
  return await apolloClient.query<QCustomReport, QCustomReportVariables>({
    query: gql`
      query QCustomReport($companyId: ID!, $sql: String!) {
                company(id: $companyId) {
                id
                name
                customReport(sql: $sql) {
                  url
                }
              }
            }
          `,
    variables: { companyId, sql }
  }).then((result: ApolloQueryResult<QCustomReport>) => {
    if (result.data.company) {
      return result.data.company.customReport.url;
    }
    return null;
  }).catch((e: ApolloError) => {
    onGenericError(e);
    return null;
  });
}