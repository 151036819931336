import base64 from 'base-64';

const INTERCOM_ID = 'flvzbqyb';

export function getIntercomUserUrl(userId: string): string {
  var params = base64.encode(`{"predicates":[{"attribute":"role","comparison":"eq","type":"role","value":"user_role"},{"attribute":"user_id","comparison":"eq","type":"string","value":"${userId}"}]}`);
  return `https://app.intercom.io/a/apps/${INTERCOM_ID}/users/segments/all-users:` + params;
}

export function getIntercomCompanyUrl(taxId: string): string {
  var params = base64.encode(`{"predicates":[{"attribute":"company.custom_data.taxId","comparison":"eq","type":"string","value":"${taxId}"}]}`);
  return `https://app.intercom.io/a/apps/${INTERCOM_ID}/companies/segments/all:` + params;
}
