// adapted from https://github.com/caiogondim/parse-price.js/blob/master/src/index.js
function filterNumbers(str: string): string {
  return str.replace(/[^\d]/g, '');
}

function filterNumbersDotsAndCommas(str: string): string {
  return str
    .replace(/[^\d.,]/g, '')
    .replace(/[.,]$/, '');
}

function getDecimalPosition(str: string): number | null {
  // For each character starting from the end...
  for (var i = 0; i < str.length; i++) {
    if (i > 2) {
      return null;
    }
    const position = str.length - i - 1;
    const currentChar = str[position];

    if ([',', '.'].indexOf(currentChar) !== -1) {
      return position;
    }
  }
  return null;
}

export function parseAmount(str: string): string {
  let decimalPart = '00';
  const filtered = filterNumbersDotsAndCommas(str);
  let integerPart = filtered;

  const decimalPosition = getDecimalPosition(filtered);
  if (decimalPosition) {
    decimalPart = filtered.substring(decimalPosition);
    integerPart = filtered.substring(0, decimalPosition);
  }
  return filterNumbers(integerPart) + '.' + filterNumbers(decimalPart);
}