import * as React from 'react';
import { Grid, createStyles, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withRouter, RouteComponentProps } from 'react-router';
import { QCompaniesQuery_companies_items } from '../gen/QCompaniesQuery';
import CompaniesList, { CompaniesListFilter, CompaniesListWindowState } from '../widgets/companiesList';
import { navigateWithState } from '../lib/util';
import CompanyCount from '../widgets/companyCount';
import { CompanyStatus, PaperworkStatus, CompaniesOrderColumn } from '../gen/globalTypes';

interface CompaniesState {
  filter: CompaniesListFilter;
}

const FILTER_NEW_FREELANCE_GATHERING_INFO_COMPLETED_COMPANIES: CompaniesListFilter = {
  page: 0,
  pageSize: 500,
  order: CompaniesOrderColumn.CREATION_DATE,
  orderDirection: 'desc',
  status: undefined,
  paymentStatus: undefined,
  paperworkStatus: [PaperworkStatus.NEW_FREELANCE_GATHERING_INFO_COMPLETED],
};

const FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPANIES: CompaniesListFilter = {
  page: 0,
  pageSize: 500,
  order: CompaniesOrderColumn.CREATION_DATE,
  orderDirection: 'desc',
  status: undefined,
  paymentStatus: undefined,
  paperworkStatus: [PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL],
};

const FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED_COMPANIES: CompaniesListFilter = {
  page: 0,
  pageSize: 500,
  order: CompaniesOrderColumn.CREATION_DATE,
  orderDirection: 'desc',
  status: undefined,
  paymentStatus: undefined,
  paperworkStatus: [PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED],
};

const FILTER_RUNNING_COMPANIES: CompaniesListFilter = {
  page: 0,
  pageSize: 500,
  order: CompaniesOrderColumn.CREATION_DATE,
  orderDirection: 'desc',
  status: [CompanyStatus.RUNNING],
  paymentStatus: undefined,
  paperworkStatus: undefined,
};

const styles = (theme: Theme) =>
  createStyles({});
class Companies extends React.Component<RouteComponentProps, CompaniesState> {
  constructor(props: RouteComponentProps) {
    super(props, null);
    this.state = {
      filter: {
        page: 0,
        pageSize: 500,
        order: CompaniesOrderColumn.CREATION_DATE,
        orderDirection: 'desc',
        status: undefined,
        paymentStatus: undefined,
        paperworkStatus: undefined,
      }
    };
  }
  resetFilter = () => {
    this.setState({
      filter: {
        page: 0,
        pageSize: 500,
        order: CompaniesOrderColumn.CREATION_DATE,
        orderDirection: 'desc',
        status: undefined,
        paymentStatus: undefined,
        paperworkStatus: undefined,
      }
    });
  }
  render = () => (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <CompanyCount
            title="Empresas activas"
            status={CompanyStatus.RUNNING}
            selected={JSON.stringify(this.state.filter) === JSON.stringify(FILTER_RUNNING_COMPANIES)}
            onClick={() => {
              if (JSON.stringify(this.state.filter) === JSON.stringify(FILTER_RUNNING_COMPANIES)) {
                this.resetFilter();
              } else {
                this.setState({ filter: FILTER_RUNNING_COMPANIES });
              }
            }}
          />
        </Grid>
        <Grid item md={3}>
          <CompanyCount
            title="Trámite: Alta autónomos"
            subtitle="ESPERANDO PROPUESTA"
            paperworkStatus={PaperworkStatus.NEW_FREELANCE_GATHERING_INFO_COMPLETED}
            selected={JSON.stringify(this.state.filter) ===
              JSON.stringify(FILTER_NEW_FREELANCE_GATHERING_INFO_COMPLETED_COMPANIES)}
            onClick={() => {
              if (JSON.stringify(this.state.filter) ===
                JSON.stringify(FILTER_NEW_FREELANCE_GATHERING_INFO_COMPLETED_COMPANIES)) {
                this.resetFilter();
              } else {
                this.setState({ filter: FILTER_NEW_FREELANCE_GATHERING_INFO_COMPLETED_COMPANIES });
              }
            }}
          />
        </Grid>
        <Grid item md={3}>
          <CompanyCount
            title="Trámite: Alta autónomos"
            subtitle="PROPUESTA ENVIADA"
            paperworkStatus={PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL}
            selected={JSON.stringify(this.state.filter) ===
              JSON.stringify(FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPANIES)}
            onClick={() => {
              if (JSON.stringify(this.state.filter) ===
                JSON.stringify(FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPANIES)) {
                this.resetFilter();
              } else {
                this.setState({ filter: FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPANIES });
              }
            }}
          />
        </Grid>
        <Grid item md={3}>
          <CompanyCount
            title="Trámite: Alta autónomos"
            subtitle="ESPERANDO ALTA EN PAE"
            paperworkStatus={PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED}
            selected={JSON.stringify(this.state.filter) ===
              JSON.stringify(FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED_COMPANIES)}
            onClick={() => {
              if (JSON.stringify(this.state.filter) ===
                JSON.stringify(FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED_COMPANIES)) {
                this.resetFilter();
              } else {
                this.setState({ filter: FILTER_NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED_COMPANIES });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CompaniesList
            // TODO Review if we can remove this key at some point in the future (jom@20190927)
            key={JSON.stringify(this.state.filter)}
            filter={this.state.filter}
            history={this.props.history}
            onCompanyRowClick={(company: QCompaniesQuery_companies_items, state: CompaniesListWindowState) => {
              navigateWithState(
                this.props.history,
                `/company/${company.id}`,
                state
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default withStyles(styles)(withRouter(Companies));