import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Dialog, AppBar,
  Button, Toolbar, Typography, IconButton, Menu, MenuItem
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Editor } from './editor';
import { ContextViewer } from './contextViewer';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { Loading } from '../../widgets/loading';
import { QSave, QSaveVariables } from '../../gen/QSave';
import { client as apolloClient } from '../../client';
import { Confirm } from '../../lib/utilBrowser';
import { onMutationError } from '../../lib/errorReporter';
import { MDeleteDoc, MDeleteDocVariables } from '../../gen/MDeleteDoc';
import { QGeneratedAccounting, QGeneratedAccountingVariables } from '../../gen/QGeneratedAccounting';
import { DocType } from '../../gen/globalTypes';
import { QDoc } from '../../gen/QDoc';
import { ExtractionEditor } from '../extraction/editor';
import clipboardCopy = require('clipboard-copy');

class DocQuery extends Query<QDoc> { }

const QUERY = gql`
  query QDoc($company: ID!, $doc: ID!) {
    company(id: $company) {
      id,
      doc(id: $doc) {
        id,
        accounting,
        docType, 
        deleted, 
        title,
        text,
        mimeType,
        thumbnailUrl,
        contentUrl, 
        extraction,
        imageOriginalPages,
        imagePages {
          imageUrl,
          visionUrl,
          width,
          height,
        }
      }
    }
  }
  `;

class SaveMutation extends Mutation<QSave, QSaveVariables> { }
const SAVE_MUTATION = gql`
  mutation QSave($company: ID!, $id: ID!, $accounting: String!) {
    updateAccounting(id: $id, company: $company, accounting: $accounting )
  }
`;

class UpdateDeletedMutation extends Mutation<MDeleteDoc, MDeleteDocVariables> { }
const UPDATE_IGNORED_MUTATION = gql`
  mutation MDeleteDoc($company:ID!, $id:ID!, $deleted:Boolean!){
    result: deleteDoc(company: $company, id: $id, deleted:$deleted) {
      deleted
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
      display: 'flex',
    },
    panel: {
      display: 'flex',
      flex: '1'
    },
    panelScroller: {
      minHeight: 0
    },
    rightPanel: {
      flexDirection: 'column',
    },
    masterDocumentPanel: {
      flexDirection: 'row-reverse',
    },
    wrapper: {
      paddingTop: theme.spacing(9),
    },
    flex: {
      flex: 1,
    },
    rightToolbar: {
      flexDirection: 'row-reverse'
    }
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    overflowY: 'hidden'
  },
}))(MuiDialogContent);

interface Props extends
  RouteComponentProps<{
    doc: string;
    company: string;
  }>,
  WithStyles<typeof styles> { }

async function getGeneratedAccounting(companyId: string, id: string): Promise<string> {
  const result = await apolloClient.query<QGeneratedAccounting, QGeneratedAccountingVariables>({
    query: gql`
    query QGeneratedAccounting($company: ID!, $id: ID!) {
      company(id: $company) {
        id
        accounting:generatedAccounting(id: $id)
      }
    }
    `,
    variables: { company: companyId, id }
  });
  if (result.data.company) {
    return result.data.company.accounting;
  }
  return '';
}

interface DocState {
  moreVisible: boolean;
  moreAnchor?: HTMLElement;
}

class Doc extends React.Component<Props, DocState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      moreVisible: false,
    };
  }
  private editorRef = React.createRef<Editor>();
  canExitWithChanges = async () => {
    if (this.editorRef.current && this.editorRef.current.props.value !== this.editorRef.current.value()) {
      return await Confirm({
        body: 'El documento tiene cambios sin guardar, ¿quieres continuar y descartar los cambios?'
      });
    }
    return true;
  }
  handleClose = async () => {
    if (await this.canExitWithChanges()) {
      this.props.history.goBack();
    }
  }
  handleMoreButton = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ moreVisible: !this.state.moreVisible, moreAnchor: event.currentTarget });
  }
  reprocessDoc = async (docId: string) => {
    if (this.editorRef.current) {
      const accounting = await getGeneratedAccounting(this.props.match.params.company, docId);
      this.editorRef.current.replaceText(accounting);
    }
  }
  openExtraction = async (docId: string) => {
    if (await this.canExitWithChanges()) {
      this.props.history.push({
        pathname: `/extractions/${this.props.match.params.doc}`,
      });
    }
  }
  _getContent = () => {
    if (this.editorRef.current) {
      return this.editorRef.current.value();
    } else {
      return undefined;
    }
  }
  handleSave = (saveHandler: MutationFn<QSave, QSaveVariables>, serverAccounting: string) => {
    let accounting = this._getContent();
    if (accounting != null && serverAccounting !== accounting) {
      saveHandler({
        variables: {
          company: this.props.match.params.company,
          id: this.props.match.params.doc,
          accounting,
        }
      });
    }
  }
  handleUpdateDeleted = async (
    updateHandler: MutationFn<MDeleteDoc, MDeleteDocVariables>,
    id: string,
    deleted: boolean
  ) => {
    const continueWithUpdate = await Confirm({
      body: deleted ?
        'Se borrará el documento de la contabilidad, ¿quieres continuar?' :
        'Se recuperará el documento y volverá a la contabilidad, ¿quieres continuar?'
    });
    if (continueWithUpdate) {
      const result = await updateHandler({
        variables: {
          company: this.props.match.params.company,
          id,
          deleted,
        }
      });
      if (result && result.data && result.data.result.deleted) {
        // When we are deleting the doc we close the document view
        this.handleClose();
      } else {
        // When we are deleting the doc we stay in this view and we just close the Menu
        this.setState({ moreVisible: false });
      }
    }
  }
  insertText = (text: string) => {
    if (this.editorRef.current) {
      this.editorRef.current.insertText(text);
    }
  }
  render = () => {
    const company = this.props.match.params.company;
    const doc = this.props.match.params.doc;
    const classes = this.props.classes;
    return (
      <Dialog
        fullScreen
        open={true}
        onClose={this.handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <DocQuery
          query={QUERY}
          variables={{ doc, company }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (<Loading />);
            }
            if (error || !data) {
              return (<div>Error {JSON.stringify(error) || 'no data'}</div>);
            }
            if (data.company == null) {
              return <div />;
            }
            if (data.company.doc == null) {
              return <div />;
            }
            const docData = data.company.doc;
            const isMasterDocument = data.company.doc.docType === DocType.STANDALONE;
            const allowToCalculateAccountingAgain = data.company.doc.docType === DocType.IMAGE;
            const allowToExtractAgain = data.company.doc.docType === DocType.IMAGE;
            const isDeleted = data.company.doc.deleted;

            const moreButtonVisible = allowToCalculateAccountingAgain || !isMasterDocument || allowToExtractAgain;

            return (
              <React.Fragment>
                <AppBar>
                  <Toolbar>
                    <Button size="small" color="secondary" onClick={this.handleClose}>
                      <ArrowBackIcon />
                    </Button>
                    <Typography variant="h6" color="inherit" className={classes.flex}>Abaq</Typography>
                    {
                      (() => {
                        if (moreButtonVisible) {
                          return (
                            <React.Fragment>
                              <IconButton color="inherit" onClick={this.handleMoreButton}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                open={this.state.moreVisible}
                                onClose={() => { this.setState({ moreVisible: false }); }}
                                anchorEl={this.state.moreAnchor}
                              >
                                {
                                  allowToCalculateAccountingAgain ? (
                                    <MenuItem onClick={() => { this.reprocessDoc(doc); }}>
                                      Volver a calcular
                                    </MenuItem>
                                  ) : null
                                }
                                {
                                  allowToExtractAgain ? (
                                    <MenuItem onClick={() => { this.openExtraction(doc); }}>
                                      Abrir extracción
                                    </MenuItem>
                                  ) : null
                                }
                                {
                                  !isMasterDocument ? (
                                    <UpdateDeletedMutation
                                      mutation={UPDATE_IGNORED_MUTATION}
                                      onError={(e) => { onMutationError(e); }}
                                    >
                                      {(updateDeletedFn, { loading: updatingDeleted }) => (
                                        <MenuItem
                                          disabled={updatingDeleted}
                                          onClick={() => {
                                            this.handleUpdateDeleted(updateDeletedFn, doc, !isDeleted);
                                          }}
                                        >
                                          {isDeleted ? 'Recuperar' : 'Borrar'}
                                        </MenuItem>
                                      )}
                                    </UpdateDeletedMutation>
                                  ) : null
                                }
                                }
                              </Menu>
                            </React.Fragment>
                          );
                        }
                        return null;
                      })()
                    }
                    {
                      data.company.doc.contentUrl ?
                        <Button
                          size="small"
                          color="secondary"
                          href={data.company.doc.contentUrl}
                          target="_blank"
                        >
                          VER ORIGINAL
                        </Button>
                        : undefined
                    }

                    <SaveMutation
                      mutation={SAVE_MUTATION}
                      onError={(e) => { onMutationError(e); }}
                    >
                      {(saveFn, { loading: saving }) => (
                        <Button
                          size="small"
                          color="secondary"
                          disabled={saving}
                          onClick={() => this.handleSave(saveFn, data!.company!.doc!.accounting)}
                        >
                          Guardar
                        </Button>
                      )}
                    </SaveMutation>
                    <Button size="small" color="secondary" onClick={this.handleClose}>
                      Cerrar
                    </Button>
                  </Toolbar>

                </AppBar>
                <DialogContent className={classNames(classes.wrapper, classes.panel, classes.panelScroller)}>
                  {
                    docData.imagePages.length > 0 ?
                      docData.docType === 'IMAGE' ?
                        <div className={classes.panel}>
                          <ExtractionEditor
                            companyId={company}
                            companyName=""
                            taxId=""
                            editor={false}
                            imageUrl={docData.imagePages[0].imageUrl}
                            visionUrl={docData.imagePages[0].visionUrl}
                            onSave={(ex) => null}
                            onSelected={(txt) => { clipboardCopy(txt); }}
                            close={() => {
                              this.props.history.push(`/company/${company}/extractions/${doc}`);
                            }}
                            initialValue={docData.extraction}
                          />
                        </div> : (
                          docData.docType === 'DIGITAL' ?
                            <div className={classes.panel} style={{ alignItems: 'flex-start', overflow: 'auto' }}>
                              <img src={docData.imagePages[0].imageUrl} style={{ width: '100%' }} />
                            </div>
                            : null
                        ) : null
                  }
                  <div
                    className={classNames(
                      classes.panel,
                      !isMasterDocument && classes.rightPanel,
                      isMasterDocument && classes.masterDocumentPanel,
                    )}>{/*tslint:disable-line: jsx-alignment*/}
                    <div className={classNames(classes.panel, classes.panelScroller)}>
                      <ContextViewer doc={doc} company={company} />
                    </div>
                    <div className={classes.panel}>
                      <Editor ref={this.editorRef} doc={doc} company={company} value={data.company.doc.accounting} />
                    </div>
                  </div>
                </DialogContent>
              </React.Fragment>
            );
          }}
        </DocQuery>
      </Dialog>
    );
  }
}
export default withStyles(styles)(Doc);