import * as React from 'react';
import { createStyles, WithStyles, withStyles, Tooltip, IconButton } from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import MessageIcon from '@material-ui/icons/Info';
import { LiveTable, TableQuery } from '../widgets/livetable';
import { QExtractions, QExtractionsVariables } from '../gen/QExtractions';
import { formatDateTime, getWindowState, ACTIVE_ROW_BGCOLOR } from '../lib/util';
import { DocStatus, CompanyDocsOrderColumn, QueryOrderDirection } from '../gen/globalTypes';
import { History } from 'history';
import { Column } from 'material-table';

const QUERY = gql`
query QExtractions(
  $limit: Int!
  $offset: Int!
  $status: DocStatus
  $order: CompanyDocsOrderColumn
  $orderDirection: QueryOrderDirection
) {
  allExtractions(
    pagination: { limit: $limit, offset: $offset }
    status: $status
    order: $order
    orderDirection: $orderDirection
  ) {
    totalCount
    items {
      id
      status
      companyId
      creationDate
      thumbnailUrl
      extractionMessage
      company {
        id
        name
      }
    }
  }
}
`;

function getOrderColumn(column: Column): CompanyDocsOrderColumn {
  switch (column.field) {
    case 'creationDate': return CompanyDocsOrderColumn.CREATION_DATE;
    case 'docType': return CompanyDocsOrderColumn.DOC_TYPE;
    case 'status': return CompanyDocsOrderColumn.STATUS;
    default: return CompanyDocsOrderColumn.CREATION_DATE;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    date: {
      whiteSpace: 'nowrap'
    },
  });

interface ExtractionsProps {
  company: string;
}
interface Props extends RouteComponentProps<ExtractionsProps>, WithStyles<typeof styles> { }

interface ExtractionsState {
  activeDocumentId?: string;
}

interface WindowState {
  state?: ExtractionsState;
  filter?: Filter;
}

interface Filter {
  page: number;
  pageSize: number;
  status?: DocStatus;
  order: CompanyDocsOrderColumn;
  orderDirection: 'asc' | 'desc';
}

function getInitialFilter(history: History): Filter {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.filter) {
    return windowState.filter;
  } else {
    return {
      page: 0,
      pageSize: 500,
      order: CompanyDocsOrderColumn.CREATION_DATE,
      orderDirection: 'desc',
    };
  }
}

function getInitialState(history: History): ExtractionsState {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.state) {
    return windowState.state;
  } else {
    return {
    };
  }
}

function getCurrentWindowState(extractions: Extractions): WindowState {
  return {
    filter: extractions.filter,
    state: extractions.state,
  };
}

interface FilterAndVariables {
  filter: Filter;
  variables: QExtractionsVariables;
}

function getFilterAndVariablesFromQuery(props: Props, query: TableQuery): FilterAndVariables {
  const variables: QExtractionsVariables = {
    limit: query.pageSize,
    offset: query.page * query.pageSize,
    order: CompanyDocsOrderColumn.CREATION_DATE,
    orderDirection: query.orderDirection === 'asc' ? QueryOrderDirection.ASC : QueryOrderDirection.DESC,
  };
  const filter: Filter = {
    page: query.page,
    pageSize: query.pageSize,
    order: CompanyDocsOrderColumn.CREATION_DATE,
    orderDirection: 'asc',
  };
  if (query.orderBy) {
    variables.order = getOrderColumn(query.orderBy);
    filter.order = variables.order;
    variables.orderDirection = query.orderDirection === 'desc' ? QueryOrderDirection.DESC : QueryOrderDirection.ASC;
    filter.orderDirection = query.orderDirection;
  }
  for (const f of query.filters) {
    switch (f.column.field) {
      case 'status': {
        variables.status = f.value[0];
        filter.status = f.value;
        break;
      }
      default:
    }
  }
  return { filter, variables };
}

class Extractions extends React.Component<Props, ExtractionsState> {
  constructor(props: Props) {
    super(props);
    this.state = getInitialState(this.props.history);
    this.filter = getInitialFilter(this.props.history);
  }
  filter: Filter;
  render = () => {
    return (
      <LiveTable
        title="Imágenes pendientes"
        history={this.props.history}
        options={{
          pageSize: this.filter.pageSize,
          rowStyle: (rowData, _) => {
            const css: React.CSSProperties = {};
            if (rowData.id === this.state.activeDocumentId) {
              css.backgroundColor = ACTIVE_ROW_BGCOLOR;
            }
            return css;
          }
        }}
        columns={
          [
            {
              field: 'creationDate',
              title: 'Fecha de creación',
              filtering: false,
              defaultSort: this.filter.order === CompanyDocsOrderColumn.CREATION_DATE ?
                this.filter.orderDirection : undefined,
              render: (rowData) => <span>{formatDateTime(rowData.creationDate)}</span>,
              type: 'datetime'
            },
            {
              field: 'company',
              title: 'Empresa',
              filtering: false,
              sorting: false,
              render: (rowData) => <span>{rowData.company.name}</span>
            },
            {
              field: 'status',
              title: 'Estado',
              filtering: true,
              lookup: {
                'PENDING': DocStatus.PENDING,
                'REVIEW': DocStatus.REVIEW,
              },
              defaultSort: this.filter.order === CompanyDocsOrderColumn.STATUS ?
                this.filter.orderDirection : undefined,
              defaultFilter: this.filter.status,
              render: renderStatus
            },
            {
              field: 'image',
              title: 'Imagen',
              render: (rowData) => <img src={rowData.thumbnailUrl} />,
              filtering: false,
              sorting: false
            },
          ]}
        fetchQuery={async (client, query) => {
          const { filter, variables } = getFilterAndVariablesFromQuery(this.props, query);
          this.filter = filter;
          const { data } = await client.query<QExtractions, QExtractionsVariables>({
            query: QUERY,
            variables,
            fetchPolicy: 'no-cache'
          });
          if (!data || !data.allExtractions) {
            return undefined;
          }
          return data.allExtractions;
        }}
        rowToPathAndState={(row) => {
          const windowState = getCurrentWindowState(this);
          if (windowState.state) {
            windowState.state.activeDocumentId = row.id;
          }
          return {
            pathname: `/extractions/${row.id}`,
            state: windowState,
          };
        }}
      />
    );
  }
}

export default withStyles(styles)(Extractions);

function renderStatus(value: { status: DocStatus, message?: string }): JSX.Element {
  if (value.message) {
    return (
      <span>
        {value.status}
        <Tooltip
          title={value.message}
          placement="top"
        >
          <IconButton>
            <MessageIcon />
          </IconButton>
        </Tooltip>
      </span>
    );
  } else {
    return <span>{value.status}</span>;
  }
}
