import * as React from 'react';
import { createStyles, WithStyles, withStyles, Snackbar, Portal, IconButton, Button } from '@material-ui/core';
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import CloseIcon from '@material-ui/icons/Close';
import { QSubscription } from './gen/QSubscription';
import { withRouter, RouteComponentProps } from 'react-router';
import { getCompanyName } from './lib/utilBrowser';

const QUERY = gql`
subscription QSubscription {
  notification {
    company,
    message
  }
}
`;

class NotificationSubscription extends Subscription<QSubscription> { }

const styles = (theme: Theme) =>
  createStyles({
    companyName: {
      color: '#ffffff', // FIXME style
    }
  });

interface MessageInfo {
  key: number;
  message: string;
  company?: string;
  companyName?: string;
}

let mountedNotifications: NotificationsNoStyle | null = null;
export const notificationsClient = {
  addMessage: (message: string) => {
    if (mountedNotifications) {
      mountedNotifications.addExternalMessage(message);
    }
  },
};

export interface NotificationProps extends RouteComponentProps {
  onServerChange?: () => Promise<void>;
}

class NotificationsNoStyle extends React.Component<NotificationProps & WithStyles<typeof styles>> {
  private queue: MessageInfo[] = [];
  state = {
    open: false,
    messageInfo: { message: '', company: '', key: 0, companyName: '' }
  };
  componentDidMount = () => {
    mountedNotifications = this;
  }
  addExternalMessage = (message: string) => {
    const messageInfo = {
      key: new Date().getTime(),
      message,
    };
    this.addMessage(messageInfo);
  }
  addMessage = (messageInfo: MessageInfo) => {
    this.queue.push(messageInfo);

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    } else {
      this.processQueue();
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleExited = () => {
    this.processQueue();
  };
  processQueue = () => {
    if (this.queue.length > 0) {
      const messageInfo = this.queue.shift();
      if (messageInfo) {
        (async () => {
          this.setState({
            messageInfo,
            open: true,
          });
        })();
      }
    }
  };

  render = () => (
    <div>
      <NotificationSubscription
        subscription={QUERY}
        onSubscriptionData={async ({ client, subscriptionData }) => {
          if (subscriptionData.data && subscriptionData.data.notification) {
            const message = {
              ...subscriptionData.data.notification,
              key: new Date().getTime(),
              companyName: await getCompanyName(subscriptionData.data.notification.company)
            };
            this.addMessage(message);
          }
          if (this.props.onServerChange) {
            this.props.onServerChange();
          }
        }}
      />
      <Portal>
        <Snackbar
          style={{ top: '4px' }}
          key={this.state.messageInfo.key}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          onExited={this.handleExited}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {
                this.state.messageInfo.companyName ? (
                  <React.Fragment>
                    <Button
                      variant="text"
                      size="small"
                      className={this.props.classes.companyName}
                      onClick={() => { this.props.history.push(`/company/${this.state.messageInfo.company}`); }}
                    >
                      {this.state.messageInfo.companyName}
                    </Button>
                  </React.Fragment>
                ) : null
              }
              {this.state.messageInfo.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              size="small"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Portal>
    </div>
  )
}
export const Notifications = withStyles(styles)(withRouter(NotificationsNoStyle));

interface Props {
  onServerChanged: () => void;
}
export class WatchServerUpdates extends React.Component<Props> {
  render = () => (
    <NotificationSubscription
      subscription={QUERY}
      onSubscriptionData={async ({ client, subscriptionData }) => this.props.onServerChanged()}
    />
  )
}