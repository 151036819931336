export function isValidNIF(nif: string) {
  if (nif == null || nif === '' || nif === undefined) {
    return false;
  }
  nif = nif.toUpperCase();
  if (nif.length === 11 && nif.charAt(0) === 'E' && nif.charAt(1) === 'S') {
    nif = nif.substring(2);
  }
  const c0 = nif.charAt(0);
  /*
  From https://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal
  Será una LETRA si la clave de entidad es P, Q, R, S o W. O también si los dos dígitos iniciales indican "No Residente"
  Será un NÚMERO si la entidad es A, B, E o H.
  Para otras claves de entidad: el dígito podrá ser tanto número como letra.
  */

  if (['P', 'Q', 'R', 'S', 'W'].includes(c0) || nif.substr(1, 3) === '00') {
    return _validateCIF(nif, 'letter');
  } else if (['A', 'B', 'E', 'H'].includes(c0)) {
    return _validateCIF(nif, 'number');
  } else if (['C', 'D', 'F', 'G', 'J', 'U', 'V', 'K', 'L', 'M', 'N'].includes(c0)) {
    return _validateCIF(nif, 'any');
  } else if (['X', 'Y', 'Z'].includes(c0)) {
    return _validateNIF(nif, true);
  } else {
    return _validateNIF(nif, false);
  }

}

export enum NIFType {
  CIF,
  DNI,
  NIE,
  INVALID
}

export function getNIFType(nif: string): NIFType {
  if (isValidNIF(nif)) {
    nif = nif.toUpperCase();
    if (nif.length === 11 && nif.charAt(0) === 'E' && nif.charAt(1) === 'S') {
      nif = nif.substring(2);
    }
    const c0 = nif.charAt(0);

    if (['P', 'Q', 'R', 'S', 'W'].includes(c0) || nif.substr(1, 3) === '00') {
      return NIFType.CIF;
    } else if (['A', 'B', 'E', 'H'].includes(c0)) {
      return NIFType.CIF;
    } else if (['C', 'D', 'F', 'G', 'J', 'U', 'V', 'K', 'L', 'M', 'N'].includes(c0)) {
      return NIFType.CIF;
    } else if (['X', 'Y', 'Z'].includes(c0)) {
      return NIFType.NIE;
    } else {
      return NIFType.DNI;
    }
  }
  return NIFType.INVALID;
}

const sumDigitsHelper = new Array(0, 2, 4, 6, 8, 1, 3, 5, 7, 9);

function _validateCIF(nif: string, controlCharacterType: 'number' | 'letter' | 'any'): boolean {
  if (nif.length !== 9) {
    return false;
  }
  let temp = 0;
  let i = 2;
  while (i <= 6) {
    temp = temp + sumDigitsHelper[Number(nif.substr(i - 1, 1))];
    temp = temp + Number(nif.substr(i, 1));
    i += 2;
  }
  temp = temp + sumDigitsHelper[Number(nif.substr(7, 1))];
  temp = (10 - (temp % 10)) % 10;
  if (controlCharacterType === 'number') {
    return temp === Number(nif.charAt(8));
  } else if (controlCharacterType === 'letter') {
    return 'JABCDEFGHI'.charAt(temp) === nif.charAt(8);
  } else { // "any"
    return (temp === Number(nif.charAt(8))) || ('JABCDEFGHI'.charAt(temp) === nif.charAt(8));
  }
}

function _validateNIF(nif: string, isNIE: boolean): boolean {
  if (nif.toUpperCase().match(new RegExp('^[A-Z,0-9]{2,9}$')) === null) {
    return false;
  }

  if (isNIE) {
    if (nif.length !== 9) {
      return false;
    }
    const c0 = nif.charAt(0);
    switch (c0) {
      case 'X':
        nif = nif.substring(1);
        break;
      case 'Y':
        nif = '1' + nif.substring(1);
        break;
      case 'Z':
        nif = '2' + nif.substring(1);
        break;
      default:
        return false;
    }
  } else {
    if (nif.length > 9) {
      return false;
    }
  }
  while (nif.charAt(0) === '0' && nif.length > 2) {
    nif = nif.substring(1);
  }
  const num = Number(nif.substring(0, nif.length - 1));
  const control = nif.substring(nif.length - 1, nif.length);
  return control === 'TRWAGMYFPDXBNJZSQVHLCKET'.charAt(num % 23);
}
