import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { resetClientAuth } from './client';
import React from 'react';
import { Loading } from './widgets/loading';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';

const STAGING_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCmwuXnN3rjafitHmxb_odKkeNyntvlCcc',
  authDomain: 'abaq-staging.firebaseapp.com',
  databaseURL: 'https://abaq-staging.firebaseio.com',
  projectId: 'abaq-staging',
  storageBucket: 'abaq-staging.appspot.com',
  messagingSenderId: '103008815481',
  appId: '1:103008815481:web:25e9aa3610b641db'
};
const FIREBASE_CONFIG = process.env.FIREBASE_CONFIG ? JSON.parse(process.env.FIREBASE_CONFIG) : STAGING_FIREBASE_CONFIG;

if (firebase.apps.length === 0) { // hot module reload
  firebase.initializeApp(FIREBASE_CONFIG);
}
export function logout() {
  firebase.auth().signOut();
}

interface SignInScreenProps {
  onUserLogged: (id: string | null) => void;
}

export class SignInScreen extends React.Component<SignInScreenProps> {
  unregisterAuthObserver: any = null;
  // The component's Local state.
  state = {
    loading: true,
    isSignedIn: false // Local signed-in state.    
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID

    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user: firebase.User) => {
        await resetClientAuth();
        this.props.onUserLogged(user ? user.uid : null);
        this.setState({ isSignedIn: !!user, loading: false });
      }
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (this.state.loading) {
      return (<Loading />);
    } else if (this.state.isSignedIn) {
      return (this.props.children);
    } else {
      return (
        <Grid
          container
          style={{ flexGrow: 1, height: '100vh' }}
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                <Typography gutterBottom align="center">Abaq</Typography>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
              </CardContent>
            </Card>
          </Grid>
        </Grid >
      );
    }
  }
}