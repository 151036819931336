import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Button, Toolbar,
  TextField, Grid, List, ListItem, Divider, ListItemText, Paper, Typography
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import { QDocs, QDocsVariables, QDocs_company_docs_items } from '../gen/QDocs';

import { CompanyDocsOrderColumn, QueryOrderDirection, DocType, DocStatus } from '../gen/globalTypes';
import { UploadImage } from '../widgets/uploadImage';
import { LiveTable, TableQuery } from '../widgets/livetable';
import {
  formatDateTime, formatDateLikeMobile, formatCurrencyLikeMobile, getWindowState, FilterBoolean,
  navigateWithState, ACTIVE_ROW_BGCOLOR
} from '../lib/util';
import {
  QCompanySearchVariables, QCompanySearch, QCompanySearch_company,
  QCompanySearch_company_search_items,
  QCompanySearch_company_search_items_item_journalItems
} from '../gen/QCompanySearch';
import { Query } from 'react-apollo';
import { Loading } from '../widgets/loading';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PendingIcon from '@material-ui/icons/AccessTime';
import ReviewIcon from '@material-ui/icons/RemoveRedEye';
import IrrelevantIcon from '@material-ui/icons/AttachFile';
import UnclearIcon from '@material-ui/icons/Warning';
import { History } from 'history';
import { Column } from 'material-table';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const DOCS_QUERY = gql`
query QDocs(
  $company: ID!, $deleted: Boolean, $docType: DocType, $status: DocStatus,
  $order: CompanyDocsOrderColumn, $orderDirection: QueryOrderDirection,
  $limit: Int!, $offset: Int!
  ) {
  company(id: $company) {
    id,
    name, 
    docs(
      deleted: $deleted, 
      type: $docType,
      status: $status,
      order: $order, 
      orderDirection: $orderDirection,
      pagination: {limit: $limit, offset: $offset}
    ) {
      totalCount,
      items {
        id,
        creationDate,
        accounting,
        deleted, 
        docType,
        status,
        thumbnailUrl, 
        isDigitalization
      }
    }
  }
}
`;

// Same query than abaq-mobile/lib/search.dart to get the same results
const SEARCH_QUERY = gql`
query QCompanySearch($companyId: ID!, $query: String!) {
  company(id: $companyId) {
    id
    search(query: $query) {
      totalCount
      items {
        item {
          __typename
          ... on Doc {
            id
            title
            thumbnailUrl
            creationDate
            deleted
            status
            docType
            accounting
            journalItems {
              title
              date
              amount
              currency
              lines {
                title
                subtitle
              }
            }
          }
        }
        text
      }
    }
  }
}
`;

class CompanySearchQuery extends Query<QCompanySearch, QCompanySearchVariables> { }

function getOrderColumn(column: Column): CompanyDocsOrderColumn {
  switch (column.field) {
    case 'creationDate': return CompanyDocsOrderColumn.CREATION_DATE;
    case 'docType': return CompanyDocsOrderColumn.DOC_TYPE;
    case 'status': return CompanyDocsOrderColumn.STATUS;
    default: return CompanyDocsOrderColumn.CREATION_DATE;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    acc: {
      overflow: 'hidden',
      fontSize: '14px', // Fix monospace font-size issue
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      display: 'block',
      maxHeight: '256px',
      textOverflow: 'ellipsis',
      marginTop: '1em',
    },
    date: {
      whiteSpace: 'nowrap'
    },
    toolbar: {
      paddingLeft: 0,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(2),
    },
    searchTitle: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    activeDocument: {
      backgroundColor: ACTIVE_ROW_BGCOLOR,
    }
  });

interface DocsProps {
  company: string;
}
interface Props extends RouteComponentProps<DocsProps>, WithStyles<typeof styles> { }

interface DocsState {
  activeDocumentId?: string;
  searchVisible: boolean;
  searchValue: string;
  searchSubmittedValue: string;
}

interface WindowState {
  state?: DocsState;
  filter?: Filter;
}

interface Filter {
  page: number;
  pageSize: number;
  order: CompanyDocsOrderColumn;
  orderDirection: 'asc' | 'desc';
  docType?: DocType[];
  status?: DocStatus[];
  deleted?: FilterBoolean;
}

function getInitialFilter(history: History): Filter {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.filter) {
    return windowState.filter;
  } else {
    return {
      page: 0,
      pageSize: 500,
      order: CompanyDocsOrderColumn.CREATION_DATE,
      orderDirection: 'desc',
      deleted: 'unchecked',
    };
  }
}

function getInitialState(history: History): DocsState {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.state) {
    return windowState.state;
  } else {
    return {
      searchVisible: false,
      searchValue: '',
      searchSubmittedValue: '',
    };
  }
}

function getCurrentWindowState(docs: Docs): WindowState {
  return {
    filter: docs.filter,
    state: docs.state,
  };
}

interface FilterAndVariables {
  filter: Filter;
  variables: QDocsVariables;
}

function getFilterAndVariablesFromQuery(props: Props, query: TableQuery): FilterAndVariables {
  const variables: QDocsVariables = {
    company: props.match.params.company,
    limit: query.pageSize,
    offset: query.page * query.pageSize,
    deleted: undefined,
    status: undefined,
    order: CompanyDocsOrderColumn.CREATION_DATE,
    orderDirection: query.orderDirection === 'asc' ? QueryOrderDirection.ASC : QueryOrderDirection.DESC,
  };
  const filter: Filter = {
    page: query.page,
    pageSize: query.pageSize,
    order: CompanyDocsOrderColumn.CREATION_DATE,
    orderDirection: 'desc',
  };
  if (query.orderBy) {
    variables.order = getOrderColumn(query.orderBy);
    filter.order = variables.order;
    variables.orderDirection = query.orderDirection === 'desc' ? QueryOrderDirection.DESC : QueryOrderDirection.ASC;
    filter.orderDirection = query.orderDirection;
  }
  for (const f of query.filters) {
    switch (f.column.field) {
      case 'docType': {
        variables.docType = f.value[0];
        filter.docType = f.value;
        break;
      }
      case 'deleted': {
        variables.deleted = f.value === 'checked';
        filter.deleted = f.value;
        break;
      }
      case 'status': {
        variables.status = f.value[0];
        filter.status = f.value;
        break;
      }
      default:
    }
  }

  return { filter, variables };
}

class Docs extends React.Component<Props, DocsState> {
  constructor(props: Props) {
    super(props);
    this.searchInput = React.createRef();
    this.state = getInitialState(this.props.history);
    this.filter = getInitialFilter(this.props.history);
  }
  searchInput: React.RefObject<HTMLInputElement>;
  filter: Filter;
  toggleSearch = () => {
    this.setState(
      { searchVisible: !this.state.searchVisible },
      () => {
        if (this.state.searchVisible && this.searchInput.current) {
          this.searchInput.current.focus();
        }
      });
  }
  submitSearch = () => {
    if (this.state.searchValue.length > 2) {
      this.setState({ searchSubmittedValue: this.state.searchValue });
    }
  }
  render = () => {
    const classes = this.props.classes;
    const company = this.props.match.params.company;
    return (
      <React.Fragment>
        <Toolbar disableGutters={false} variant="regular" className={classes.toolbar}>
          {this.state.searchVisible ? (
            <Button onClick={this.toggleSearch}>
              <ArrowBackIcon />
            </Button>
          ) : (
              <React.Fragment>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.button}
                  onClick={this.toggleSearch}
                >
                  Buscar
                </Button>
                <UploadImage company={company} />
              </React.Fragment>
            )}
        </Toolbar>
        {
          this.state.searchVisible ?
            (
              <React.Fragment>
                <Grid container={true} spacing={8}>
                  <Grid item={true} xs={3}>
                    <TextField
                      inputRef={this.searchInput}
                      label="Teclea la búsqueda"
                      value={this.state.searchValue}
                      fullWidth={true}
                      onChange={(e) => {
                        this.setState({ searchValue: e.target.value });
                      }}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          e.preventDefault();
                          e.stopPropagation();
                          this.submitSearch();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={1}>
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.button}
                      disabled={this.state.searchValue.length < 3}
                      onClick={this.submitSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12}>
                    {this.state.searchValue.length < 3 && this.state.searchSubmittedValue.length < 3 ?
                      (
                        <div>Introduce al menos 3 caracteres para poder buscar</div>
                      ) : (
                        this.state.searchSubmittedValue.length < 3 ? (<div />) : (
                          <CompanySearchQuery
                            query={SEARCH_QUERY}
                            variables={{
                              companyId: this.props.match.params.company,
                              query: this.state.searchSubmittedValue
                            }}
                          >
                            {({ loading, error, data }) => {
                              if (loading) {
                                return (<Loading />);
                              }
                              if (error || !data) {
                                return (<div>Error {JSON.stringify(error) || 'no data'}</div>);
                              }
                              if (data.company === null) {
                                return (<div>Empresa no encontrada</div>);
                              }

                              return (
                                <SearchResultsList
                                  classes={classes}
                                  activeDocumentId={this.state.activeDocumentId}
                                  searchValue={this.state.searchSubmittedValue}
                                  data={data.company}
                                  onResultClick={(
                                    e: React.MouseEvent,
                                    rowData: QCompanySearch_company_search_items
                                  ) => {
                                    const url = `/company/${data.company!.id}/documents/${rowData.item.id}`;
                                    if (e.ctrlKey) {
                                      window.open(url, '_blank');
                                    } else {
                                      const windowState = getCurrentWindowState(this);
                                      if (windowState.state) {
                                        windowState.state.activeDocumentId = rowData.item.id;
                                      }

                                      navigateWithState(
                                        this.props.history,
                                        `/company/${data.company!.id}/documents/${rowData.item.id}`,
                                        windowState
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          </CompanySearchQuery>
                        )
                      )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ) :
            (
              <LiveTable
                history={this.props.history}
                title="Documentos"
                initialPage={this.filter.page}
                options={{
                  pageSize: this.filter.pageSize,
                  rowStyle: (rowData, index) => {
                    const css: React.CSSProperties = {};
                    if (rowData.id === this.state.activeDocumentId) {
                      css.backgroundColor = ACTIVE_ROW_BGCOLOR;
                    }
                    return css;
                  }
                }}
                columns={[
                  {
                    field: 'deleted',
                    title: 'Borrado',
                    type: 'boolean',
                    filtering: true,
                    defaultSort: this.filter.order === CompanyDocsOrderColumn.DELETED ?
                      this.filter.orderDirection : undefined,
                    defaultFilter: this.filter.deleted,
                  },
                  {
                    field: 'creationDate',
                    title: 'Fecha de creación',
                    type: 'datetime',
                    sorting: true,
                    filtering: false,
                    defaultSort: this.filter.order === CompanyDocsOrderColumn.CREATION_DATE ?
                      this.filter.orderDirection : undefined,
                    render: (rowData) => <span>{formatDateTime(rowData.creationDate)}</span>
                  },
                  {
                    field: 'image',
                    title: 'Imagen',
                    sorting: false,
                    filtering: false,
                    render: (rowData) => <img src={rowData.thumbnailUrl} />,
                  },
                  {
                    field: 'docType',
                    title: 'Tipo',
                    render: (rowData: QDocs_company_docs_items) => {
                      if (rowData.isDigitalization) {
                        return (
                          <span>{rowData.docType} <VerifiedUserIcon /> </span>
                        );
                      } else {
                        return rowData.docType;
                      }
                    },
                    sorting: true,
                    filtering: true,
                    defaultSort: this.filter.order === CompanyDocsOrderColumn.DOC_TYPE ?
                      this.filter.orderDirection : undefined,
                    defaultFilter: this.filter.docType,
                    lookup: { 'DIGITAL': DocType.DIGITAL, 'IMAGE': DocType.IMAGE, 'STANDALONE': DocType.STANDALONE, },
                  },
                  {
                    field: 'status',
                    title: 'Estado',
                    filtering: true,
                    lookup: {
                      'ACCOUNTED': DocStatus.ACCOUNTED,
                      'PENDING': DocStatus.PENDING,
                      'REVIEW': DocStatus.REVIEW,
                      'UNCLEAR': DocStatus.UNCLEAR,
                      'IRRELEVANT': DocStatus.IRRELEVANT,
                    },
                    defaultSort: this.filter.order === CompanyDocsOrderColumn.STATUS ?
                      this.filter.orderDirection : undefined,
                    defaultFilter: this.filter.status,
                  },
                  {
                    field: 'accounting',
                    title: 'Contabilidad',
                    render: (rowData) =>
                      <div className={classes.acc} style={{ maxWidth: '700px' }}>{rowData.accounting}</div>,
                    filtering: false,
                    removable: true,
                    sorting: false,
                  },
                ]}
                fetchQuery={async (client, query) => {
                  const { filter, variables } = getFilterAndVariablesFromQuery(this.props, query);
                  this.filter = filter;
                  let { data } = await client.query<QDocs, QDocsVariables>({
                    query: DOCS_QUERY,
                    variables,
                    fetchPolicy: 'no-cache'
                  });
                  if (!data) {
                    return undefined;
                  }
                  if (!data.company) {
                    return undefined;
                  }
                  return data.company.docs;
                }}
                rowToPathAndState={(rowData) => {
                  const windowState = getCurrentWindowState(this);
                  if (windowState.state) {
                    windowState.state.activeDocumentId = rowData.id;
                  }
                  return {
                    pathname: `/company/${company}/documents/${rowData.id}`,
                    state: windowState,
                  };
                }}
              />
            )
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Docs);

interface SearchResultsListProps extends WithStyles<typeof styles> {
  activeDocumentId?: string;
  searchValue: string;
  data: QCompanySearch_company;
  onResultClick: (e: React.MouseEvent, rowData: QCompanySearch_company_search_items) => void;
}

function getStatusIcon(rowData: QCompanySearch_company_search_items): JSX.Element | null {
  switch (rowData.item.status) {
    case DocStatus.PENDING: { return (<PendingIcon />); }
    case DocStatus.REVIEW: { return (<ReviewIcon />); }
    case DocStatus.IRRELEVANT: { return (<IrrelevantIcon />); }
    case DocStatus.UNCLEAR: { return (<UnclearIcon />); }
    default: { return null; }
  }
}

class SearchResultsListX extends React.Component<SearchResultsListProps> {
  render = () => {
    return (
      <Paper>
        <Typography variant="h5" component="h3" className={this.props.classes.searchTitle}>
          Resultados de la búsqueda: {this.props.searchValue}
        </Typography>
        <List>
          {
            this.props.data.search.items.map((rowData: QCompanySearch_company_search_items, index: number) => {
              const title = rowData.item.title;
              const status = rowData.item.status;
              const statusIcon = getStatusIcon(rowData);
              let subtitle: string = '';
              let date: string = '';
              let amount: string = '';
              let className: string = '';
              if (rowData.item.id === this.props.activeDocumentId) {
                className = this.props.classes.activeDocument;
              }
              if (status === DocStatus.ACCOUNTED) {
                if (rowData.item.journalItems.length === 1) {
                  const journalItem: QCompanySearch_company_search_items_item_journalItems =
                    rowData.item.journalItems[0];
                  date = formatDateLikeMobile(journalItem.date);
                  amount = formatCurrencyLikeMobile(journalItem.amount, journalItem.currency);
                  if (journalItem.lines.length > 0) {
                    subtitle = rowData.item.journalItems[0].lines[0].subtitle;
                  }
                }
              }

              return (
                <React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={(e) => {
                      this.props.onResultClick(e, rowData);
                    }}
                    className={className}
                  >
                    <Grid container={true}>
                      <Grid item={true} xs={2}>
                        {
                          rowData.item.thumbnailUrl ? (
                            <img src={rowData.item.thumbnailUrl} />
                          ) : (<LibraryBooksIcon />)
                        }
                      </Grid>
                      <Grid item={true} xs={8}>
                        <ListItemText
                          primary={title}
                          secondary={subtitle}
                        />
                        <span className={this.props.classes.acc}>
                          {rowData.item.accounting}
                        </span>
                      </Grid>
                      <Grid item={true} xs={2}>
                        {statusIcon ? statusIcon : (
                          <ListItemText
                            primary={amount}
                            secondary={date}
                          />
                        )}
                      </Grid>
                      <Grid item={true} xs={12}>
                        <ListItemText
                          secondary={<div dangerouslySetInnerHTML={{ __html: rowData.text }} />}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })
          }
        </List>
      </Paper>
    );
  }
}

const SearchResultsList = withStyles(styles)(SearchResultsListX);