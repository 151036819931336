import { ApolloError } from 'apollo-client';
import { WithStyles, createStyles, Theme, withStyles, Portal, Snackbar, IconButton } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';

function onError(message: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
  if (error) {
    addError(error);
  } else {
    addError(message as string);
  }
}

export function initErrorReporter() {
  // tslint:disable-next-line: no-console
  console.log('Init error reporter');
  window.onerror = onError;
}

function addError(error: (Error | string)) {
  if (windowErrorReporter) {
    windowErrorReporter.state.errors.push(error);
    windowErrorReporter.setState({ errors: windowErrorReporter.state.errors });
  } else {
    // tslint:disable-next-line: no-console
    console.error('error and ErrorReporter is not available', error);
  }
}
export function onMutationError(error: ApolloError) {
  addError(error);
}
export function onGenericError(error: Error) {
  addError(error);
}

const styles = (theme: Theme) =>
  createStyles({});

interface ErrorReporterState {
  errors: (Error | string)[];
}

interface ErrorReporterProps extends RouteComponentProps, WithStyles<typeof styles> { }

let windowErrorReporter: ErrorReporter | undefined = undefined;

class ErrorReporter extends React.Component<ErrorReporterProps, ErrorReporterState> {
  constructor(props: ErrorReporterProps) {
    super(props);
    this.state = {
      errors: [],
    };
  }
  componentDidMount = () => {
    windowErrorReporter = this;
  }
  getMessageToRender = () => {
    if (this.state.errors.length > 0) {
      const m = this.state.errors[0];
      if ((typeof m) === 'string') {
        return m;
      } else {
        return (m as Error).message;
      }
    } else {
      return '';
    }
  }
  discardCurrentMessage = () => {
    this.setState({ errors: this.state.errors.slice(1) });
  }
  discardAllMessages = () => {
    this.setState({ errors: [] });
  }
  render = () => {
    return (
      <Portal>
        <Snackbar
          open={this.state.errors.length > 0}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          ContentProps={{
            'aria-describedby': 'errorReporter-message-id'
          }}
          message={
            <span id="errorReporter-message-id">
              {this.getMessageToRender()}
            </span>
          }
          action={[
            (
              this.state.errors.length > 1 ?
                (<IconButton
                  key="closeAll"
                  aria-label="Close All"
                  color="inherit"
                  onClick={this.discardAllMessages}
                >
                  <CancelIcon />
                </IconButton>
                ) : null
            ),
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.discardCurrentMessage}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Portal>
    );
  }
}

export default withStyles(styles)(withRouter(ErrorReporter));
