import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { QCompaniesQuery, QCompaniesQueryVariables, QCompaniesQuery_companies_items } from '../gen/QCompaniesQuery';
import { LiveTable, TableQuery } from './livetable';
import {
  CompanyStatus, PaperworkStatus, PaymentStatus, CompaniesOrderColumn,
  QueryOrderDirection
} from '../gen/globalTypes';
import { Column } from 'material-table';
import { getWindowState, ACTIVE_ROW_BGCOLOR } from '../lib/util';
import { History } from 'history';

const QUERY = gql`
  query QCompaniesQuery(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $taxIdLike: String
    $status: CompanyStatus
    $paperworkStatus: PaperworkStatus
    $paymentStatus: PaymentStatus
    $order: CompaniesOrderColumn
    $orderDirection: QueryOrderDirection
  ) {
    companies(
      pagination: { limit: $limit, offset: $offset }
      nameLike: $nameLike
      taxIdLike: $taxIdLike
      status: $status
      paperworkStatus: $paperworkStatus
      paymentStatus: $paymentStatus
      order: $order
      orderDirection: $orderDirection
    ) {
      totalCount
      items {
        id
        name
        taxId
        status
        paperworkStatus
        paymentStatus
        creationDate
        accountingLockDate
      }
    }
  }
`;

function getCompaniesOrderColumn(column: Column): CompaniesOrderColumn {
  switch (column.field) {
    case 'name': return CompaniesOrderColumn.NAME; break;
    case 'taxId': return CompaniesOrderColumn.TAX_ID; break;
    case 'status': return CompaniesOrderColumn.STATUS; break;
    case 'paperworkStatus': return CompaniesOrderColumn.PAPERWORK_STATUS; break;
    case 'paymentStatus': return CompaniesOrderColumn.PAYMENT_STATUS; break;
    default: return CompaniesOrderColumn.CREATION_DATE;
  }
}

const styles = (theme: Theme) =>
  createStyles({});

interface CompaniesListProps extends WithStyles<typeof styles> {
  history?: History; // Optional, use only to load initial state in main companies page
  title?: string;
  filter?: CompaniesListFilter;
  onCompanyRowClick?: (company: QCompaniesQuery_companies_items, state: CompaniesListWindowState) => void;
}

interface CompaniesListState {
  activeCompanyId?: string;
}

export interface CompaniesListWindowState {
  state?: CompaniesListState;
  filter?: CompaniesListFilter;
}

export interface CompaniesListFilter {
  page: number;
  pageSize: number;
  order: CompaniesOrderColumn;
  orderDirection: 'asc' | 'desc';
  name?: string;
  taxId?: String;
  status?: Array<CompanyStatus>;
  paymentStatus?: Array<PaymentStatus>;
  paperworkStatus?: Array<PaperworkStatus>;
}

function getInitialFilter(history?: History, filter?: CompaniesListFilter): CompaniesListFilter {
  const windowState = history ? getWindowState<CompaniesListWindowState>(history) : null;
  if (windowState && windowState.filter) {
    return windowState.filter;
  } else if (filter) {
    return filter;
  } else {
    return {
      page: 0,
      pageSize: 500,
      order: CompaniesOrderColumn.CREATION_DATE,
      orderDirection: 'desc',
    };
  }
}

function getInitialState(history?: History): CompaniesListState {
  const windowState = history ? getWindowState<CompaniesListWindowState>(history) : null;
  if (windowState && windowState.state) {
    return windowState.state;
  } else {
    return {};
  }
}

function getCurrentWindowState(companiesList: CompaniesList): CompaniesListWindowState {
  return {
    state: companiesList.state,
    filter: companiesList.filter,
  };
}

interface FilterAndVariables {
  filter: CompaniesListFilter;
  variables: QCompaniesQueryVariables;
}

function getFilterAndVariablesFromQuery(query: TableQuery): FilterAndVariables {
  const variables: QCompaniesQueryVariables = {
    limit: query.pageSize,
    offset: query.page * query.pageSize,
  };
  const filter: CompaniesListFilter = {
    page: query.page,
    pageSize: query.pageSize,
    order: CompaniesOrderColumn.CREATION_DATE,
    orderDirection: 'desc',
  };
  if (query.orderBy) {
    variables.order = getCompaniesOrderColumn(query.orderBy);
    filter.order = variables.order;
    variables.orderDirection = query.orderDirection === 'desc' ? QueryOrderDirection.DESC : QueryOrderDirection.ASC;
    filter.orderDirection = query.orderDirection;
  }
  for (const tableFilter of query.filters) {
    switch (tableFilter.column.field) {
      case 'name': {
        variables.nameLike = '%' + tableFilter.value.split(' ').join('%') + '%';
        filter.name = tableFilter.value;
        break;
      }
      case 'taxId': {
        variables.taxIdLike = '%' + tableFilter.value.split(' ').join('%') + '%';
        filter.taxId = tableFilter.value;
        break;
      }
      case 'status': {
        variables.status = tableFilter.value[0];
        filter.status = tableFilter.value;
        break;
      }
      case 'paperworkStatus': {
        variables.paperworkStatus = tableFilter.value[0];
        filter.paperworkStatus = tableFilter.value;
        break;
      }
      case 'paymentStatus': {
        variables.paymentStatus = tableFilter.value[0];
        filter.paymentStatus = tableFilter.value;
        break;
      }
      default:
    }
  }

  return { filter, variables };
}

class CompaniesList extends React.Component<CompaniesListProps & WithStyles<typeof styles>, CompaniesListState> {
  constructor(props: CompaniesListProps) {
    super(props);
    this.state = getInitialState(this.props.history);
    this.filter = getInitialFilter(this.props.history, this.props.filter);
  }
  filter: CompaniesListFilter;
  render = () => (
    <LiveTable
      title={this.props.title || 'Empresas'}
      options={{
        pageSize: this.filter.pageSize,
        rowStyle: (rowData, index) => {
          const css: React.CSSProperties = {};
          if (rowData.id === this.state.activeCompanyId) {
            css.backgroundColor = ACTIVE_ROW_BGCOLOR;
          }
          return css;
        }
      }}
      columns={[
        {
          title: 'Nombre',
          field: 'name',
          filtering: true,
          sorting: true,
          defaultSort: this.filter.order === CompaniesOrderColumn.NAME ?
            this.filter.orderDirection : undefined,
          defaultFilter: this.filter.name,
        },
        {
          title: 'NIF',
          field: 'taxId',
          filtering: true,
          sorting: true,
          defaultSort: this.filter.order === CompaniesOrderColumn.TAX_ID ?
            this.filter.orderDirection : undefined,
          defaultFilter: this.filter.taxId,
        },
        {
          title: 'Fecha creación',
          field: 'creationDate',
          filtering: false,
          sorting: true,
          defaultSort: this.filter.order === CompaniesOrderColumn.CREATION_DATE ?
            this.filter.orderDirection : undefined,
        },
        {
          title: 'Estado', field: 'status', filtering: true, sorting: true,
          lookup: {
            'ONBOARDING': CompanyStatus.ONBOARDING,
            'RUNNING': CompanyStatus.RUNNING,
            'STOPPED': CompanyStatus.STOPPED,
          },
          defaultSort: this.filter.order === CompaniesOrderColumn.STATUS ?
            this.filter.orderDirection : undefined,
          defaultFilter: this.filter.status,
        },
        {
          title: 'Trámite', field: 'paperworkStatus', filtering: true, sorting: true,
          lookup: {
            'NONE': PaperworkStatus.NONE,
            'NEW_FREELANCE_GATHERING_INFO': PaperworkStatus.NEW_FREELANCE_GATHERING_INFO,
            'NEW_FREELANCE_GATHERING_INFO_COMPLETED': PaperworkStatus.NEW_FREELANCE_GATHERING_INFO_COMPLETED,
            'NEW_FREELANCE_REVIEWING_PROPOSAL': PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL,
            'NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED': PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED,
            'NEW_FREELANCE_SUCCESS': PaperworkStatus.NEW_FREELANCE_SUCCESS,
            'FREELANCE_START': PaperworkStatus.FREELANCE_START,
          },
          defaultSort: this.filter.order === CompaniesOrderColumn.PAPERWORK_STATUS ?
            this.filter.orderDirection : undefined,
          defaultFilter: this.filter.paperworkStatus,
        },
        {
          title: 'Estado suscripción', field: 'paymentStatus', filtering: true, sorting: true,
          lookup: {
            'NONE': PaymentStatus.NONE,
            'SETUP': PaymentStatus.SETUP,
            'ACTIVE': PaymentStatus.ACTIVE,
            'FAILED': PaymentStatus.FAILED,
            'UPDATED': PaymentStatus.UPDATED,
            'CANCELED': PaymentStatus.CANCELED,
          },
          defaultSort: this.filter.order === CompaniesOrderColumn.PAYMENT_STATUS ?
            this.filter.orderDirection : undefined,
          defaultFilter: this.filter.paymentStatus,
        },
        {
          title: 'Bloqueo contable', field: 'accountingLockDate',
          filtering: false, sorting: false
        },
      ]}
      fetchQuery={async (client, query) => {
        const { filter, variables } = getFilterAndVariablesFromQuery(query);
        this.filter = filter;
        const { data } = await client.query<QCompaniesQuery, QCompaniesQueryVariables>({ query: QUERY, variables });
        if (!data) {
          return undefined;
        }
        return data.companies;
      }}
      onRowClick={(rowData) => {
        if (this.props.onCompanyRowClick) {
          const windowState = getCurrentWindowState(this);
          if (windowState.state) {
            windowState.state.activeCompanyId = rowData.id;
          }
          this.props.onCompanyRowClick(rowData, windowState);
        }
      }}
    />
  )
}
export default withStyles(styles)(CompaniesList);
