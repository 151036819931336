
import { Loading } from './widgets/loading';
import Loadable from 'react-loadable';
import Doc from './pages/doc/doc'; // doesn't work with code split...

export const advisorRoutes = [
  {
    path: '/',
    exact: true,
    main: Loadable({ loader: () => import('./pages/allTasks'), loading: Loading })
  },
  {
    path: '/user',
    exact: true,
    main: Loadable({ loader: () => import('./pages/users'), loading: Loading })
  },
  {
    path: '/user/:userId',
    exact: true,
    main: Loadable({ loader: () => import('./pages/user'), loading: Loading })
  },
  {
    path: '/company',
    exact: true,
    main: Loadable({ loader: () => import('./pages/companies'), loading: Loading })
  },
  {
    path: '/company/:company',
    exact: true,
    main: Loadable({ loader: () => import('./pages/dashboard'), loading: Loading })
  },
  {
    path: '/company/:company/documents',
    exact: true,
    main: Loadable({ loader: () => import('./pages/docs'), loading: Loading })
  },
  {
    path: '/company/:company/review',
    exact: true,
    main: Loadable({ loader: () => import('./pages/review'), loading: Loading })
  },
  {
    path: '/company/:company/reports',
    exact: true,
    main: Loadable({ loader: () => import('./pages/reports'), loading: Loading })
  },
  {
    path: '/company/:company/settings',
    exact: true,
    main: Loadable({ loader: () => import('./pages/settings'), loading: Loading })
  },
  {
    path: '/company/:company/paperwork',
    exact: true,
    main: Loadable({ loader: () => import('./pages/paperwork'), loading: Loading })
  },
  {
    path: '/company/:company/documents/:doc',
    exact: true,
    main: Doc
  },
  {
    path: '/extractions',
    exact: true,
    main: Loadable({ loader: () => import('./pages/extractions'), loading: Loading })
  },
  {
    path: '/extractions/:doc',
    exact: true,
    main: Loadable({ loader: () => import('./pages/extraction/advisorExtractionDialog'), loading: Loading })
  },
];

export const transcriptorRoutes = [
  {
    path: '/',
    exact: true,
    main: Loadable({ loader: () => import('./pages/transcriptorHome'), loading: Loading })
  },
  {
    path: '/extractions/:doc',
    exact: true,
    main: Loadable({ loader: () => import('./pages/extraction/transcriptorExtractionDialog'), loading: Loading })
  },
];
