import * as React from 'react';
import {
  createStyles, withStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import ExtractionDialog from './extractionDialog';

interface Props extends
  RouteComponentProps<{
    doc: string;
  }> { }

class TranscriptorExtractionDialog extends React.Component<Props, {}> {
  render = () => {
    return (
      <ExtractionDialog
        transcriptorMode
        {...this.props}
        handleNavigate={(id: string | null) => {
          this.props.history.push(id ? `/extractions/${id}` : '/');
        }}
      />
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({});

export default withStyles(styles)(TranscriptorExtractionDialog);
