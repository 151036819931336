/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppType {
  BANK = "BANK",
  CARD = "CARD",
  FD1 = "FD1",
  FD2 = "FD2",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  STRIPE = "STRIPE",
}

export enum CompaniesOrderColumn {
  CREATION_DATE = "CREATION_DATE",
  NAME = "NAME",
  PAPERWORK_STATUS = "PAPERWORK_STATUS",
  PAYMENT_STATUS = "PAYMENT_STATUS",
  STATUS = "STATUS",
  TAX_ID = "TAX_ID",
}

export enum CompanyDocsOrderColumn {
  CREATION_DATE = "CREATION_DATE",
  DELETED = "DELETED",
  DOC_TYPE = "DOC_TYPE",
  MODIFICATION_DATE = "MODIFICATION_DATE",
  STATUS = "STATUS",
}

export enum CompanyStatus {
  ONBOARDING = "ONBOARDING",
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
}

export enum DigitalizationDatabaseDocsType {
  EXPENSE = "EXPENSE",
  INCOME = "INCOME",
}

export enum DigitalizationDatabaseTaxType {
  IRPF = "IRPF",
  IVA = "IVA",
}

export enum DocStatus {
  ACCOUNTED = "ACCOUNTED",
  IRRELEVANT = "IRRELEVANT",
  PENDING = "PENDING",
  REVIEW = "REVIEW",
  UNCLEAR = "UNCLEAR",
}

export enum DocType {
  DIGITAL = "DIGITAL",
  IMAGE = "IMAGE",
  STANDALONE = "STANDALONE",
}

export enum ExtractionType {
  INVOICE = "INVOICE",
  IRRELEVANT = "IRRELEVANT",
  REVIEW = "REVIEW",
  UNCLEAR = "UNCLEAR",
  UNKNOWN = "UNKNOWN",
}

export enum InvoiceCategory {
  GASOLINE = "GASOLINE",
  NO_CATEGORY = "NO_CATEGORY",
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

export enum InvoiceRectification {
  NO = "NO",
  REPLACEMENT = "REPLACEMENT",
}

export enum InvoiceType {
  ISP = "ISP",
  NORMAL = "NORMAL",
}

export enum PaperworkStatus {
  FREELANCE_START = "FREELANCE_START",
  NEW_FREELANCE_GATHERING_INFO = "NEW_FREELANCE_GATHERING_INFO",
  NEW_FREELANCE_GATHERING_INFO_COMPLETED = "NEW_FREELANCE_GATHERING_INFO_COMPLETED",
  NEW_FREELANCE_REVIEWING_PROPOSAL = "NEW_FREELANCE_REVIEWING_PROPOSAL",
  NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED = "NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED",
  NEW_FREELANCE_SUCCESS = "NEW_FREELANCE_SUCCESS",
  NONE = "NONE",
}

export enum PaymentStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  NONE = "NONE",
  SETUP = "SETUP",
  UPDATED = "UPDATED",
}

export enum QueryOrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UploadType {
  CAMERA = "CAMERA",
  FILE = "FILE",
  NIF_BACK = "NIF_BACK",
  NIF_FRONT = "NIF_FRONT",
  SIGNATURE = "SIGNATURE",
}

export enum UserNotificationChannel {
  EMAIL = "EMAIL",
  INAPP = "INAPP",
  PUSH = "PUSH",
}

export enum UserProfile {
  ADVISOR = "ADVISOR",
  TRANSCRIPTOR = "TRANSCRIPTOR",
  USER = "USER",
}

export interface ExpenseSyncJobConfig {
  app: string;
  fromDate?: string | null;
  toDate?: string | null;
}

export interface Extraction {
  extractionType: ExtractionType;
  receivedDate?: string | null;
  invoice?: InvoiceExtraction | null;
  message?: string | null;
}

export interface ExtractionValue {
  extracted: boolean;
  positions: number[];
  page: number;
  raw: string;
  value?: string | null;
}

export interface InvoiceExtraction {
  category: ExtractionValue;
  rectification: ExtractionValue;
  invoiceType: ExtractionValue;
  number: ExtractionValue;
  date: ExtractionValue;
  currency: ExtractionValue;
  senderId: ExtractionValue;
  senderName: ExtractionValue;
  senderCountry: ExtractionValue;
  senderZipcode: ExtractionValue;
  receiverId: ExtractionValue;
  receiverName: ExtractionValue;
  receiverCountry: ExtractionValue;
  receiverZipcode: ExtractionValue;
  iva21Amount: ExtractionValue;
  iva10Amount: ExtractionValue;
  iva4Amount: ExtractionValue;
  irpfAmount: ExtractionValue;
  irpfPercent: ExtractionValue;
  netTotal: ExtractionValue;
  grossTotal: ExtractionValue;
  _category?: InvoiceCategory | null;
  _invoiceType?: InvoiceType | null;
  _invoiceRectification?: InvoiceRectification | null;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export interface UserNotificationData {
  company?: string | null;
  paperworkStatus?: PaperworkStatus | null;
  url?: string | null;
}

export interface UserNotificationMessage {
  title: string;
  body: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
