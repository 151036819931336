import { GraphQLError } from 'graphql';
import * as React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  createStyles, WithStyles, withStyles, List, ListItem, ListItemText, ListItemIcon, Divider,
  InputAdornment, IconButton, Typography, Card, CardContent, CardActions, Button, DialogTitle, Dialog, Select,
  DialogContent, DialogActions, Grid, TextField, Checkbox, FormLabel, FormControl, RadioGroup, MenuItem,
  FormControlLabel, Radio, ListSubheader, FormHelperText, Tooltip
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { Loading } from '../widgets/loading';
import { PaperworkStatus, UserNotificationMessage, UserNotificationChannel, UploadType } from '../gen/globalTypes';
import { formatDate } from '../lib/util';
import { Confirm } from '../lib/utilBrowser';
import { getNIFType } from '../lib/nif';
import { onMutationError } from '../lib/errorReporter';
import { notificationsClient } from '../notifications';
import {
  QCompanyPaperworkInfo, QCompanyPaperworkInfoVariables,
  QCompanyPaperworkInfo_company
} from '../gen/QCompanyPaperworkInfo';
import { MUpdatePaperwork, MUpdatePaperworkVariables } from '../gen/MUpdatePaperwork';
import { MSendUserNotification, MSendUserNotificationVariables } from '../gen/MSendUserNotification';
import { resetStore } from '../client';
import { UploadPaperwork } from '../widgets/uploadPaperwork';
import { getIntercomUserUrl } from '../lib/intercom';
import Comment from '@material-ui/icons/Comment';
import { KeyboardDatePicker } from '@material-ui/pickers';

const COMPANY_PAPERWORK_INFO_QUERY = gql`
  query QCompanyPaperworkInfo($company:ID!) {
    company(id: $company) {
      id
      name
      taxId
      status
      paymentStatus
      paperworkStatus
      signatureHash
      signatureUrl
      nifFrontHash
      nifFrontUrl
      nifBackHash
      nifBackUrl
      trialUntil
      enabledUntil
      activityStartDate
      activityName
      activityNotes
      additionalNotes
      activityIAE
      activityCNAE
      freelanceLast3Years
      addressStreet
      addressCity
      addressZipcode
      addressProvince
      iban
      socialSecurityMutual
      socialSecurityNumber
      disability
      users {
          id
          name
          email
      }
    }
  }
  `;

class CompanyPaperworkInfoQuery extends Query<QCompanyPaperworkInfo, QCompanyPaperworkInfoVariables> { }

const UPDATE_PAPERWORK_MUTATION = gql`
  mutation MUpdatePaperwork(
    $company: ID!, 
    $paperworkStatus: PaperworkStatus, 
    $activityStartDate: Date,
    $activityName: String,
    $activityNotes: String,
    $additionalNotes: String,
    $activityIAE: String,
    $activityCNAE: String,
    $freelanceLast3Years: Boolean,
    $addressStreet: String,
    $addressCity: String,
    $addressZipcode: String,
    $addressProvince: String,
    $iban: String,
    $socialSecurityMutual: String,
    $socialSecurityNumber: String,
    $disability: Boolean
  ) {
    updatePaperwork(
        company: $company
        paperworkStatus: $paperworkStatus,
        activityStartDate: $activityStartDate,
        activityName: $activityName,
        activityNotes: $activityNotes,
        additionalNotes: $additionalNotes,
        activityIAE: $activityIAE,
        activityCNAE: $activityCNAE,
        freelanceLast3Years: $freelanceLast3Years,
        addressStreet: $addressStreet,
        addressCity: $addressCity,
        addressZipcode: $addressZipcode,
        addressProvince: $addressProvince,
        iban: $iban,
        socialSecurityMutual: $socialSecurityMutual,
        socialSecurityNumber: $socialSecurityNumber,
        disability: $disability
    ) {
      id
    }
  }
  `;
class UpdatePaperworkMutation extends Mutation<MUpdatePaperwork, MUpdatePaperworkVariables> { }

const SEND_USER_NOTIFICATION = gql`
  mutation MSendUserNotification(
    $user: ID!, 
    $message: UserNotificationMessage!, 
    $channel: UserNotificationChannel!,
    $data: UserNotificationData!,
    $paperworkStatus: PaperworkStatus!,
    $company: ID!,
  ) {
    sendUserNotification(
        user: $user,
        message: $message,
        channel: $channel,
        data: $data,
    )
    updatePaperwork(
        company: $company
        paperworkStatus: $paperworkStatus
    ) {
        id
    }

  }
  `;
class SendUserNotification extends Mutation<MSendUserNotification, MSendUserNotificationVariables> { }

const styles = (theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  appsTitle: {
    marginBottom: theme.spacing(2),
  },
  app: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  appError: {
    padding: theme.spacing(2),
    backgroundColor: '#ff0000',
    color: '#ffffff',
    width: 'auto'
  },
  toolbar: {
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
});

interface Props extends
  RouteComponentProps<{
    company: string;
  }>,
  WithStyles<typeof styles> { }

interface SettingsState {
  newFreelanceDataFormDialogOpened: boolean;
  notificationUser: string;
}

class Settings extends React.Component<Props, SettingsState> {
  state = {
    newFreelanceDataFormDialogOpened: false,
    notificationUser: '',
  } as SettingsState;
  readyForSepa = (company: QCompanyPaperworkInfo_company) => {
    return (
      !!company.name &&
      !!company.taxId &&
      !!company.activityStartDate &&
      !!company.addressStreet &&
      !!company.addressCity &&
      !!company.addressZipcode &&
      !!company.addressProvince &&
      !!company.iban &&
      !!company.socialSecurityNumber
    );
  }
  readyForReviewingProposal = (company: QCompanyPaperworkInfo_company | null) => {
    if (company != null) {
      return (
        !!company.name &&
        !!company.taxId &&
        !!company.activityStartDate &&
        !!company.activityCNAE &&
        !!company.activityIAE &&
        !!company.addressStreet &&
        !!company.addressCity &&
        !!company.addressZipcode &&
        !!company.addressProvince &&
        !!company.iban &&
        !!company.socialSecurityMutual &&
        !!company.socialSecurityNumber
      );
    } else {
      return false;
    }
  }
  readyForNewFreelanceSuccess = (company: QCompanyPaperworkInfo_company | null) => {
    return this.readyForReviewingProposal(company);
  }
  openNIFDocument = (company: QCompanyPaperworkInfo_company | null) => {
    if (company != null) {
      var win = window.open('', '_blank');
      if (win != null) {
        var html = '<html><head><title>Documento DNI/NIE</title></head><body>';
        if (company.nifFrontUrl) {
          html += '<img style="width: 100%;" src="' + company.nifFrontUrl + '"/><br/>';
        }
        if (company.nifBackUrl) {
          html += '<img style="width: 100%;" src="' + company.nifBackUrl + '"/><br/>';
        }
        html += '</body></html>';
        win.document.body.innerHTML = html;
        // win.focus();
      }
    }
  }
  openSEPADocument = (company: QCompanyPaperworkInfo_company | null) => {
    if (company != null) {
      let url = '/public/sepa.html?';
      var query = '';
      var nifType = getNIFType(company.taxId || '');
      // Fields that should always be included in uppercase
      ['name', 'taxId',
        'addressStreet', 'addressCity', 'addressZipcode', 'addressProvince',
        'socialSecurityNumber'].forEach((key) => {
          query += key + '=' + company[key].toUpperCase() + '&';
        });
      // Fields that should no contain blanks in the middle
      ['iban'].forEach((key) => {
        query += key + '=' + company[key].replace(/\s/g, '') + '&';
      });
      ['signatureUrl'].forEach((key) => {
        query += key + '=' + company[key] + '&';
      });
      // INVALID nifTypes we assume it's a passport for now (jom@20191008)
      const nifTypes = ['CIF', 'DNI', 'NIE', 'PASSPORT'];
      query += 'taxIdType_' + nifTypes[nifType] + '=x&';
      var w = window.open(encodeURI(url + query), '_blank');
      if (w) {
        w.focus();
      }
    }
  }
  onNewFreelanceDialogToggle = () => {
    this.setState({ newFreelanceDataFormDialogOpened: !this.state.newFreelanceDataFormDialogOpened });
  }
  getCompanyPaperworkInfoQueryVariables(): QCompanyPaperworkInfoVariables {
    return {
      company: this.props.match.params.company,
    };
  }
  onUserNotification = async (
    mutationFn: MutationFn<MSendUserNotification, MSendUserNotificationVariables>,
    company: QCompanyPaperworkInfo_company | null,
    paperworkStatus: PaperworkStatus) => {

    if (company != null) {
      let message: UserNotificationMessage | undefined = undefined;
      if (paperworkStatus === PaperworkStatus.NEW_FREELANCE_GATHERING_INFO) {
        message = {
          title: 'Necesitamos más información',
          body: 'Abre la App de Abaq y completa la información que falta'
        };
      } else if (paperworkStatus === PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL) {
        message = {
          title: 'Tu documentación está lista',
          body: 'Revisa que todo está correcto y procederemos a darte de alta como autónomo.'
        };
      } else if (paperworkStatus === PaperworkStatus.NEW_FREELANCE_SUCCESS) {
        message = {
          title: '¡Enhorabuena! Ya eres autónomo',
          body: 'Se han completado con éxito los trámites en Hacienda y en la Seguridad Social.'
        };
      } else {
        notificationsClient.addMessage('Mensaje inválido');
      }

      if (message) {
        const actionConfirmed = await Confirm({
          body: (
            <div>Se enviará la siguiente notificación PUSH al usuario
              y se cambiará el estado del trámite de la empresa a:
              <strong>{paperworkStatus}</strong>.
              <br /><hr /><br />{message.title}<br />{message.body}<br /><br /><hr /></div>
          )
        });
        if (actionConfirmed) {
          const variables: MSendUserNotificationVariables = {
            user: this.state.notificationUser,
            message: message,
            channel: UserNotificationChannel.PUSH,
            data: {
              company: company.id,
              paperworkStatus: paperworkStatus,
            },
            company: company.id,
            paperworkStatus: paperworkStatus,
          };

          const success = await mutationFn({ variables });
          if (success) {
            notificationsClient.addMessage('Notificación enviada correctamente');
          } else {
            notificationsClient.addMessage('La notificación no ha podido enviarse');
          }
        }
      } else {
        notificationsClient.addMessage('Notificación INVALIDA');
      }

      resetStore();
    }
  }
  render = () => {
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <CompanyPaperworkInfoQuery
          query={COMPANY_PAPERWORK_INFO_QUERY}
          variables={this.getCompanyPaperworkInfoQueryVariables()}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return (
                <pre style={{ whiteSpace: 'pre-line' }}>
                  {error.message}
                </pre>
              );
            }
            if (!data) {
              return <div />;
            }
            if (!data.company) {
              return <div />;
            }

            return (
              <React.Fragment>
                <div className={classes.section}>
                  <PaperworkInfo
                    classes={classes}
                    company={data.company}
                  />
                </div>
                <div className={classes.section}>
                  <Card style={{ padding: 12 }}>
                    <CardContent>
                      <Typography variant="h6">Alta como Nuevo Autónomo</Typography>
                      <NewFreelanceInfo
                        classes={classes}
                        company={data.company}
                      />
                      <Grid container={true} spacing={4}>
                        <Grid item={true} md={4}>
                          <List>
                            <ListSubheader>Acciones</ListSubheader>
                            <Divider />

                            <ListItem
                              button
                              onClick={this.onNewFreelanceDialogToggle}
                            >
                              <ListItemText
                                primary="Modificar datos"
                                secondary="Modifica los datos asociados al alta de nuevo autónomo de esta empresa."
                              />
                            </ListItem>

                            <ListItem
                              button
                              onClick={() => this.openNIFDocument(data.company)}
                              disabled={data.company.nifFrontUrl == null && data.company.nifBackUrl == null}
                            >
                              <ListItemText
                                primary="Ampliar NIF"
                                secondary="Muestra las imagenes del NIF ampliadas en nueva ventana para facilitar la copia de los datos."
                              />
                              <ListItemIcon>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </ListItem>

                            <ListItem
                              button
                              onClick={() => this.openSEPADocument(data.company)}
                              disabled={!this.readyForSepa(data.company)}
                            >
                              <ListItemText
                                primary="Generar documento SEPA para la domiciliación del recibo de autónomos"
                                secondary="Se abrirá una nueva ventana para poder generar el documento de domiciliación a partir de los datos disponibles de la parte superior."
                              />
                              <ListItemIcon>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </ListItem>
                          </List>

                        </Grid>
                        <Grid item={true} md={4}>
                          <List>
                            <ListSubheader>
                              Notificaciones para
                              &nbsp;&nbsp;
                              <Select
                                native
                                value={this.state.notificationUser}
                                onChange={(e) => this.setState({ notificationUser: '' + e.target.value })}
                              >
                                <option value="" />
                                {
                                  data.company.users.map((user) => {
                                    return (<option key={user.id} value={user.id}>{user.name}</option>);
                                  })
                                }
                              </Select>
                              &nbsp;&nbsp;
                              <Tooltip title="Usuario en Intercom">
                                <IconButton
                                  disabled={!this.state.notificationUser}
                                  onClick={(event) => {
                                    const w = window.open(getIntercomUserUrl(this.state.notificationUser), '_blank');
                                    if (w) {
                                      w.focus();
                                    }
                                  }}
                                >
                                  <Comment />
                                </IconButton>
                              </Tooltip>
                            </ListSubheader>
                            <Divider />
                            <SendUserNotification
                              mutation={SEND_USER_NOTIFICATION}
                              onError={(e) => onMutationError(e)}
                            >
                              {(mutationFn, options) => (
                                <ListItem
                                  disabled={!this.state.notificationUser || options.loading}
                                  button
                                  onClick={() => {
                                    this.onUserNotification(
                                      mutationFn,
                                      data.company,
                                      PaperworkStatus.NEW_FREELANCE_GATHERING_INFO);
                                  }}
                                >
                                  <ListItemIcon>
                                    <AddAPhotoIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Recoger información"
                                    secondary="Se envía notificación al usuario para que introduzca desde el móvil la documentación e información necesaria para completar el proceso de alta de autónomo en la AEAT y la Seguridad Social."
                                  />
                                </ListItem>
                              )}
                            </SendUserNotification>
                            <SendUserNotification
                              mutation={SEND_USER_NOTIFICATION}
                              onError={(e) => onMutationError(e)}
                            >
                              {(mutationFn, options) => (
                                <ListItem
                                  disabled={!this.state.notificationUser || options.loading ||
                                    !this.readyForReviewingProposal(data.company)}
                                  button
                                  onClick={() => {
                                    this.onUserNotification(
                                      mutationFn,
                                      data.company,
                                      PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL);
                                  }}
                                >
                                  <ListItemIcon>
                                    <EditIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Revisar y firmar"
                                    secondary="Se enviará notificación al usuario para revise la información preparada por el gestor y la confirme con su firma (con el dedo)"
                                  />
                                </ListItem>
                              )}
                            </SendUserNotification>
                            <SendUserNotification
                              mutation={SEND_USER_NOTIFICATION}
                              onError={(e) => onMutationError(e)}
                            >
                              {(mutationFn, options) => (
                                <ListItem
                                  disabled={!this.state.notificationUser || options.loading ||
                                    !this.readyForNewFreelanceSuccess(data.company)}
                                  button
                                  onClick={() => {
                                    this.onUserNotification(
                                      mutationFn,
                                      data.company,
                                      PaperworkStatus.NEW_FREELANCE_SUCCESS);
                                  }}
                                >
                                  <ListItemIcon>
                                    <CheckCircleIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Alta completada"
                                    secondary="Se enviará notificación al usuario para notificarle que ya le hemos dado de alta en AEAT, Seguridad Social y en Abaq como cliente."
                                  />
                                </ListItem>
                              )}
                            </SendUserNotification>
                          </List>
                        </Grid>
                        <Grid item={true} md={4}>
                          <List>
                            <ListSubheader>Recursos externos</ListSubheader>
                            <Divider />
                            <ListItem
                              button
                              onClick={(event) => {
                                var w = window.open('https://www.iae.com.es/buscador', '_blank');
                                if (w) {
                                  w.focus();
                                }
                              }}
                            >
                              <ListItemText
                                primary="Buscador IAE"
                                secondary="Se abrirá una nueva ventana el servicio web para poder buscar códigos IAE"
                              />
                              <ListItemIcon>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </ListItem>
                            <ListItem
                              button
                              onClick={(event) => {
                                var w = window.open('https://www.iberaval.es/conversor/', '_blank');
                                if (w) {
                                  w.focus();
                                }
                              }}
                            >
                              <ListItemText
                                primary="Conversor IAE / CNAE"
                                secondary="Se abrirá una nueva ventana el servicio web para poder convertir códigos IAE a CNAE"
                              />
                              <ListItemIcon>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </ListItem>
                            <ListItem
                              button
                              onClick={(event) => {
                                var w = window.open('https://paeelectronico.circe.es/', '_blank');
                                if (w) {
                                  w.focus();
                                }
                              }}
                            >
                              <ListItemText
                                primary="Abrir sitio web del PAE Electrónico"
                                secondary="Se abrirá una nueva ventana la web del pae electrónico para proceder a realizar el alta del autónomo en la AEAT y Seguridad Social"
                              />
                              <ListItemIcon>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </ListItem>
                          </List>
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </div>
                <NewFreelanceDataForm
                  company={data.company}
                  open={this.state.newFreelanceDataFormDialogOpened}
                  onClose={this.onNewFreelanceDialogToggle}
                />
              </React.Fragment>
            );
          }}
        </CompanyPaperworkInfoQuery>
      </React.Fragment >
    );
  }
}
export default withStyles(styles)(Settings);

interface PaperworkInfoState {
  statusDialogOpened: boolean;
}
interface PaperworkInfoProps {
  company: QCompanyPaperworkInfo_company;
}

class PaperworkInfo extends React.PureComponent<PaperworkInfoProps & WithStyles<typeof styles>, PaperworkInfoState> {
  state = {
    statusDialogOpened: false,
  } as PaperworkInfoState;
  onStatusToggle = () => {
    this.setState({ statusDialogOpened: !this.state.statusDialogOpened });
  }
  render = () => {
    const company = this.props.company;
    const classes = this.props.classes;
    return (
      <Card style={{ padding: 12 }}>
        <CardContent>
          <Typography variant="h6" className={classes.appsTitle}>Trámite en curso</Typography>
          <TextField
            label="Estado actual de trámite"
            value={company.paperworkStatus || ''}
            fullWidth={true}
            disabled={true}
          />
          <StatusDialog
            open={this.state.statusDialogOpened}
            company={company}
            onClose={this.onStatusToggle}
          />
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={this.onStatusToggle}
          >
            Actualizar estado
          </Button>
        </CardActions>
      </Card>
    );
  }
}

interface StatusDialogProps {
  open: boolean;
  company: QCompanyPaperworkInfo_company;
  onClose: () => void;
}
interface StatusDialogState {
  paperworkStatus: PaperworkStatus;
}

class StatusDialog extends React.Component<StatusDialogProps, StatusDialogState> {
  state = {
    paperworkStatus: this.props.company.paperworkStatus,
  } as StatusDialogState;
  onConfirm = async (mutationFn: MutationFn<MUpdatePaperwork, MUpdatePaperworkVariables>) => {
    const variables: MUpdatePaperworkVariables = {
      company: this.props.company.id,
      paperworkStatus: this.state.paperworkStatus,
    };
    if (this.state.paperworkStatus !== this.props.company.paperworkStatus) {
      await mutationFn({ variables });
      notificationsClient.addMessage('Estado de trámite actualizado');
      resetStore();
    }
    this.props.onClose();
  }
  render = () => {
    if (this.props.open) {
      return (
        <Dialog
          maxWidth="lg"
          open={true}
          onClose={this.props.onClose}
        >
          <DialogTitle>Cambiar estado de trámite</DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Typography color="error">
                  <b>MUY IMPORTANTE:</b> El estado del trámite se cambia
                  automáticamente a través de las acciones de flujo.
                  Asegúrate de saber lo que estás haciendo si modificas
                  el estado del trámite desde esta opción.
                                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Estado de trámite</FormLabel>
                  <RadioGroup
                    value={this.state.paperworkStatus}
                    onChange={(e, value) => { this.setState({ paperworkStatus: value as PaperworkStatus }); }}
                  >
                    <FormControlLabel
                      value={PaperworkStatus.NONE}
                      control={<Radio />}
                      label="NONE"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.NEW_FREELANCE_GATHERING_INFO}
                      control={<Radio />}
                      label="NEW_FREELANCE_GATHERING_INFO"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.NEW_FREELANCE_GATHERING_INFO_COMPLETED}
                      control={<Radio />}
                      label="NEW_FREELANCE_GATHERING_INFO_COMPLETED"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL}
                      control={<Radio />}
                      label="NEW_FREELANCE_REVIEWING_PROPOSAL"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED}
                      control={<Radio />}
                      label="NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.NEW_FREELANCE_SUCCESS}
                      control={<Radio />}
                      label="NEW_FREELANCE_SUCCESS"
                    />
                    <FormControlLabel
                      value={PaperworkStatus.FREELANCE_START}
                      control={<Radio />}
                      label="FREELANCE_START"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>Cancelar</Button>
            <UpdatePaperworkMutation
              mutation={UPDATE_PAPERWORK_MUTATION}
              onError={(e) => onMutationError(e)}
            >
              {(mutationFn, { loading }) => (
                <Button
                  onClick={() => { this.onConfirm(mutationFn); }}
                  disabled={loading}
                >
                  Guardar
                </Button>
              )}
            </UpdatePaperworkMutation>
          </DialogActions>
        </Dialog >
      );
    } else {
      return null;
    }
  }
}

interface NewFreelanceDataFormProps {
  company: QCompanyPaperworkInfo_company;
  open: boolean;
  onClose: () => void;
}
interface NewFreelanceDataFormState {
  errors: any;
  name?: string;
  taxId?: string;
  activityStartDate?: string;
  activityName?: string;
  activityNotes?: string;
  additionalNotes?: string;
  activityIAE?: string;
  activityCNAE?: string;
  freelanceLast3Years?: boolean;
  addressStreet?: string;
  addressCity?: string;
  addressZipcode?: string;
  addressProvince?: string;
  iban?: string;
  socialSecurityMutual?: string;
  socialSecurityNumber?: string;
  disability?: boolean;
}
class NewFreelanceDataForm extends React.Component<NewFreelanceDataFormProps, NewFreelanceDataFormState> {
  state = {
    errors: {},
    name: this.props.company.name,
    taxId: this.props.company.taxId,
    activityStartDate: this.props.company.activityStartDate || '',
    activityName: this.props.company.activityName,
    activityNotes: this.props.company.activityNotes,
    additionalNotes: this.props.company.additionalNotes,
    activityIAE: this.props.company.activityIAE,
    activityCNAE: this.props.company.activityCNAE,
    freelanceLast3Years: this.props.company.freelanceLast3Years,
    addressStreet: this.props.company.addressStreet,
    addressCity: this.props.company.addressCity,
    addressZipcode: this.props.company.addressZipcode,
    addressProvince: this.props.company.addressProvince,
    iban: this.props.company.iban,
    socialSecurityMutual: this.props.company.socialSecurityMutual,
    socialSecurityNumber: this.props.company.socialSecurityNumber,
    disability: this.props.company.disability,
  } as NewFreelanceDataFormState;

  changeField = (
    key: keyof NewFreelanceDataFormState,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

    const state = {};
    state[key] = (e.target as HTMLInputElement).value || '';
    this.setState(state);
  }
  onConfirm = async (mutationFn: MutationFn<MUpdatePaperwork, MUpdatePaperworkVariables>) => {
    const variables: MUpdatePaperworkVariables = {
      company: this.props.company.id,
      activityStartDate: this.state.activityStartDate,
      activityName: this.state.activityName,
      activityNotes: this.state.activityNotes,
      additionalNotes: this.state.additionalNotes,
      activityIAE: this.state.activityIAE,
      activityCNAE: this.state.activityCNAE,
      freelanceLast3Years: this.state.freelanceLast3Years,
      addressStreet: this.state.addressStreet,
      addressCity: this.state.addressCity,
      addressZipcode: this.state.addressZipcode,
      addressProvince: this.state.addressProvince,
      iban: this.state.iban,
      socialSecurityMutual: this.state.socialSecurityMutual,
      socialSecurityNumber: this.state.socialSecurityNumber,
      disability: this.state.disability,
    };
    const result = await mutationFn({ variables });
    if (result) {
      notificationsClient.addMessage('Datos de alta nuevo autónomo actualizados');
      resetStore();
      this.props.onClose();
    }
  }
  processErrors = (error: GraphQLError) => {
    if (error != null && error.extensions != null && error.extensions.exception != null) {
      const abaqErrors = error.extensions.exception.abaqErrors as any[];
      const errors = {};
      if (abaqErrors != null) {
        abaqErrors.forEach((err) => errors[err.code] = err.message);
      }
      this.setState({ errors: errors });
    }
    notificationsClient.addMessage(error.message);
  }
  render = () => {
    if (this.props.open) {
      return (
        <Dialog
          open={true}
          maxWidth="xl"
          fullWidth={true}
          onClose={this.props.onClose}
        >
          <DialogTitle>Modificación datos de alta como nuevo autónomo</DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={8}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <Typography variant="subtitle2">Formularo registro nuevo autónomo</Typography>
                    <Divider />
                  </Grid>
                  <Grid item={true} md={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="YYYY-MM-DD"
                      margin="normal"
                      fullWidth
                      label="Fecha de inicio"
                      value={this.state.activityStartDate || null}
                      autoOk={true}
                      onChange={(d) => {
                        if (d && d.isValid()) {
                          this.setState({ activityStartDate: d.format('YYYY-MM-DD') });
                        } else {
                          this.setState({ activityStartDate: undefined });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item={true} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!this.state.freelanceLast3Years}
                          onChange={(e, checked) => {
                            this.setState({
                              freelanceLast3Years: checked
                            });
                          }}
                        />
                      }
                      label="He sido autónomo anteriormente"
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      // error={!this.state.activityName}
                      label="Actividad"
                      value={this.state.activityName}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('activityName', e); }}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      multiline
                      // error={!this.state.activityNotes}
                      label="Notas de actividad"
                      rowsMax={4}
                      value={this.state.activityNotes}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('activityNotes', e); }}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      error={!this.state.socialSecurityNumber}
                      label="Núm. Seguridad Social"
                      value={this.state.socialSecurityNumber || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('socialSecurityNumber', e); }}
                    />
                  </Grid>
                  <Grid item={true} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!this.state.disability}
                          onChange={(e, checked) => {
                            this.setState({
                              disability: checked
                            });
                          }}
                        />
                      }
                      label="Tiene acreditada una discapacidad"
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      error={!this.state.iban || this.state.errors.iban}
                      label="Cuenta bancaria"
                      value={this.state.iban || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('iban', e); }}
                      helperText={this.state.errors.iban}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item={true} xs={4}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <Typography variant="subtitle2">A rellenar por el gestor</Typography>
                    <Divider />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      multiline
                      error={!this.state.activityIAE}
                      label="Código IAE"
                      helperText="Puedes indicar múltiples códigos IAE en múltiples líneas"
                      rowsMax={10}
                      value={this.state.activityIAE || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('activityIAE', e); }}
                      InputProps={{
                        endAdornment:
                          (<InputAdornment position="end">
                            <IconButton
                              onClick={(event) => {
                                var w = window.open('https://www.iae.com.es/buscador', '_blank');
                                if (w) {
                                  w.focus();
                                }
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </InputAdornment>)
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      multiline
                      error={!this.state.activityCNAE}
                      label="Código CNAE"
                      helperText="Puedes indicar múltiples códigos CNAE en múltiples líneas"
                      rowsMax={10}
                      value={this.state.activityCNAE || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('activityCNAE', e); }}
                      InputProps={{
                        endAdornment:
                          (<InputAdornment position="end">
                            <IconButton
                              onClick={(event) => {
                                var w = window.open('https://www.iberaval.es/conversor/', '_blank');
                                if (w) {
                                  w.focus();
                                }
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </InputAdornment>)
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <FormControl component="fieldset" fullWidth={true}>
                      <FormLabel component="legend">Mútua</FormLabel>
                      <Select
                        error={!this.state.socialSecurityMutual}
                        autoWidth={true}
                        fullWidth={true}
                        value={this.state.socialSecurityMutual}
                        onChange={(e) => { this.setState({ socialSecurityMutual: e.target.value as string }); }}
                      >
                        <MenuItem value="ACTIVA MUTUA 2008">ACTIVA MUTUA 2008</MenuItem>
                        <MenuItem value="ASEPEYO">ASEPEYO</MenuItem>
                        <MenuItem value="EGARSAT">EGARSAT</MenuItem>
                        <MenuItem value="FRATERNIDAD - MUPRESPA">FRATERNIDAD - MUPRESPA</MenuItem>
                        <MenuItem value="FREMAP">FREMAP</MenuItem>
                        <MenuItem value="IBERMUTUA">IBERMUTUA</MenuItem>
                        <MenuItem value="MAC, MUTUA DE ACCIDENTES DE CANARIAS">
                          MAC, MUTUA DE ACCIDENTES DE CANARIAS
                        </MenuItem>
                        <MenuItem value="MAZ, MUTUA DE ACCIDENTES DE ZARAGOZA">
                          MAZ, MUTUA DE ACCIDENTES DE ZARAGOZA
                        </MenuItem>
                        <MenuItem value="MUTUA BALEAR">MUTUA BALEAR</MenuItem>
                        <MenuItem value="MUTUA DE ANDALUCÍA Y CEUTA - CESMA">
                          MUTUA DE ANDALUCÍA Y CEUTA - CESMA
                        </MenuItem>
                        <MenuItem value="MUTUA INTERCOMARCAL">MUTUA INTERCOMARCAL</MenuItem>
                        <MenuItem value="MUTUA MONTAÑESA">MUTUA MONTAÑESA</MenuItem>
                        <MenuItem value="MUTUA NAVARRA">MUTUA NAVARRA</MenuItem>
                        <MenuItem value="MUTUA UNIVERSAL, MUGENAT">MUTUA UNIVERSAL, MUGENAT</MenuItem>
                        <MenuItem value="MC MUTUAL">MC MUTUAL</MenuItem>
                        <MenuItem value="MUTUALIA">MUTUALIA</MenuItem>
                        <MenuItem value="SOLIMAT">SOLIMAT</MenuItem>
                        <MenuItem value="UMIVALE">UMIVALE</MenuItem>
                        <MenuItem value="UNION DE MUTUAS, UNIMAT">UNION DE MUTUAS, UNIMAT</MenuItem>
                      </Select>
                      <FormHelperText>
                        Indica la mútua que creas que más encaja con el usuario en función
                        de su situación geográfica, etc...
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      helperText="Puedes indicar cualquier nota adicional que creas importante que el usuario tenga que aceptar con su firma. Por ejemplo, locales comerciales e inmuebles (dirección y metros cuadrados), porcentaje de discapacidad, otras consideraciones...."
                      multiline
                      // error={!this.state.additionalNotes}
                      label="Notas adicionales"
                      rowsMax={20}
                      value={this.state.additionalNotes || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('additionalNotes', e); }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography variant="subtitle2">Dirección fiscal y social</Typography>
                    <Divider />
                    <FormHelperText>
                      Utiliza la dirección que indica en el DNI/NIE/Passaporte a menos que el usuario nos indique
                      que tenemos que utilizar otra.
                    </FormHelperText>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      error={!this.state.addressStreet}
                      label="Dirección (Calle,...)"
                      value={this.state.addressStreet || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('addressStreet', e); }}
                    />
                  </Grid>
                  <Grid item={true} sm={12}>
                    <TextField
                      error={!this.state.addressCity}
                      label="Población"
                      value={this.state.addressCity || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('addressCity', e); }}
                    />
                  </Grid>
                  <Grid item={true} sm={6}>
                    <TextField
                      error={!this.state.addressZipcode}
                      label="Código postal"
                      value={this.state.addressZipcode || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('addressZipcode', e); }}
                    />
                  </Grid>
                  <Grid item={true} sm={6}>
                    <TextField
                      error={!this.state.addressProvince}
                      label="Provincia"
                      value={this.state.addressProvince || ''}
                      fullWidth={true}
                      onChange={(e) => { this.changeField('addressProvince', e); }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>Cancelar</Button>
            <UpdatePaperworkMutation
              mutation={UPDATE_PAPERWORK_MUTATION}
              onError={(e) => {
                if (e.graphQLErrors[0]) {
                  this.processErrors(e.graphQLErrors[0]);
                } else {
                  onMutationError(e);
                }
              }}
            >
              {(mutationFn, { loading }) => (
                <Button
                  onClick={() => { this.onConfirm(mutationFn); }}
                  disabled={loading}
                >
                  Guardar
                </Button>
              )}
            </UpdatePaperworkMutation>
          </DialogActions>
        </Dialog >
      );
    } else {
      return null;
    }
  }
}

interface NewFreelanceInfoProps {
  company: QCompanyPaperworkInfo_company;
}

class NewFreelanceInfo extends React.PureComponent<NewFreelanceInfoProps & WithStyles<typeof styles>> {
  render = () => {
    const company = this.props.company;
    return (
      <React.Fragment>
        <Grid container={true} spacing={2} style={{ padding: 12 }}>
          <Grid item={true} xs={8}>
            <Grid container={true} spacing={2} style={{ padding: 12 }}>
              <Grid item={true} xs={12}>
                <Typography variant="subtitle2">Formularo registro nuevo autónomo</Typography>
                <Divider />
              </Grid>
              <Grid item={true} md={6}>
                <PaperworkField
                  mandatory
                  label="Fecha inicio actividad"
                  value={company.activityStartDate ? formatDate(company.activityStartDate) : ''}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField
                  label="He sido autónomo anteriormente"
                  value={company.freelanceLast3Years ? 'Sí' : 'No'}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField label="Actividad" value={company.activityName} />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField label="Notas de actividad" value={company.activityNotes} />
              </Grid>
              <Grid item={true} md={6}>
                <PaperworkField
                  mandatory
                  label="Núm. Seguridad Social"
                  value={company.socialSecurityNumber}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField
                  label="Tiene acreditada una discapacidad"
                  value={company.disability ? 'Sí' : 'No'}
                />
              </Grid>
              <Grid item={true} md={6}>
                <PaperworkField mandatory label="Cuenta bancaria" value={company.iban} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} md={4}>
            <Grid container={true} spacing={2} style={{ padding: 12 }}>
              <Grid item={true} xs={12}>
                <Typography variant="subtitle2">
                  A rellenar por el gestor
                                </Typography>
                <Divider />
              </Grid>
              <Grid item={true} md={12}>
                <PaperworkField mandatory label="Código IAE" value={company.activityIAE} multiline />
              </Grid>
              <Grid item={true} md={12}>
                <PaperworkField mandatory label="Código CNAE" value={company.activityCNAE} />
              </Grid>
              <Grid item={true} md={12}>
                <PaperworkField mandatory label="Mútua de autónomos" value={company.socialSecurityMutual} />
              </Grid>
              <Grid item={true} xs={12}>
                <PaperworkField label="Notas adicionales" value={company.additionalNotes} />
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="subtitle2">Dirección fiscal y social</Typography>
                <Divider />
              </Grid>
              <Grid item={true} xs={12}>
                <PaperworkField mandatory label="Dirección" value={company.addressStreet} />
              </Grid>
              <Grid item={true} xs={12}>
                <PaperworkField mandatory label="Población" value={company.addressCity} />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField mandatory label="Código postal" value={company.addressZipcode} />
              </Grid>
              <Grid item={true} xs={6}>
                <PaperworkField mandatory label="Provincia" value={company.addressProvince} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="subtitle2">Imágenes</Typography>
            <Divider />
          </Grid>
          <Grid item={true} md={4}>
            <PaperworkImage label="DNI/NIE Delantero" value={company.nifFrontUrl} />
            <UploadPaperwork company={company.id} type={UploadType.NIF_FRONT} />
          </Grid>
          <Grid item={true} md={4}>
            <PaperworkImage label="DNI/NIE Trasero" value={company.nifBackUrl} />
            <UploadPaperwork company={company.id} type={UploadType.NIF_BACK} />
          </Grid>
          <Grid item={true} md={4}>
            <PaperworkImage label="Firma" value={company.signatureUrl} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

interface PaperworkFieldProps {
  label: string;
  value?: string | null;
  mandatory?: boolean;
  multiline?: boolean;
}

class PaperworkField extends React.PureComponent<PaperworkFieldProps> {
  render = () => {

    return (
      <React.Fragment>
        <Typography variant="caption">{this.props.label}</Typography>
        <Typography
          variant="body1"
          style={{ whiteSpace: 'pre-line' }}
          color={(!this.props.value && this.props.mandatory) ? 'error' : 'inherit'}
        >
          {this.props.value ? this.props.value : (this.props.mandatory ? 'PENDIENTE' : '-')}
        </Typography>
      </React.Fragment>
    );
  }
}

interface PaperworkImageProps {
  label: string;
  value?: string | null;
}

class PaperworkImage extends React.PureComponent<PaperworkImageProps> {
  openImage = () => {
    var win = window.open('', '_blank');
    if (win != null) {
      win.document.body.innerHTML = '<html><head><title>' + this.props.label + '</title></head><body><img style="width: 100%;" src="' + this.props.value + '"/></body></html>';
    }
  }
  render = () => {

    let style = { maxWidth: '320px', border: '1px dashed' };

    return (
      <React.Fragment>
        <Typography variant="caption">{this.props.label}</Typography>
        <Typography
          variant="body1"
          color={this.props.value ? 'inherit' : 'error'}
        >
          {this.props.value ?
            <a href="#" onClick={() => { this.openImage(); return false; }}>
              <img src={this.props.value} style={style} />
            </a> : 'PENDIENTE'}
        </Typography>
      </React.Fragment>
    );
  }
}