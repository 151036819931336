import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withRouter, RouteComponentProps } from 'react-router';
import { QAllUsers, QAllUsersVariables } from '../gen/QAllUsers';
import { formatUserProfile } from '../lib/util';
import { UserProfile } from '../gen/globalTypes';
import { LiveTable } from '../widgets/livetable';

const QUERY = gql`
  query QAllUsers(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $emailLike: String
    $phoneLike: String
    $id: String
  ) {
    users: allUsers(
      pagination: { limit: $limit, offset: $offset }
      nameLike: $nameLike
      emailLike: $emailLike
      phoneLike: $phoneLike
      id: $id
    ) {
      totalCount
      items {
        id
        name
        profile
        email
        phone
      }
    }
  }
`;
const styles = (theme: Theme) =>
  createStyles({});

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> { }

class Users extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render = () => (
    <LiveTable
      history={this.props.history}
      rowToPathAndState={(row) => { return { pathname: `/user/${row.id}` }; }}
      title="Usuarios"
      fetchQuery={async (client, query) => {
        let variables: QAllUsersVariables = {
          limit: query.pageSize,
          offset: query.page * query.pageSize,
        };
        for (const filter of query.filters) {
          switch (filter.column.field) {
            case 'id': variables.id = filter.value; break;
            case 'name': variables.nameLike = '%' + filter.value.split(' ').join('%') + '%'; break;
            case 'email': variables.emailLike = '%' + filter.value.split(' ').join('%') + '%'; break;
            case 'phone': variables.phoneLike = '%' + filter.value.split(' ').join('%') + '%'; break;
            default:
          }
        }
        let { data } = await client.query<QAllUsers, QAllUsersVariables>(
          { query: QUERY, variables }
        );
        if (!data || !data.users) {
          return undefined;
        }
        return data.users;
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          filtering: true,
          sorting: false,
        },
        {
          field: 'profile',
          title: 'Perfil',
          filtering: false,
          // We need single select support in filter: https://github.com/mbrn/material-table/issues/492
          // lookup: {
          //   'Asesor': UserProfile.ADVISOR,
          //   'Transcriptor': UserProfile.TRANSCRIPTOR,
          //   'Usuario': UserProfile.USER,
          // },
          sorting: false,
          render: (rowData) => formatUserProfile(rowData.profile as UserProfile)
        },
        {
          field: 'name',
          title: 'Nombre',
          filtering: true,
          sorting: false,
        },
        {
          field: 'email',
          title: 'Correo electrónico',
          filtering: true,
          sorting: false,
        },
        {
          field: 'phone',
          title: 'Teléfono',
          filtering: true,
          sorting: false,
        },
      ]}
    />
  )
}

export default withStyles(styles)(withRouter(Users));