import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import CssBaseline from '@material-ui/core/CssBaseline';
import { client } from './client';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { SignInScreen } from './auth';
import { initErrorReporter } from './lib/errorReporter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
});

initErrorReporter();

interface AppState {
  userId: string | null;
}

class AppScreen extends React.Component<{}, AppState> {
  state = {
    userId: null,
  } as AppState;

  render = () => {
    return (
      <SignInScreen
        onUserLogged={(userId: string) => {
          this.setState({ userId });
        }}
      >
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Router forceRefresh={false}>
              <App userId={this.state.userId} />
            </Router>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </SignInScreen>
    );
  }
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AppScreen />
  </MuiThemeProvider>
  ,
  document.querySelector('#root')
);
