import * as React from 'react';
import { createStyles, WithStyles, withStyles, Button } from '@material-ui/core';
import { MutationFn, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { notificationsClient } from '../notifications';
import { onGenericError } from '../lib/errorReporter';
import { ApolloError } from 'apollo-client';
import { QUploadPaperwork, QUploadPaperworkVariables } from '../gen/QUploadPaperwork';
import { UploadType } from '../gen/globalTypes';

const UPLOAD_MUTATION = gql`
mutation QUploadPaperwork($company: ID! $file: Upload!, $type: UploadType!) {
  uploadPaperwork(company: $company, file: $file, type: $type) {
    id
  }
}
`;

class UploadMutation extends Mutation<QUploadPaperwork, QUploadPaperworkVariables> { }

interface UploadPaperworkEvidencesProps extends WithStyles<typeof styles> {
    company: string;
    type: UploadType;
}

interface UploadPaperworkEvidencesState {
    loading: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
        button: {
            marginRight: theme.spacing(2),
        }
    });

class UploadPaperworkInternal extends React.Component<UploadPaperworkEvidencesProps, UploadPaperworkEvidencesState> {
    private input?: HTMLInputElement;
    state = {
        loading: false
    } as UploadPaperworkEvidencesState;

    uploadFiles = async (
        uploadFn: MutationFn<QUploadPaperwork, QUploadPaperworkVariables>,
        company: string,
        type: UploadType,
        files: FileList
    ) => {
        const uploaded: File[] = [];
        this.setState({ loading: true });
        for await (const file of Array.from(files)) {
            await uploadFn({ variables: { company, file, type } })
                .then((_) => {
                    uploaded.push(file);
                })
                .catch((e: ApolloError) => {
                    onGenericError(new Error(e.message + ` (Archivo: ${file.name})`));
                });
        }
        if (uploaded.length === 1) {
            notificationsClient.addMessage('Archivo subido y procesado correctamente: ' + uploaded[0].name);
        } else if (uploaded.length > 1) {
            notificationsClient.addMessage('Se han subido y procesado ' + uploaded.length + ' archivos correctamente');
        }
        this.setState({ loading: false });
        if (this.input) {
            this.input.value = '';
        }
    }
    assignInputRef = (input: HTMLInputElement) => {
        this.input = input;
    }
    render = () => {
        return (
            <UploadMutation
                mutation={UPLOAD_MUTATION}
            // we do not use onError here to allow the upload handler to receive the error
            >
                {(uploadFile) => {
                    return (
                        <Button
                            variant="contained"
                            component="label"
                            disabled={this.state.loading}
                            className={this.props.classes.button}
                        >
                            {this.state.loading ? 'Subiendo' : 'Subir archivo'}
                            <input
                                multiple={true}
                                ref={this.assignInputRef}
                                type="file"
                                accept="application/pdf,image/*"
                                required={true}
                                disabled={this.state.loading}
                                style={{ display: 'none' }}
                                onChange={({ target: { validity, files } }) => {
                                    if (!files || files.length === 0 || !validity.valid) {
                                        return;
                                    }
                                    this.uploadFiles(
                                        uploadFile,
                                        this.props.company,
                                        this.props.type,
                                        files as FileList,
                                    );
                                }}
                            />

                        </Button>
                    );
                }}
            </UploadMutation>

        );
    }
}
export const UploadPaperwork = withStyles(styles)(UploadPaperworkInternal);