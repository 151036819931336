import * as React from 'react';
import { Typography, Link as MUILink, List, ListSubheader, ListItem } from '@material-ui/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loading } from '../../widgets/loading';
import { Journal } from './journal';
import { QDocInfoContext } from '../../gen/QDocInfoContext';
import { DocType } from '../../gen/globalTypes';
import { Link } from 'react-router-dom';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

class MyQuery extends Query<QDocInfoContext> { }

const QUERY = gql`
  query QDocInfoContext($company: ID!, $doc: ID!) {
    company(id: $company) {
      id,
      name,
      taxId,
      doc(id: $doc) {
        id
        deleted
        docType
        status
        isDigitalization
        tasks {
          message,
          idx
        }
      }
    },
  }
`;

/* FIXME
      docInfo(id: $doc ) {
        title,
        date,
        directives
      },
      tasks(query: $tasksSql ) {
        message
      }
*/

interface Props extends RouteComponentProps {
  doc: string;
  company: string;
}

interface State {
  account?: string;
  oid?: number;
}

class ContextViewerInternal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { account: undefined, oid: undefined };
  }
  accountClicked = (oid: number, account: string) => {
    this.setState({ account, oid });
  }
  docClicked = (doc: string) => {
    this.props.history.push(`/company/${this.props.company}/documents/${doc}`);
  }
  render = () => {
    return (
      <MyQuery
        query={QUERY}
        variables={{
          company: this.props.company,
          doc: this.props.doc
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (<Loading />);
          }
          if (error) {
            return (<div>error {JSON.stringify(error)}</div>);
          }
          if (!data || data.company == null) {
            return (<div>no data</div>);
          }
          if (data.company.doc == null) {
            return <div />;
          }
          const tasks =
            <div>
              {
                <List>
                  <ListSubheader>Tareas</ListSubheader>
                  {
                    data.company.doc.tasks.map((task, i) =>
                      <ListItem key={i}>{task.idx}: {task.message}</ListItem>
                    )
                  }
                </List>
              }
            </div>
            ;
          const isDeleted = data.company.doc.deleted;

          return (
            <div style={{ padding: '8px', flex: '1', overflow: 'auto' }}>
              <Typography variant="subtitle1">
                <MUILink color="inherit" component={Link} to={`/company/${data.company.id}`}>
                  {data.company.name} - {data.company.taxId}
                </MUILink>
              </Typography>
              <Typography variant="body1">
                {data.company.doc.docType === DocType.STANDALONE ? 'Documento maestro' : 'Documento'}
                {isDeleted ? ' (BORRADO)' : ''}
                <span> (Estado: {data.company.doc.status})</span>
                <span>
                  &nbsp;
                  (Tipo:
                  {data.company.doc.docType}
                  {data.company.doc.isDigitalization ? <VerifiedUserIcon /> : null}
                  )
                </span>
              </Typography>
              {
                // data.company.docInfo ? <React.Fragment>
                //   <Typography>Resumen: {data.company.docInfo.title}</Typography>
                //   <Typography>Fecha: {data.company.docInfo.date}</Typography>
                //   <Typography>Número de directivas: {data.company.docInfo.directives}</Typography>
                // </React.Fragment> : null
              }
              {
                data.company.doc.tasks.length !== 0 ? tasks : null
              }
              <Journal company={this.props.company} doc={this.props.doc} onAccountClicked={this.accountClicked} />
              {/* <Ledger
                account={this.state.account}
                oid={this.state.oid}
                company={this.props.company}
                onDocClicked={this.docClicked}
              /> */}
            </div>
          );
        }}
      </MyQuery>
    );
  }
}

export const ContextViewer = withRouter(ContextViewerInternal);