import * as React from 'react';
import { Grid, createStyles, WithStyles, withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withRouter, RouteComponentProps } from 'react-router';
import { QAllTasksQuery, QAllTasksQueryVariables } from '../gen/QAllTasksQuery';
import { LiveTable, TableQuery } from '../widgets/livetable';
import { QAllReviewQuery } from '../gen/QAllReviewQuery';
import { DocStatus, CompanyStatus, PaymentStatus, PaperworkStatus } from '../gen/globalTypes';
import { History } from 'history';
import { getWindowState, ACTIVE_ROW_BGCOLOR } from '../lib/util';
import TaskCount from '../widgets/taskCount';
import NewFreelanceCount from '../widgets/newFreelanceCount';

const FILTER_RUNNING_TASKS = {
  page: 0,
  pageSize: 500,
  companyStatus: [CompanyStatus.RUNNING],
  companyPaymentStatus: undefined,
  companyPaperworkStatus: undefined,
};

const DEFAULT_FILTER = FILTER_RUNNING_TASKS;

const QUERY_ALL_TASKS = gql`
query QAllTasksQuery(
  $limit: Int!
  $offset: Int!
  $companyStatus: CompanyStatus
  $companyPaymentStatus: PaymentStatus
  $companyPaperworkStatus: PaperworkStatus
) {
  allTasks(
    pagination: { limit: $limit, offset: $offset }
    companyStatus: $companyStatus
    companyPaymentStatus: $companyPaymentStatus
    companyPaperworkStatus: $companyPaperworkStatus
  ) {
    totalCount
    items {
      doc
      message
      company {
        id
        name
        status
        paymentStatus
        paperworkStatus
      }
    }
  }
}
`;

const QUERY_REVIEW = gql`
  query QAllReviewQuery($limit: Int!, $offset: Int!, $status: DocStatus!, $deleted: Boolean) {
    allDocs(pagination: {limit:$limit, offset: $offset}, status:$status, deleted: $deleted) {
      totalCount,
      items {
        id,
        company {
          id,
          name,
        },
        accounting,
        extractionMessage,
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    spaceBottom: {
      marginBottom: theme.spacing(2),
    }
  });

interface Props extends RouteComponentProps, WithStyles<typeof styles> { }

interface AllTasksState {
  activeCompanyTaskId?: string;
  filter?: Filter;
}

interface WindowState {
  state?: AllTasksState;
  filter?: Filter;
}

interface Filter {
  page: number;
  pageSize: number;
  companyStatus?: Array<CompanyStatus>;
  companyPaymentStatus?: Array<PaymentStatus>;
  companyPaperworkStatus?: Array<PaperworkStatus>;
}

function getInitialFilter(history: History): Filter {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.filter) {
    return windowState.filter;
  } else {
    return DEFAULT_FILTER;
  }
}

function getInitialState(history: History): AllTasksState {
  const windowState = getWindowState<WindowState>(history);
  if (windowState && windowState.state) {
    return windowState.state;
  } else {
    return {
    };
  }
}

function getCurrentWindowState(allTasks: AllTasks): WindowState {
  return {
    filter: allTasks.filter,
    state: allTasks.state,
  };
}

interface FilterAndVariables {
  filter: Filter;
  variables: QAllTasksQueryVariables;
}

function getFilterAndVariablesFromQuery(props: Props, query: TableQuery): FilterAndVariables {
  const variables: QAllTasksQueryVariables = {
    limit: query.pageSize,
    offset: query.page * query.pageSize,
  };
  const filter: Filter = {
    page: query.page,
    pageSize: query.pageSize,
  };
  for (const f of query.filters) {
    switch (f.column.field) {
      case 'companyStatus': {
        variables.companyStatus = f.value[0];
        filter.companyStatus = f.value;
        break;
      }
      case 'companyPaymentStatus': {
        variables.companyPaymentStatus = f.value[0];
        filter.companyPaymentStatus = f.value;
        break;
      }
      case 'companyPaperworkStatus': {
        variables.companyPaperworkStatus = f.value[0];
        filter.companyPaperworkStatus = f.value;
        break;
      }
      default:
    }
  }

  return { filter, variables };
}

class AllTasks extends React.Component<Props, AllTasksState> {
  constructor(props: Props) {
    super(props);
    this.state = getInitialState(this.props.history);
    this.filter = getInitialFilter(this.props.history);
  }
  filter: Filter;
  resetFilter = () => {
    this.filter = {
      page: 0,
      pageSize: 500,
    };
    this.setState({ filter: this.filter });
  }
  render = () => (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TaskCount
            title="Empresas activas"
            status={CompanyStatus.RUNNING}
            selected={JSON.stringify(this.filter) === JSON.stringify(FILTER_RUNNING_TASKS)}
            onClick={() => {
              if (JSON.stringify(this.filter) === JSON.stringify(FILTER_RUNNING_TASKS)) {
                this.resetFilter();
              } else {
                this.filter = FILTER_RUNNING_TASKS;
                this.setState({ filter: this.filter });
              }
            }}
          />
        </Grid>
        <Grid item md={8}>
          <NewFreelanceCount
            title="Trámite: Alta autónomos"
            onClick={() => {
              this.props.history.push({ pathname: '/company' });
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LiveTable
            key={JSON.stringify(this.state.filter || '')}
            history={this.props.history}
            title="Todas las tareas"
            initialPage={this.filter.page}
            options={{
              pageSize: this.filter.pageSize,
              rowStyle: (rowData) => {
                const css: React.CSSProperties = {};
                if (rowData.company && rowData.company.id === this.state.activeCompanyTaskId) {
                  css.backgroundColor = ACTIVE_ROW_BGCOLOR;
                }
                return css;
              }

            }}
            columns={[
              {
                title: 'Tarea',
                field: 'message',
                filtering: false,
                sorting: false,
                cellStyle: { maxWidth: '800px', overflowWrap: 'break-word' }
              },
              {
                title: 'Empresa',
                field: 'company',
                filtering: false,
                sorting: false,
                render: (rowData) => rowData.company.name
              },
              {
                title: 'Estado empresa',
                field: 'companyStatus',
                sorting: false,
                filtering: true,
                lookup: {
                  'ONBOARDING': CompanyStatus.ONBOARDING,
                  'RUNNING': CompanyStatus.RUNNING,
                  'STOPPED': CompanyStatus.STOPPED,
                },
                defaultFilter: this.filter.companyStatus,
                render: (rowData) => rowData.company.status
              },
              {
                title: 'Trámite',
                field: 'companyPaperworkStatus',
                sorting: false,
                filtering: true,
                lookup: {
                  'NONE': PaperworkStatus.NONE,
                  'NEW_FREELANCE_GATHERING_INFO': PaperworkStatus.NEW_FREELANCE_GATHERING_INFO,
                  'NEW_FREELANCE_GATHERING_INFO_COMPLETED': PaperworkStatus.NEW_FREELANCE_GATHERING_INFO_COMPLETED,
                  'NEW_FREELANCE_REVIEWING_PROPOSAL': PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL,
                  'NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED':
                    PaperworkStatus.NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED,
                  'NEW_FREELANCE_SUCCESS': PaperworkStatus.NEW_FREELANCE_SUCCESS,
                  'FREELANCE_START': PaperworkStatus.FREELANCE_START,
                },
                defaultFilter: this.filter.companyPaperworkStatus,
                render: (rowData) => rowData.company.paperworkStatus
              },
              {
                title: 'Estado pago',
                field: 'companyPaymentStatus',
                sorting: false,
                filtering: true,
                lookup: {
                  'NONE': PaymentStatus.NONE,
                  'SETUP': PaymentStatus.SETUP,
                  'ACTIVE': PaymentStatus.ACTIVE,
                  'CANCELED': PaymentStatus.CANCELED,
                  'FAILED': PaymentStatus.FAILED,
                  'UPDATED': PaymentStatus.UPDATED,
                },
                defaultFilter: this.filter.companyPaymentStatus,
                render: (rowData) => rowData.company.paymentStatus
              },
            ]}
            fetchQuery={async (client, query) => {
              const { filter, variables } = getFilterAndVariablesFromQuery(this.props, query);
              this.filter = filter;
              const { data } = await client.query<QAllTasksQuery, QAllTasksQueryVariables>({
                query: QUERY_ALL_TASKS,
                variables
              });
              if (!data || !data.allTasks) {
                return undefined;
              }
              return data.allTasks;
            }}
            rowToPathAndState={(row) => {
              const windowState = getCurrentWindowState(this);
              if (windowState.state) {
                windowState.state.activeCompanyTaskId = row.company.id;
              }
              return {
                pathname: row.doc ? `/company/${row.company.id}/documents/${row.doc}` : `/company/${row.company.id}`,
                state: windowState,
              };
            }}
          />
        </Grid>
      </Grid>

      <div className={this.props.classes.spaceBottom} />
      <LiveTable
        history={this.props.history}
        title="Pendientes de revisión"
        fetchQuery={async (client, query) => {
          const { data } = await client.query<QAllReviewQuery>({
            query: QUERY_REVIEW,
            variables: {
              status: DocStatus.REVIEW,
              deleted: false,
              limit: query.pageSize,
              offset: query.page * query.pageSize,
            }
          });
          if (!data || !data.allDocs) {
            return undefined;
          }
          return data.allDocs;
        }}
        columns={[
          {
            title: 'Empresa', field: 'company', filtering: false, sorting: false,
            render: (rowData) => rowData.company.name
          },
          {
            title: 'Mensaje', field: 'extractionMessage',
            filtering: false,
            sorting: false
          },
          {
            title: 'Contenido', field: 'accounting',
            render: (rowData) => <pre>{rowData.accounting}</pre>,
            filtering: false,
            sorting: false
          },
        ]}
        rowToPathAndState={(row) => {
          return { pathname: `/company/${row.company.id}/documents/${row.id}` };
        }}
      />
    </React.Fragment>
  )
}
export default withStyles(styles)(withRouter(AllTasks));