import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Card, CardContent,
  Grid, List, ListItem, Typography, Button
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps, Link } from 'react-router-dom';
import { QDashboard, QDashboardVariables, QDashboard_company_apps } from '../gen/QDashboard';
import { Query } from 'react-apollo';
import { Loading } from '../widgets/loading';
import MaterialTable from 'material-table';
import { tableIcons } from '../lib/tableIcons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Comment from '@material-ui/icons/Comment';
import { getIntercomUserUrl } from '../lib/intercom';

import ReactDiffViewer from 'react-diff-viewer';
import {
  sqlToRows, renderAmount, formatDateTime, formatDate,
  ACTIVE_ROW_BGCOLOR, stripeCustomerUrl
} from '../lib/util';
import dayjs = require('dayjs');
import clipboardCopy = require('clipboard-copy');
import { notificationsClient } from '../notifications';
import { QBoefile, QBoefileVariables } from '../gen/QBoefile';
import { saveAs } from 'file-saver';

const QUERY_DASHBOARD = gql`
query QDashboard($company: ID!) {
  company(id: $company) {
    id,
    name,
    taxId,
    status,
    paperworkStatus,
    trialUntil,
    paidUntil,
    enabledUntil,
    accountingLockDate,
    paymentCustomerId,
    paymentStatus,
    digitalizationEnabled,
    docsTotals {
      PENDING
      IRRELEVANT
      REVIEW
      UNCLEAR
      ACCOUNTED
    }
    users {
      id,
      name,
      phone,
      email
    }
    apps {
      id,
      title,
      app,
      ok,
      message,
      lastUpdate,
      externalLink
    },
    masterDoc: docs(type: STANDALONE) {
      items {
        id,
        accounting
      }
    }
    docs: docs(order: MODIFICATION_DATE, orderDirection: DESC, deleted: false) {
      items {
        id,
        docType,
        status,
        modificationDate,
        deleted,
        title,
      }
    }
    toReview: docs(status: REVIEW, order: MODIFICATION_DATE, orderDirection: DESC, deleted: false) {
      items {
        id,
        accounting,
        extractionMessage
      }
    }
    pending: docs(status: PENDING, order: MODIFICATION_DATE, orderDirection: DESC, deleted:false) {
      items {
        id,
        title
      }
    }
    tasks {
      totalCount,
      items {
        doc,
        idx,
        message,
      }
    },
    journal: sql(sql:"select doc, doc_title, line_idx, date, amount, report_amount, currency, options from journal order by date desc limit 50"),
    taxes: sql(sql:"select date, status, amount, reference, notes, send_doc, content, sent_content from taxes"),
    ivaExpedidas: sql(sql:"select * from iva_expedidas order by fecha_emision desc limit 50"),
    ivaRecibidas: sql(sql:"select * from iva_recibidas order by fecha_emision desc limit 50"),
  }
}
`;

const QUERY_BOEFILE = gql`
query QBoefile($company: ID!, $taxid: String!) {
  company(id: $company) {
    id,
    taxId,
    boefile(taxid: $taxid),
  }
}
`;

const styles = (theme: Theme) =>
  createStyles({
    spaceBottom: {
      marginBottom: theme.spacing(2),
    },
    label: {
      paddingRight: theme.spacing(0.5),
    }
  });

interface TaskParams {
  company: string;
}
interface Props extends RouteComponentProps<TaskParams>, WithStyles<typeof styles> { }

class QueryDasboard extends Query<QDashboard, QDashboardVariables> { }

function renderAppTitle(app: QDashboard_company_apps): JSX.Element {
  if (app.externalLink) {
    return (
      <a target="_blank" href={app.externalLink}>{app.title}</a>
    );
  } else {
    return (
      <span>{app.title}</span>
    );
  }
}

class Tasks extends React.Component<Props> {
  render = () => {
    const company = this.props.match.params.company;
    const classes = this.props.classes;
    return (
      <QueryDasboard query={QUERY_DASHBOARD} variables={{ company }}>
        {({ data, client }) => {
          if (!data || !data.company) {
            return <Loading />;
          }
          const journal = sqlToRows(data.company.journal);
          const taxes = sqlToRows(data.company.taxes);
          const ivaExpedidas = sqlToRows(data.company.ivaExpedidas);
          const ivaRecibidas = sqlToRows(data.company.ivaRecibidas);
          return <div>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6">Ficha</Typography>
                    <List>
                      <ListItem>{data.company.name} - {data.company.taxId}</ListItem>
                      <ListItem><b className={classes.label}>Estado:</b> {data.company.status}</ListItem>
                      <ListItem><b className={classes.label}>Trámite:</b> {data.company.paperworkStatus}</ListItem>
                      <ListItem>
                        <b className={classes.label}>TrialUntil:</b> {data.company.trialUntil}&nbsp;
                        <b className={classes.label}>PaidUntil: </b> {data.company.paidUntil}&nbsp;
                        <b className={classes.label}>EnabledUntil:</b> {data.company.enabledUntil}
                      </ListItem>
                      <ListItem>
                        <b className={classes.label}>Bloqueo contable: </b> {data.company.accountingLockDate}
                      </ListItem>
                      <ListItem>
                        <b className={classes.label}>Pago:</b> {data.company.paymentStatus}
                        {data.company.paymentCustomerId ? (
                          <React.Fragment>
                            &nbsp;-&nbsp;
                            <a
                              target="_blank"
                              href={stripeCustomerUrl(data.company.paymentCustomerId)}
                            >
                              {data.company.paymentCustomerId}
                            </a>
                          </React.Fragment>
                        ) : null}
                      </ListItem>
                      <ListItem>
                        <b className={classes.label}>Digitalización habilitada:</b>
                        {data.company.digitalizationEnabled ? 'Sí' : 'No'}
                      </ListItem>
                      <ListItem>
                        <b className={classes.label}>PENDING:</b> {data.company.docsTotals.PENDING}&nbsp;
                        <b className={classes.label}>REVIEW:</b> {data.company.docsTotals.REVIEW}&nbsp;
                        <b className={classes.label}>IRRELEVANT:</b> {data.company.docsTotals.IRRELEVANT}&nbsp;
                        <b className={classes.label}>UNCLEAR:</b> {data.company.docsTotals.UNCLEAR}&nbsp;
                        <b className={classes.label}>ACCOUNTED:</b> {data.company.docsTotals.ACCOUNTED}&nbsp;
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6}>
                <MaterialTable
                  icons={tableIcons}
                  columns={[
                    { title: 'Nombre', field: 'name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Teléfono', field: 'phone' },
                  ]}
                  data={data.company.users}
                  onRowClick={(event, rowData) => {
                    this.props.history.push({ pathname: `/user/${rowData.id}` });
                  }}
                  title="Usuarios"
                  options={{
                    paging: false,
                    search: false,
                    sorting: false,
                    filtering: false,
                    padding: 'dense'
                  }}
                  actions={[
                    {
                      icon: () => (<Comment />),
                      tooltip: 'Usuario en Intercom',
                      onClick: (event, rowData) => {
                        const w = window.open(getIntercomUserUrl(rowData.id), '_blank');
                        if (w) {
                          w.focus();
                        }
                      }
                    }
                  ]}
                />
                <div className={classes.spaceBottom} />
                <MaterialTable
                  icons={tableIcons}
                  columns={[
                    {
                      title: 'App',
                      field: 'title',
                      render: (rowData) => {
                        return (
                          <span>
                            {rowData.app}
                            {' - '}
                            {renderAppTitle(rowData)}
                          </span>
                        );
                      }
                    },
                    { title: 'Ok', field: 'ok', type: 'boolean' },
                    { title: 'Mensaje', field: 'message' },
                    { title: 'Actualizado', field: 'lastUpdate', render: (row) => formatDateTime(row) },
                  ]}
                  data={data.company.apps}
                  title="Apps"
                  options={{
                    paging: false,
                    search: false,
                    sorting: false,
                    filtering: false,
                    padding: 'dense'
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Tareas"
              columns={[
                {
                  title: 'Tarea', field: 'message', filtering: false, sorting: false,
                  cellStyle: { maxWidth: '1000px', overflowWrap: 'break-word' }

                },
              ]}
              data={data.company.tasks.items}
              onRowClick={(event, rowData) => {
                if (rowData.doc) {
                  this.props.history.push({ pathname: `/company/${company}/documents/${rowData.doc}` });
                }
              }}
              options={{
                paging: false,
                search: false,
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Pendientes de extracción"
              columns={[
                { title: 'Título', field: 'title', render: (row) => row.title || '<Sin título>' },
              ]}
              data={data.company.pending.items}
              onRowClick={(event, rowData) => {
                this.props.history.push({ pathname: `/company/extraction/${rowData.id}` });
              }}
              options={{
                paging: false,
                search: false,
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />

            <MaterialTable
              icons={tableIcons}
              title="Pendientes de revisión"
              columns={[
                { title: 'Mensaje', field: 'extractionMessage', render: (row) => row.extractionMessage || '<Sin mensaje>' },
                { title: 'Contenido', field: 'accounting', render: (row) => <pre>{row.accounting}</pre> },
              ]}
              data={data.company.toReview.items}
              onRowClick={(event, rowData) => {
                this.props.history.push({ pathname: `/company/${company}/documents/${rowData.id}` });
              }}
              options={{
                paging: false,
                search: false,
                padding: 'dense'
              }}
            />

            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Impuestos"
              columns={[
                { title: 'Reference', field: 'reference' },
                { title: 'Estado', field: 'status' },
                {
                  title: 'Fecha', field: 'date', headerStyle: { width: '19ch' },
                  render: (row) => formatDate(row.date)
                },
                { title: 'Cantidad', field: 'amount', type: 'numeric', render: (row) => renderAmount(row.amount) },
                { title: 'Notas', field: 'notes' },
              ]}
              actions={[
                rowData => ({
                  icon: () => <VisibilityIcon />,
                  tooltip: 'Ver presentación',
                  disabled: rowData.send_doc == null,
                  onClick: (event) => {
                    event.stopPropagation();
                    if (rowData.send_doc) {
                      this.props.history.push({ pathname: `/company/${company}/documents/${rowData.send_doc}` });
                    }
                  }
                })
              ]}
              data={taxes}
              detailPanel={
                (rowData) => {
                  const content = JSON.parse(rowData.content);
                  const sentContent = JSON.parse(rowData.sent_content) || {};
                  const button = rowData.content && rowData.status === 'SENT_ERROR' ?
                    (
                      <Button
                        variant="contained"
                        style={{ marginRight: '16px' }}
                        onClick={async () => {
                          const date = dayjs(rowData.date).set('date', 1).format('YYYY-MM-DD');
                          const override = `${date} >EVENT\n  tipo:override\n  id:${rowData.reference}\n  content:${JSON.stringify(rowData.content)}\n`;
                          await clipboardCopy(override);
                          notificationsClient.addMessage('Copiado al portapapeles');
                        }}
                      >
                        Copiar evento override al portapapeles
                      </Button>
                    )
                    : null;
                  return <React.Fragment>
                    <ReactDiffViewer
                      oldValue={JSON.stringify(content, null, 2)}
                      newValue={JSON.stringify(sentContent, null, 2)}
                      disableWordDiff
                      extraLinesSurroundingDiff={0}
                      showDiffOnly
                      splitView
                    />
                    <div style={{ margin: '16px' }}>
                      {button}
                      <Button
                        variant="contained"
                        style={{ marginRight: '16px' }}
                        onClick={async () => {
                          const x = await client.query<QBoefile, QBoefileVariables>({
                            query: QUERY_BOEFILE,
                            variables: { taxid: rowData.reference, company }
                          });
                          if (x.data && x.data.company && x.data.company.boefile) {
                            const b = Buffer.from(x.data.company.boefile, 'latin1');
                            const blob = new Blob([b], { type: 'text/plain;charset=ascii' });
                            saveAs(blob, `${x.data.company.taxId}-${rowData.reference}.boe`);
                          }
                        }}
                      >
                        Descargar fichero BOE
                      </Button>
                    </div>
                  </React.Fragment>;
                }
              }
              options={{
                paging: false,
                search: false,
                filtering: false,
                sorting: false,
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Últimos documentos"
              columns={[
                { title: 'Título', field: 'title' },
                { title: 'Modificación', render: (rowData) => formatDateTime(rowData.modificationDate) },
                { title: 'Tipo', field: 'docType' },
                { title: 'Estado', field: 'status' },
                { title: 'Borrado', field: 'deleted', type: 'boolean' },
              ]}
              data={data.company.docs.items}
              onRowClick={(event, rowData) => {
                this.props.history.push({ pathname: `/company/${company}/documents/${rowData.id}` });
              }}
              options={{
                paging: false,
                search: false,
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Últimos apuntes"
              columns={[
                {
                  title:
                    'I',
                  field: 'line_idx',
                },
                {
                  title:
                    'Fecha',
                  field: 'date',
                  type: 'date',
                  headerStyle: { width: '19ch' },
                  render: (row) => formatDate(row.date)
                },
                { title: 'Documento', field: 'doc_title' },
                {
                  title: 'Importe',
                  field: 'amount',
                  type: 'numeric',
                  render: (row) => renderAmount(row.amount)
                },
                {
                  title: 'Moneda',
                  field: 'currency',
                },
                {
                  title: 'Euros',
                  field: 'report_amount',
                  type: 'numeric',
                  render: (row) => renderAmount(row.report_amount)
                },
                { title: 'Opciones', field: 'options' }
              ]}
              data={journal}
              onRowClick={(event, rowData) => {
                if (rowData.doc) {
                  this.props.history.push({ pathname: `/company/${company}/documents/${rowData.doc}` });
                }
              }}
              options={{
                paging: false,
                search: false,
                filtering: false,
                sorting: false,
                rowStyle: (rowData) => {
                  const result: React.CSSProperties = {};
                  if (rowData.line_idx === 0) {
                    result.backgroundColor = ACTIVE_ROW_BGCOLOR;
                  }
                  return result;
                },
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Últimas expedidas"
              columns={[
                { title: 'Periodo', render: (row) => `${row.year}-${row.periodo}` },
                {
                  title: 'Fecha Fac.', field: 'fecha_emision', headerStyle: { width: '19ch' },
                  render: (row) => formatDate(row.fecha_emision)
                },
                { title: 'Numero', render: (row) => `${row.serie || ''}/${row.num}` },
                { title: 'Receptor', render: (row) => `${row.contraparte_id} ${row.contraparte_nombre}` },
                { title: 'País', field: 'contraparte_pais' },
                { title: 'Base', type: 'numeric', render: (row) => renderAmount(row.base_imponible) },
                { title: '%', field: 'tipo_impositivo', type: 'numeric' },
                { title: 'Cuota', type: 'numeric', render: (row) => renderAmount(row.cuota_repercutida) },
                { title: 'Tipo', field: 'tipo_factura' },
                { title: 'Regimen', field: 'regimen' },
                { title: 'Sujeta', field: 'sujeta' },
                { title: 'Desglose', field: 'desglose' },
              ]}
              data={ivaExpedidas}
              onRowClick={(event, rowData) => {
                this.props.history.push({ pathname: `/company/${company}/documents/${rowData.doc}` });
              }}
              options={{
                paging: false,
                search: false,
                filtering: false,
                sorting: false,
                padding: 'dense'
              }}
            />
            <div className={classes.spaceBottom} />
            <MaterialTable
              icons={tableIcons}
              title="Últimas recibidas"
              columns={[
                { title: 'Periodo', render: (row) => `${row.year}-${row.periodo}` },
                {
                  title: 'Fecha Fac.', field: 'fecha_emision', headerStyle: { width: '19ch' },
                  render: (row) => formatDate(row.fecha_emision)
                },
                {
                  title: 'Fecha Reg.', field: 'fecha_registro_contable', headerStyle: { width: '19ch' },
                  render: (row) => formatDate(row.fecha_registro_contable)
                },
                { title: 'Numero', field: 'numyserie' },
                { title: 'Emisor', render: (row) => `${row.emisor_id} ${row.emisor_nombre}` },
                { title: 'País', field: 'emisor_pais' },
                { title: 'Base', type: 'numeric', render: (row) => renderAmount(row.base_imponible) },
                { title: '%', field: 'tipo_impositivo', type: 'numeric' },
                { title: 'Cuota sop.', type: 'numeric', render: (row) => renderAmount(row.cuota_soportada) },
                { title: 'Cuota ded.', type: 'numeric', render: (row) => renderAmount(row.cuota_deducible) },
                { title: 'Tipo', field: 'tipo_factura' },
                { title: 'Regimen', field: 'regimen' },
                { title: 'Importe', type: 'numeric', render: (row) => renderAmount(row.importe_total) },
              ]}
              data={ivaRecibidas}
              options={{
                paging: false,
                search: false,
                filtering: false,
                sorting: false,
                padding: 'dense'
              }}
              onRowClick={(event, rowData) => {
                this.props.history.push({ pathname: `/company/${company}/documents/${rowData.doc}` });
              }}
            />
            <div className={classes.spaceBottom} />
            <Card>
              <CardContent>
                <Link to={`/company/${company}/documents/${data.company.masterDoc.items[0].id}`}>
                  <Typography variant="h6">
                    Documento maestro
                </Typography>
                </Link>
                <pre>{data.company.masterDoc.items[0].accounting}</pre>
              </CardContent>
            </Card>

          </div>;
        }}
      </QueryDasboard >
    );
  }
}
export default withStyles(styles)(Tasks);