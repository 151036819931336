import * as React from 'react';
import {
  createStyles, WithStyles, withStyles,
  Paper, Typography, Card, Button, Toolbar, Chip, DialogTitle, Dialog,
  DialogContent, DialogActions, Grid, TextField, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio,
  Switch, Select, MenuItem, InputLabel
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { QCompanyApps, QCompanyAppsVariables, QCompanyApps_company_apps } from '../gen/QCompanyApps';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { Loading } from '../widgets/loading';
import { AppType, PaymentStatus, CompanyStatus, DigitalizationDatabaseTaxType, DigitalizationDatabaseDocsType } from '../gen/globalTypes';
import {
  formatDateTime, stripeSubscriptionUrl, stripeCustomerUrl, getPreviousQuarter, getDateRangeForFiscalPeriod,
  FiscalPeriod
} from '../lib/util';
import { Confirm, Alert } from '../lib/utilBrowser';
import { MProgramExpenseSync, MProgramExpenseSyncVariables } from '../gen/MProgramExpenseSync';
import { onMutationError } from '../lib/errorReporter';
import { notificationsClient } from '../notifications';
import {
  QCompanyPaymentInfo, QCompanyPaymentInfoVariables,
  QCompanyPaymentInfo_company,
  QCompanyPaymentInfo_company_paymentInfo_fiscalData
} from '../gen/QCompanyPaymentInfo';
import { MUpdateCompany, MUpdateCompanyVariables } from '../gen/MUpdateCompany';
import { resetStore } from '../client';
import { MSetupPaymentFiscalData, MSetupPaymentFiscalDataVariables } from '../gen/MSetupPaymentFiscalData';
import * as Stripe from 'stripe';
import { MSetupSubscription, MSetupSubscriptionVariables } from '../gen/MSetupSubscription';
import { MSyncAEAT, MSyncAEATVariables } from '../gen/MSyncAEAT';
import { MObtenerCertificado, MObtenerCertificadoVariables } from '../gen/MObtenerCertificado';
import { MResetPayment, MResetPaymentVariables } from '../gen/MResetPayment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import {
  MGenerateDigitalizationDatabase,
  MGenerateDigitalizationDatabaseVariables
} from '../gen/MGenerateDigitalizationDatabase';

const COMPANY_APPS_QUERY = gql`
query QCompanyApps($company: ID!) {
  company(id: $company) {
    id
    name
    apps {
      id
      title
      app
      ok
      message
      lastUpdate
    }
  }
}
`;

const COMPANY_PAYMENT_INFO_QUERY = gql`
query QCompanyPaymentInfo($company:ID!) {
  company(id: $company) {
    id
    name
    taxId
    status
    trialUntil
    paidUntil
    enabledUntil
    paperworkStatus
    signatureHash
    signatureUrl
    accountingLockDate
    paymentStatus
    paymentInfo {
      id
      fiscalData {
        name
        surname
        taxId
        address
        city
        postalCode
      }
    }
    rawSubscription
    digitalizationEnabled
  }
}
`;

class CompanyAppsQuery extends Query<QCompanyApps, QCompanyAppsVariables> { }
class CompanyPaymentInfoQuery extends Query<QCompanyPaymentInfo, QCompanyPaymentInfoVariables> { }

const styles = (theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  appsTitle: {
    marginBottom: theme.spacing(2),
  },
  app: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  appError: {
    padding: theme.spacing(2),
    backgroundColor: '#ff0000',
    color: '#ffffff',
    width: 'auto'
  },
  toolbar: {
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
});

function getAppTitle(app: QCompanyApps_company_apps): string {
  switch (app.app) {
    case AppType.GOOGLE_DRIVE:
      return 'Google Drive';
      break;
    case AppType.FD1: {
      return 'FacturaDirecta 1';
    }
    case AppType.FD2: {
      return 'FacturaDirecta 2';
    }
    default: {
      return app.app;
    }
  }
}

const SYNC_AEAT_MUTATION = gql`
  mutation MSyncAEAT(
    $company: ID!
  ) {
    syncAEAT(
      company: $company
    )
  }
`;
class SyncAEATMutation extends Mutation<MSyncAEAT, MSyncAEATVariables> { }

const OBTENER_CERTIFICADO_MUTATION = gql`
  mutation MObtenerCertificado(
    $company: ID!,
    $surname: String!
  ) {
    obtenerCertificado(
      company: $company
      surname: $surname
    )
  }
`;
class ObtenerCertificadoMutation extends Mutation<MObtenerCertificado, MObtenerCertificadoVariables> { }

const GENERATE_DIGITALIZATION_DATABASE_MUTATION = gql`
  mutation MGenerateDigitalizationDatabase(
    $company: ID!
    $startDate: Date!
    $endDate: Date!
    $taxType: DigitalizationDatabaseTaxType!
    $docsType: DigitalizationDatabaseDocsType!
  ) {
    generateDigitalizationDatabase(
      company: $company
      startDate: $startDate
      endDate: $endDate
      taxType: $taxType
      docsType: $docsType
    )
    {
      id
      contentHash
      contentUrl
      startDate
      endDate
      taxType
      docsType
      totalDocuments
    }
  }
`;
class GenerateDigitalizationDatabaseMutation extends
  Mutation<MGenerateDigitalizationDatabase, MGenerateDigitalizationDatabaseVariables> { }

interface Props extends
  RouteComponentProps<{
    company: string;
  }>,
  WithStyles<typeof styles> { }

interface SettingsState {
  programExpenseDialogOpened: boolean;
  sepaDialogOpened: boolean;
  fnmtDialogOpened: boolean;
  digitalizationDialogOpened: boolean;
}

class Settings extends React.Component<Props, SettingsState> {
  state = {
    programExpenseDialogOpened: false,
    sepaDialogOpened: false,
    fnmtDialogOpened: false,
    digitalizationDialogOpened: false
  } as SettingsState;
  onProgramExpenseToggle = () => {
    this.setState({ programExpenseDialogOpened: !this.state.programExpenseDialogOpened });
  }
  onSepaToggle = () => {
    this.setState({ sepaDialogOpened: !this.state.sepaDialogOpened });
  }
  onFnmtToggle = () => {
    this.setState({ fnmtDialogOpened: !this.state.fnmtDialogOpened });
  }
  onDigitalizationDatabaseToggle = () => {
    this.setState({ digitalizationDialogOpened: !this.state.digitalizationDialogOpened });
  }
  getCompanyAppsQueryVariables(): QCompanyAppsVariables {
    return {
      company: this.props.match.params.company,
    };
  }
  getCompanyPaymentInfoQueryVariables(): QCompanyPaymentInfoVariables {
    return {
      company: this.props.match.params.company,
    };
  }
  render = () => {
    const classes = this.props.classes;
    return (
      <Paper className={classes.paper}>
        <CompanyPaymentInfoQuery
          query={COMPANY_PAYMENT_INFO_QUERY}
          variables={this.getCompanyPaymentInfoQueryVariables()}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return (
                <pre style={{ whiteSpace: 'pre-line' }}>
                  {error.message}
                </pre>
              );
            }
            if (!data) {
              return <div />;
            }
            if (!data.company) {
              return <div />;
            }

            return (
              <React.Fragment>
                <div className={classes.section}>
                  <Typography variant="h6" className={classes.appsTitle}>Estado de la empresa</Typography>
                  <Card style={{ padding: 12 }}>
                    <CompanyInfo
                      classes={classes}
                      company={data.company}
                    />
                  </Card>
                </div>
                <div className={classes.section}>
                  <Typography variant="h6" className={classes.appsTitle}>Estado de la suscripción</Typography>
                  <Card style={{ padding: 12 }}>
                    <CompanyPaymentInfo
                      classes={classes}
                      company={data.company}
                    />
                  </Card>
                </div>
              </React.Fragment>
            );
          }}
        </CompanyPaymentInfoQuery>
        <CompanyAppsQuery
          query={COMPANY_APPS_QUERY}
          variables={this.getCompanyAppsQueryVariables()}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return (
                <pre style={{ whiteSpace: 'pre-line' }}>
                  {error.message}
                </pre>
              );
            }
            if (!data) {
              return <div />;
            }
            if (!data.company) {
              return <div />;
            }
            const company = data.company.id;

            return (
              <div className={classes.section}>
                <Typography variant="h6" className={classes.appsTitle}>Aplicaciones</Typography>
                {
                  data.company.apps.map((app: QCompanyApps_company_apps, i: number) => {
                    return (
                      <Card key={i} className={classes.app}>
                        <Typography variant="subtitle1">
                          {getAppTitle(app)} - {app.title}
                        </Typography>
                        <Typography>
                          Última actualización:
                          {' '}
                          {formatDateTime(app.lastUpdate)}
                          {' - '}
                          {app.message}
                        </Typography>
                        {app.ok ? null : <Chip className={classes.appError} label="ERROR" />}
                        {
                          ((app.app === AppType.FD1 || app.app === AppType.FD2) ? (
                            <Toolbar className={classes.toolbar}>
                              <Button
                                variant="contained"
                                onClick={this.onProgramExpenseToggle}
                              >
                                Cargar adjuntos de gastos
                              </Button>
                              {
                                this.state.programExpenseDialogOpened ?
                                  <ProgramExpenseLoad
                                    company={company}
                                    app={app}
                                    onClose={this.onProgramExpenseToggle}
                                  /> : null
                              }
                            </Toolbar>
                          ) : null)
                        }
                      </Card>
                    );
                  })
                }
              </div>
            );
          }}
        </CompanyAppsQuery>
        <div className={classes.section}>
          <Typography variant="h6" className={classes.appsTitle}>Agencia Tributaria</Typography>
          <SyncAEATMutation
            mutation={SYNC_AEAT_MUTATION}
            variables={{ company: this.props.match.params.company }}
          >
            {(mutationFn, { loading }) => (
              <Button
                variant="contained"
                disabled={loading}
                onClick={async () => {
                  mutationFn().then((res) => {
                    if (res && res.data) {
                      notificationsClient.addMessage(res.data.syncAEAT);
                      window.alert(res.data.syncAEAT);
                    } else {
                      window.alert('No se ha podido sincronizar.');
                    }
                  }).catch((err) => {
                    window.alert('No se ha podido sincronizar.\n' + JSON.stringify(err, null, 4));
                  });
                }}
              >
                Sincronizar Modelos
                {loading ? <Loading /> : null}
              </Button>
            )}
          </SyncAEATMutation>
          <Button variant="contained" style={{ marginLeft: '8px' }} onClick={this.onFnmtToggle}>
            Obtener certificado FNMT
          </Button>
          {
            this.state.fnmtDialogOpened ?
              <FNMTDialog
                onClose={this.onFnmtToggle}
                company={this.props.match.params.company}
              /> : null
          }
          <Button variant="contained" style={{ marginLeft: '8px' }} onClick={this.onDigitalizationDatabaseToggle}>
            Generar base de datos de digitalizaciones
          </Button>
          {
            this.state.digitalizationDialogOpened ?
              <CreateDigitalizationDatabaseDialog
                onClose={this.onDigitalizationDatabaseToggle}
                company={this.props.match.params.company}
              /> : null
          }
        </div>
      </Paper>
    );
  }
}
export default withStyles(styles)(Settings);

function getGenerateDigitalizationDatabaseVariables(args: {
  company: string,
  dateRangeVisible: boolean,
  startDate: string,
  endDate: string,
  year: number,
  periodo: string,
  taxType: DigitalizationDatabaseTaxType,
  docsType: DigitalizationDatabaseDocsType
}): MGenerateDigitalizationDatabaseVariables {
  if (args.dateRangeVisible) {
    return {
      company: args.company,
      taxType: args.taxType,
      docsType: args.docsType,
      startDate: args.startDate,
      endDate: args.endDate,
    };
  } else {
    return {
      company: args.company,
      taxType: args.taxType,
      docsType: args.docsType,
      ...getDateRangeForFiscalPeriod(args.year, args.periodo as FiscalPeriod)
    };
  }
}

const CreateDigitalizationDatabaseDialog = (props: { onClose: () => void, company: string }) => {
  const previousQuarter = getPreviousQuarter(dayjs());
  const [dateRangeVisible, setDateRangeVisible] = React.useState(false);
  const [startDate, setStartDate] = React.useState(previousQuarter.startDate.format('YYYY-MM-DD'));
  const [endDate, setEndDate] = React.useState(previousQuarter.endDate.format('YYYY-MM-DD'));
  const [year, setYear] = React.useState(previousQuarter.startDate.get('year'));
  const [periodo, setPeriodo] = React.useState('' + (1 + Math.floor(previousQuarter.startDate.get('month') / 3)) + 'T');
  const [taxType, setTaxType] = React.useState(DigitalizationDatabaseTaxType.IVA);
  const [docsType, setDocsType] = React.useState(DigitalizationDatabaseDocsType.EXPENSE);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={props.onClose}
      maxWidth="md"
    >
      <DialogTitle>Crear base de datos de digitalizaciones</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={4}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Periodo fiscal/Fechas</FormLabel>
              <RadioGroup
                value={dateRangeVisible ? 'true' : 'false'}
                onChange={(e, value) => { setDateRangeVisible(value === 'true'); }}
              >
                <FormControlLabel value={'false'} control={<Radio />} label="Periodo fiscal" />
                <FormControlLabel value={'true'} control={<Radio />} label="Fechas" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={4}>
            {dateRangeVisible ? (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label="Fecha de inicio"
                value={startDate}
                autoOk={true}
                onChange={(d) => {
                  if (d && d.isValid()) {
                    setStartDate(d.format('YYYY-MM-DD'));
                  }
                }}
              />
            ) : (
                <TextField
                  label="Ejercicio"
                  type="number"
                  value={year}
                  onChange={(e) => setYear(Number.parseInt(e.target.value, 10))}
                />
              )}
          </Grid>
          <Grid item={true} xs={4}>
            {dateRangeVisible ? (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label="Fecha de fin"
                value={endDate}
                autoOk={true}
                onChange={(d) => {
                  if (d && d.isValid()) {
                    setEndDate(d.format('YYYY-MM-DD'));
                  }
                }}
              />
            ) : (
                <FormControl>
                  <InputLabel>Periodo</InputLabel>
                  <Select
                    value={periodo}
                    onChange={(e) => { setPeriodo(e.target.value as string); }}
                  >
                    <MenuItem value="1T">1T</MenuItem>
                    <MenuItem value="2T">2T</MenuItem>
                    <MenuItem value="3T">3T</MenuItem>
                    <MenuItem value="4T">4T</MenuItem>
                  </Select>
                </FormControl>
              )}
          </Grid>
          <Grid item={true} xs={4}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Tipo de impuesto</FormLabel>
              <RadioGroup
                value={taxType}
                onChange={(e, value) => { setTaxType(value as DigitalizationDatabaseTaxType); }}
              >
                <FormControlLabel value={DigitalizationDatabaseTaxType.IVA} control={<Radio />} label="IVA" />
                <FormControlLabel value={DigitalizationDatabaseTaxType.IRPF} control={<Radio />} label="IRPF" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={4}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Tipo de documentos</FormLabel>
              <RadioGroup
                value={docsType}
                onChange={(e, value) => { setDocsType(value as DigitalizationDatabaseDocsType); }}
              >
                <FormControlLabel value={DigitalizationDatabaseDocsType.EXPENSE} control={<Radio />} label="Gastos" />
                <FormControlLabel value={DigitalizationDatabaseDocsType.INCOME} control={<Radio />} label="Ingresos" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancelar
        </Button>
        <GenerateDigitalizationDatabaseMutation
          mutation={GENERATE_DIGITALIZATION_DATABASE_MUTATION}
          variables={getGenerateDigitalizationDatabaseVariables({
            company: props.company,
            taxType,
            docsType,
            dateRangeVisible,
            startDate,
            endDate,
            year,
            periodo
          })}
          onError={(e) => onMutationError(e)}
        >
          {(mutationFn, { loading }) => (
            <Button
              disabled={loading}
              onClick={async () => {
                const res = await mutationFn();
                if (res && res.data) {
                  const message = `Base de datos generada 
                    entre las fechas ${res.data.generateDigitalizationDatabase.startDate} 
                    y ${res.data.generateDigitalizationDatabase.endDate} 
                    para impuesto ${res.data.generateDigitalizationDatabase.taxType}
                    y documentos ${res.data.generateDigitalizationDatabase.docsType}
                    incluyendo ${res.data.generateDigitalizationDatabase.totalDocuments} documentos
                  `;
                  const body =
                    (<div>
                      {message}
                      <br /><br />
                      <Button
                        variant="contained"
                        color="primary"
                        href={res.data.generateDigitalizationDatabase.contentUrl}
                      >
                        Descargar
                      </Button>
                    </div>);
                  await Alert({
                    title: 'Base de datos generada',
                    body,
                    ok: 'Continuar',
                  });
                }
              }}
            >
              Generar base de datos
              {loading ? <Loading /> : null}
            </Button>
          )}
        </GenerateDigitalizationDatabaseMutation>

      </DialogActions>
    </Dialog >
  );
};

const FNMTDialog = (props: { onClose: () => void, company: string }) => {
  const [surname, setSurname] = React.useState('');
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      fullWidth={true}
    >
      <DialogTitle>Solicitar certificado digital FNMT</DialogTitle>
      <DialogContent>
        <TextField
          label="Primer apellido (sin acentos)"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cerrar
          </Button>
        <ObtenerCertificadoMutation
          mutation={OBTENER_CERTIFICADO_MUTATION}
          variables={{ company: props.company, surname: surname }}
        >
          {(mutationFn, { loading }) => (
            <Button
              disabled={loading || !surname}
              onClick={async () => {
                const res = await mutationFn();
                if (res && res.data) {
                  notificationsClient.addMessage(res.data.obtenerCertificado);
                  // tslint:disable-next-line
                  console.log(res.data.obtenerCertificado);
                  window.alert(res.data.obtenerCertificado);
                  props.onClose();
                }
              }}
            >
              Obtener certificado FNMT
                {loading ? <Loading /> : null}
            </Button>
          )}
        </ObtenerCertificadoMutation>
      </DialogActions>
    </Dialog>);
};

const PROGRAM_EXPENSE_SYNC_MUTATION = gql`
        mutation MProgramExpenseSync(
          $company: ID!
          $expenseSyncJobConfig: ExpenseSyncJobConfig!
  ) {
        addAppExpenseSyncJob(
          company: $company
      expenseSyncJobConfig: $expenseSyncJobConfig
    )
  }
`;
class ProgramExpenseSyncMutation extends Mutation<MProgramExpenseSync, MProgramExpenseSyncVariables> { }

interface ProgramExpenseLoadProps {
  company: string;
  app: QCompanyApps_company_apps;
  onClose: () => void;
}
interface ProgramExpenseState {
  fromDate?: string;
  toDate?: string;
}
class ProgramExpenseLoad extends React.Component<ProgramExpenseLoadProps, ProgramExpenseState> {
  state = {
    fromDate: undefined,
    toDate: undefined,
  } as ProgramExpenseState;
  changeDate = (key: keyof ProgramExpenseState, value: string | undefined) => {
    const state = {};
    state[key] = value;
    this.setState(state);
  }
  onConfirm = async (mutationFn: MutationFn<MProgramExpenseSync, MProgramExpenseSyncVariables>) => {
    const result = await mutationFn({
      variables: {
        company: this.props.company,
        expenseSyncJobConfig: {
          app: this.props.app.id,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        }
      }
    });
    if (result) {
      notificationsClient.addMessage('Programada sincronización de gastos');
      this.props.onClose();
    }
  }
  render = () => {
    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
      >
        <DialogTitle>Programar nueva subida de gastos desde {getAppTitle(this.props.app)}</DialogTitle>
        <DialogContent>
          <Typography>
            Indica la fecha mínima y máxima para la sincronización
            </Typography>
          <Grid container={true} spacing={8}>
            <Grid item={true} xs={6}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                fullWidth
                label="Fecha de inicio"
                value={this.state.fromDate || null}
                autoOk={true}
                onChange={(d) => {
                  if (d && d.isValid()) {
                    this.changeDate('fromDate', d.format('YYYY-MM-DD'));
                  } else {
                    this.changeDate('fromDate', undefined);
                  }
                }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                fullWidth
                label="Fecha de fin"
                value={this.state.toDate || null}
                autoOk={true}
                onChange={(d) => {
                  if (d && d.isValid()) {
                    this.changeDate('toDate', d.format('YYYY-MM-DD'));
                  } else {
                    this.changeDate('toDate', undefined);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancelar</Button>
          <ProgramExpenseSyncMutation
            mutation={PROGRAM_EXPENSE_SYNC_MUTATION}
            onError={(e) => onMutationError(e)}
          >
            {(mutationFn, { loading }) => (
              <Button
                onClick={() => { this.onConfirm(mutationFn); }}
              >
                Programar
              </Button>
            )}
          </ProgramExpenseSyncMutation>
        </DialogActions>
      </Dialog >
    );
  }
}

const SETUP_SUBSCRIPTION_MUTATION = gql`
mutation MSetupSubscription($company: ID!) {
  setupSubscription(
    company: $company
  ) {
    id
    rawSubscription
  }
}
`;
class SetupSubscriptionMutation extends Mutation<MSetupSubscription, MSetupSubscriptionVariables> { }

interface CompanyInfoState {
  statusDialogOpened: boolean;
}
interface CompanyInfoProps {
  company: QCompanyPaymentInfo_company;
}

class CompanyInfo extends React.PureComponent<CompanyInfoProps & WithStyles<typeof styles>, CompanyInfoState> {
  state = {
    statusDialogOpened: false,
  } as CompanyInfoState;
  onStatusToggle = () => {
    this.setState({ statusDialogOpened: !this.state.statusDialogOpened });
  }
  render = () => {
    const company = this.props.company;
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={3}>
            <TextField
              label="Estado"
              value={company.status || ''}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              label="Bloqueo contable"
              value={company.accountingLockDate}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              label="Digitalización habilitada"
              value={company.digitalizationEnabled ? 'Sí' : 'No'}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Toolbar className={classes.toolbar}>
            <Button
              variant="contained"
              onClick={this.onStatusToggle}
              className={classes.button}
            >
              Actualizar estado
            </Button>
          </Toolbar>
        </Grid>
        {
          this.state.statusDialogOpened ?
            <StatusDialog
              company={company}
              onClose={this.onStatusToggle}
            /> : null
        }
      </React.Fragment>
    );
  }
}

interface CompanyPaymentInfoState {
  statusDialogOpened: boolean;
  fiscalDataDialogOpened: boolean;
  startSubscriptionDialogOpened: boolean;
}
interface CompanyPaymentInfoProps {
  company: QCompanyPaymentInfo_company;
}

class CompanyPaymentInfo extends React.PureComponent<CompanyPaymentInfoProps & WithStyles<typeof styles>,
  CompanyPaymentInfoState> {
  state = {
    statusDialogOpened: false,
    fiscalDataDialogOpened: false,
    startSubscriptionDialogOpened: false,
  } as CompanyPaymentInfoState;
  onStatusToggle = () => {
    this.setState({ statusDialogOpened: !this.state.statusDialogOpened });
  }
  onFiscalDataToggle = () => {
    this.setState({ fiscalDataDialogOpened: !this.state.fiscalDataDialogOpened });
  }
  onResetPayment = async (mutationFn: MutationFn<MResetPayment, MResetPaymentVariables>) => {
    const previousPaymentStatus = this.props.company.paymentStatus;
    const result = await mutationFn({ variables: { company: this.props.company.id } });
    if (result && result.data && result.data.resetPayment.paymentStatus !== previousPaymentStatus) {
      notificationsClient.addMessage('Método de pago cancelado correctamente');
      resetStore();
    } else {
      notificationsClient.addMessage('Sin cambios');
    }
  }
  onSetupSubscription = async (mutationFn: MutationFn<MSetupSubscription, MSetupSubscriptionVariables>) => {
    await mutationFn({ variables: { company: this.props.company.id } });
    notificationsClient.addMessage('Suscripción configurada');
    resetStore();
  }
  render = () => {
    const company = this.props.company;
    const classes = this.props.classes;
    const subscription: Stripe.subscriptions.ISubscription | null = company.rawSubscription;
    const showFiscalData = company.paymentInfo != null && company.paymentInfo.id != null;
    const canResetPaymentSource = [PaymentStatus.SETUP, PaymentStatus.UPDATED].includes(company.paymentStatus);
    return (
      <React.Fragment>
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={3}>
            <TextField
              label="Estado del pago"
              value={company.paymentStatus || ''}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              label="Fin del trial"
              type="text"
              value={company.trialUntil || ''}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              label="Fin de la suscripción"
              type="text"
              value={company.paidUntil || ''}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              label="Bloqueo después de"
              type="text"
              value={company.enabledUntil || ''}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
        </Grid>
        {
          company.paymentInfo ? (
            <Grid container={true} spacing={8}>
              <Grid item={true} xs={12}>
                <Typography variant="h5">Datos fiscales</Typography>
              </Grid>
              {
                showFiscalData ? (
                  <Grid item={true} xs={12}>
                    <CompanyFiscalData classes={classes} fiscalData={company.paymentInfo.fiscalData} />
                  </Grid>
                ) : null
              }
            </Grid>
          ) : null
        }
        <Grid item={true} xs={12}>
          <Toolbar className={classes.toolbar}>
            <Button
              variant="contained"
              onClick={this.onStatusToggle}
              className={classes.button}
            >
              Actualizar estado
            </Button>
            {
              canResetPaymentSource ? (
                <ResetPaymentMutation
                  mutation={RESET_PAYMENT_MUTATION}
                  onError={(e) => onMutationError(e)}
                >
                  {(mutationFn, { loading }) => (
                    <Button
                      variant="contained"
                      disabled={loading}
                      onClick={async () => {
                        const requestCertConfirmed = await Confirm({
                          body: 'Se modificará el estado de la empresa para ignorar la forma de pago introducida y el usuario tendrá que introducir un nuevo método de pago para poder configurar la suscripcón'
                        });
                        if (requestCertConfirmed) {
                          this.onResetPayment(mutationFn);
                        }
                      }}
                      className={classes.button}
                    >
                      Cancelar forma de pago
                    </Button>
                  )}
                </ResetPaymentMutation>
              ) : null
            }
            {
              showFiscalData ? (
                <Button
                  variant="contained"
                  onClick={this.onFiscalDataToggle}
                  className={classes.button}
                >
                  Actualizar datos fiscales
                </Button>
              ) : null
            }
            {
              company.paymentInfo ? (
                <Button
                  variant="contained"
                  target="_blank"
                  href={stripeCustomerUrl(company.paymentInfo.id)}
                  className={classes.button}
                >
                  Ver cliente en Stripe
                </Button>
              ) : null
            }
            {
              subscription != null ? (
                <Button
                  variant="contained"
                  target="_blank"
                  href={stripeSubscriptionUrl(subscription.id)}
                  className={classes.button}
                >
                  Ver suscripción en Stripe
                </Button>
              ) : null
            }
            {
              subscription == null && company.paymentInfo && company.paymentInfo.fiscalData &&
                [PaymentStatus.SETUP, PaymentStatus.UPDATED].includes(company.paymentStatus) ? (
                  <SetupSubscriptionMutation
                    mutation={SETUP_SUBSCRIPTION_MUTATION}
                    onError={(e) => onMutationError(e)}
                  >
                    {(mutationFn, { loading }) => (
                      <Button
                        variant="contained"
                        onClick={async () => {
                          const confirmed = await Confirm({
                            body: 'Se iniciará la suscripción del usuario con fecha de primer pago el día 1 del próximo mes'
                          });
                          if (confirmed) {
                            this.onSetupSubscription(mutationFn);
                          }
                        }}
                        disabled={loading}
                        className={classes.button}
                      >
                        Iniciar suscripción
                      </Button>
                    )}
                  </SetupSubscriptionMutation>
                ) : null
            }
          </Toolbar>
        </Grid>
        {
          this.state.statusDialogOpened ?
            <PaymentStatusDialog
              company={company}
              onClose={this.onStatusToggle}
            /> : null
        }
        {
          this.state.fiscalDataDialogOpened ?
            <FiscalDataDialog
              classes={classes}
              company={company}
              onClose={this.onFiscalDataToggle}
            /> : null
        }
      </React.Fragment>
    );
  }
}

const RESET_PAYMENT_MUTATION = gql`
  mutation MResetPayment($company: ID!) {
    resetPayment(company: $company) {
      id
      name
      paymentStatus
    }
  }
`;
class ResetPaymentMutation extends Mutation<MResetPayment, MResetPaymentVariables> { }

interface CompanyFiscalDataProps {
  fiscalData: QCompanyPaymentInfo_company_paymentInfo_fiscalData | null;
  editable?: boolean;
  updateField?: (key: keyof QCompanyPaymentInfo_company_paymentInfo_fiscalData, value: string) => void;
}

function getEmptyFiscalData(): QCompanyPaymentInfo_company_paymentInfo_fiscalData {
  return {
    name: '',
    surname: '',
    taxId: '',
    address: '',
    city: '',
    postalCode: ''
  } as QCompanyPaymentInfo_company_paymentInfo_fiscalData;
}

class CompanyFiscalData extends React.Component<CompanyFiscalDataProps & WithStyles<typeof styles>> {
  setField = (
    key: keyof QCompanyPaymentInfo_company_paymentInfo_fiscalData,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    if (this.props.updateField) {
      this.props.updateField(key, e.target.value);
    }
  }
  render = () => {
    const fiscalData = this.props.fiscalData || getEmptyFiscalData();
    const editable = this.props.editable === true;
    return (
      <Grid container={true} spacing={8}>
        <Grid item={true} xs={3}>
          <TextField
            label="Nombre"
            value={fiscalData.name || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('name', e); }}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <TextField
            label="Apellidos"
            value={fiscalData.surname || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('surname', e); }}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <TextField
            label="NIF"
            value={fiscalData.taxId || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('taxId', e); }}
          />
        </Grid>
        <Grid item={true} xs={3} />
        <Grid item={true} xs={6}>
          <TextField
            label="Dirección"
            value={fiscalData.address || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('address', e); }}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <TextField
            label="Código postal"
            value={fiscalData.postalCode || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('postalCode', e); }}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <TextField
            label="Municipio"
            value={fiscalData.city || ''}
            fullWidth={true}
            disabled={!editable}
            onChange={(e) => { this.setField('city', e); }}
          />
        </Grid>
      </Grid>
    );
  }
}

const UPDATE_COMPANY_MUTATION = gql`
mutation MUpdateCompany(
  $company: ID!
  $status: CompanyStatus
  $trialUntil: Date
  $paidUntil: Date
  $enabledUntil: Date
  $accountingLockDate: Date
  $digitalizationEnabled: Boolean
) {
  updateCompany(
    company: $company
    status: $status
    trialUntil: $trialUntil
    paidUntil: $paidUntil
    enabledUntil: $enabledUntil
    accountingLockDate: $accountingLockDate
    digitalizationEnabled: $digitalizationEnabled
  ) {
    id
  }
}

`;
class UpdateCompanyMutation extends Mutation<MUpdateCompany, MUpdateCompanyVariables> { }

interface StatusDialogProps {
  company: QCompanyPaymentInfo_company;
  onClose: () => void;
}
interface StatusDialogState {
  status: CompanyStatus;
  accountingLockDate: string;
  digitalizationEnabled: boolean;
}

class StatusDialog extends React.Component<StatusDialogProps, StatusDialogState> {
  state = {
    status: this.props.company.status,
    accountingLockDate: this.props.company.accountingLockDate,
    digitalizationEnabled: this.props.company.digitalizationEnabled,
  } as StatusDialogState;
  onConfirm = async (mutationFn: MutationFn<MUpdateCompany, MUpdateCompanyVariables>) => {
    const variables: MUpdateCompanyVariables = {
      company: this.props.company.id,
      status: this.state.status,
      accountingLockDate: this.state.accountingLockDate,
      digitalizationEnabled: this.state.digitalizationEnabled,
    };
    if (this.state.status !== this.props.company.status ||
      this.state.accountingLockDate !== this.props.company.accountingLockDate ||
      this.state.digitalizationEnabled !== this.props.company.digitalizationEnabled) {
      const result = await mutationFn({ variables });
      if (result) {
        notificationsClient.addMessage('Estado actualizado');
        resetStore();
      }
    }
    this.props.onClose();
  }
  render = () => {

    return (
      <Dialog
        maxWidth="lg"
        open={true}
        onClose={this.props.onClose}
      >
        <DialogTitle>Cambiar estado de la empresa</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12}>
              <Typography>
                Indica el nuevo estado para la empresa
                </Typography>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Estado</FormLabel>
                <RadioGroup
                  value={this.state.status}
                  onChange={(e, value) => { this.setState({ status: value as CompanyStatus }); }}
                >
                  <FormControlLabel value={CompanyStatus.ONBOARDING} control={<Radio />} label="ONBOARDING" />
                  <FormControlLabel value={CompanyStatus.RUNNING} control={<Radio />} label="RUNNING" />
                  <FormControlLabel value={CompanyStatus.STOPPED} control={<Radio />} label="STOPPED" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                fullWidth
                label="Fecha de bloqueo de contabilidad"
                value={this.state.accountingLockDate}
                autoOk={true}
                onChange={(d) => {
                  if (d) {
                    this.setState({ accountingLockDate: d.format('YYYY-MM-DD') });
                  }
                }}
                helperText="El usuario no podrá borrar ni restaurar documentos anteriores a esta fecha. Las transcripciones siempre se crearán en fechas iguales o posteriores a esta fecha. Consejo: Usa solo el día 1 del inicio del trimestre actual."
              />
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl>
                <FormLabel component="legend">Digitalización habilitada</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.digitalizationEnabled}
                      onChange={(e) => {
                        this.setState({ digitalizationEnabled: e.target.checked });
                      }}
                    />}
                  label={this.state.digitalizationEnabled ? 'Sí' : 'No'}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancelar</Button>
          <UpdateCompanyMutation
            mutation={UPDATE_COMPANY_MUTATION}
            onError={(e) => onMutationError(e)}
          >
            {(mutationFn, { loading }) => (
              <Button
                onClick={() => { this.onConfirm(mutationFn); }}
                disabled={loading}
              >
                Guardar
              </Button>
            )}
          </UpdateCompanyMutation>
        </DialogActions>
      </Dialog >
    );
  }
}

interface PaymentStatusDialogProps {
  company: QCompanyPaymentInfo_company;
  onClose: () => void;
}
interface PaymentStatusDialogState {
  trialUntil?: string;
  enabledUntil?: string;
}
class PaymentStatusDialog extends React.Component<PaymentStatusDialogProps, PaymentStatusDialogState> {
  state = {
    trialUntil: this.props.company.trialUntil,
    enabledUntil: this.props.company.enabledUntil,
  } as PaymentStatusDialogState;
  onConfirm = async (mutationFn: MutationFn<MUpdateCompany, MUpdateCompanyVariables>) => {
    const variables: MUpdateCompanyVariables = {
      company: this.props.company.id
    };
    if (this.props.company.paymentStatus === PaymentStatus.NONE) {
      // Cuando no se ha configurado y se actualiza el trial se cambian todas las fechas, es correcto
      variables.trialUntil = this.state.trialUntil;
      variables.paidUntil = this.state.trialUntil;
      variables.enabledUntil = this.state.trialUntil;
    } else {
      // En otros estados solo tiene sentido actualizar enabledUntil
      variables.enabledUntil = this.state.enabledUntil;
    }
    await mutationFn({ variables });
    notificationsClient.addMessage('Estado de suscripción actualizado');
    resetStore();
    this.props.onClose();
  }
  render = () => {
    const company = this.props.company;

    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
      >
        <DialogTitle>Cambiar estado de suscripción la empresa</DialogTitle>
        <DialogContent>
          {
            company.paymentStatus === PaymentStatus.NONE ? (
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                  <Typography>
                    Indica la nueva fecha de fin de trial
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="YYYY-MM-DD"
                    margin="normal"
                    fullWidth
                    label="Fin de trial"
                    value={this.state.trialUntil}
                    autoOk={true}
                    onChange={(d) => {
                      if (d && d.isValid()) {
                        this.setState({ trialUntil: d.format('YYYY-MM-DD') });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            ) : null
          }
          {
            company.paymentStatus !== PaymentStatus.NONE ? (
              <Grid container={true} spacing={8}>
                <Grid item={true} xs={12}>
                  <Typography>
                    Indica la nueva fecha de bloqueo
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="YYYY-MM-DD"
                    margin="normal"
                    fullWidth
                    label="Bloqueado después de"
                    value={this.state.enabledUntil}
                    autoOk={true}
                    onChange={(d) => {
                      if (d && d.isValid()) {
                        this.setState({ enabledUntil: d.format('YYYY-MM-DD') });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            ) : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancelar</Button>
          <UpdateCompanyMutation
            mutation={UPDATE_COMPANY_MUTATION}
            onError={(e) => onMutationError(e)}
          >
            {(mutationFn, { loading }) => (
              <Button
                onClick={() => { this.onConfirm(mutationFn); }}
                disabled={loading}
              >
                Guardar
              </Button>
            )}
          </UpdateCompanyMutation>
        </DialogActions>
      </Dialog >
    );
  }
}

const SETUP_PAYMENT_FISCAL_DATA_MUTATION = gql`
mutation MSetupPaymentFiscalData(
  $company: ID!
  $name: String!
  $surname: String
  $taxId: String!
  $address: String!
  $postalCode: String!
  $city: String!
) {
  setupPaymentFiscalData(
    company: $company
    name: $name
    surname: $surname
    taxId: $taxId
    address: $address
    postalCode: $postalCode
    city: $city
  )
}
`;
class SetupPaymentFiscalDataMutation extends Mutation<MSetupPaymentFiscalData, MSetupPaymentFiscalDataVariables> { }

interface FiscalDataDialogState {
  fiscalData: QCompanyPaymentInfo_company_paymentInfo_fiscalData;
}
interface FiscalDataDialogProps {
  company: QCompanyPaymentInfo_company;
  onClose: () => void;
}
class FiscalDataDialog extends React.Component<FiscalDataDialogProps & WithStyles<typeof styles>,
  FiscalDataDialogState> {
  state = {
    fiscalData: this.props.company.paymentInfo && this.props.company.paymentInfo.fiscalData ?
      this.props.company.paymentInfo.fiscalData : {}
  } as FiscalDataDialogState;
  updateField = (key: keyof QCompanyPaymentInfo_company_paymentInfo_fiscalData, value: string) => {
    this.state.fiscalData[key] = value as any;
    this.setState({ fiscalData: this.state.fiscalData });
  }
  onConfirm = async (mutationFn: MutationFn<MSetupPaymentFiscalData, MSetupPaymentFiscalDataVariables>) => {
    const variables: MSetupPaymentFiscalDataVariables = {
      company: this.props.company.id,
      name: this.state.fiscalData.name,
      surname: this.state.fiscalData.surname,
      taxId: this.state.fiscalData.taxId,
      address: this.state.fiscalData.address,
      postalCode: this.state.fiscalData.postalCode,
      city: this.state.fiscalData.city,
    };
    await mutationFn({ variables });
    notificationsClient.addMessage('Datos fiscales actualizados');
    resetStore();
    this.props.onClose();
  }
  render = () => {
    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
        maxWidth="lg"
      >
        <DialogTitle>Datos fiscales</DialogTitle>
        <DialogContent>
          <CompanyFiscalData
            classes={this.props.classes}
            fiscalData={this.state.fiscalData}
            editable
            updateField={this.updateField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancelar</Button>
          <SetupPaymentFiscalDataMutation
            mutation={SETUP_PAYMENT_FISCAL_DATA_MUTATION}
            onError={(e) => onMutationError(e)}
          >
            {(mutationFn, { loading }) => (
              <Button
                onClick={() => { this.onConfirm(mutationFn); }}
                disabled={loading}
              >
                Guardar
              </Button>
            )}
          </SetupPaymentFiscalDataMutation>
        </DialogActions>
      </Dialog >
    );
  }
}
