import 'monaco-editor/esm/vs/editor/editor.all';
import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/quickOutline.js';
import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/gotoLine.js';
import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/quickCommand.js';

import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import 'monaco-editor/esm/vs/basic-languages/python/python.contribution.js';

(self as any).MonacoEnvironment = {
  getWorker: function () {
    return new Worker('../../node_modules/monaco-editor/esm/vs/editor/editor.worker.js');
  }
};

// register Monaco languages
monaco.languages.register({
  id: 'acc',
  extensions: ['.acc'],
  mimetypes: ['application/acc'],
});

monaco.languages.setLanguageConfiguration('acc', {
  comments: {
    lineComment: '#'
  }
});

monaco.editor.defineTheme('accTheme', {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: 'date', foreground: '569cd6', fontStyle: 'bold' },
    { token: 'currency', foreground: 'ccccff' },
    { token: 'number', fontStyle: 'bold' },
    { token: 'number.positive', foreground: '11FF11' },
    { token: 'number.negative', foreground: 'FF1111' } // will inherit fontStyle from `comment` above
  ]
} as monaco.editor.IStandaloneThemeData);

// Register a tokens provider for the language
monaco.languages.setMonarchTokensProvider('acc', {
  defaultToken: 'invalid',
  ignoreCase: true,
  keywords: [
    'invoice', 'bill', 'receipt', 'bank', 'contact', 'pgc', 'tax',
    'base', 'rate', 'bcbisv', 'expense', 'income', 'open',
    'when', 'then', 'id',
    '>ER', '>RULE', '>EVENT'
  ],
  currency: [
    'EUR', 'USD', 'COP'
  ],
  operators: [
    '=', ':',
  ],

  // we include these common regular expressions
  symbols: /[=:]+/,
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // The main tokenizer for our languages
  tokenizer: {
    root: [
      // identifiers and keywords
      [/[a-zA-Z][\w-]*/, {
        cases: {
          '@keywords': 'keyword', // TODO push state on options
          '@currency': 'currency',
          '@default': 'identifier'
        }
      }],

      // whitespace
      { include: '@whitespace' },

      // date
      [/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'date'],

      // numbers
      [/\+?\d+\.?\d*/, 'number.positive'],
      [/-\d+\.?\d*/, 'number.negative'],

      // delimiters and operators
      [/[=:]+/, 'delimiter', 'option'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'],  // non-teminated string
      [/"/, 'string', '@string'],

    ],
    option: [
      [/[0-9A-Za-z-_@\./]+/, 'string', '@pop'],
      [/"([^"\\]|\\.)*$/, 'string.invalid', '@pop'],  // non-teminated string
      [/"/, 'string', '@string'],
    ],
    whitespace: [
      [/[ \t\r\n]+/, ''],
      [/#.*$/, 'comment'],
    ],

    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, 'string', '@popall']
    ],
  },
} as monaco.languages.IMonarchLanguage);

import './monaco.remote';

export default monaco;