import * as React from 'react';
import classNames from 'classnames';
import {
  createStyles, WithStyles, withStyles, Grid, Card,
  CardContent, Button, CardActions, Fab
} from '@material-ui/core';
import { Query } from 'react-apollo';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MUILink from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReplayIcon from '@material-ui/icons/Replay';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Route, RouteComponentProps, Switch, Link } from 'react-router-dom';
import { advisorRoutes, transcriptorRoutes } from './routes';
import { Sidebar } from './sidebar';
import { Notifications } from './notifications';
import ErrorReporter from './lib/errorReporter';
import { getUserProfile } from './lib/utilBrowser';
import { Loading } from './widgets/loading';
import { QLoggedUser_viewer } from './gen/QLoggedUser';
import { UserProfile } from './gen/globalTypes';
import { logout } from './auth';
import { QCompanyTitleVariables, QCompanyTitle } from './gen/QCompanyTitle';
import gql from 'graphql-tag';
import { resetStore } from './client';
import { getIntercomCompanyUrl } from './lib/intercom';
import Comment from '@material-ui/icons/Comment';

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    refreshToolbar: {
      position: 'absolute',
      bottom: '16px',
      right: '16px',
      zIndex: 2000,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: '96px',
      height: '100vh',
      overflow: 'auto',
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  });

interface AppProps {
  userId: string | null;
}

interface AppState {
  open: boolean;
  loadingUser: boolean;
  userData: QLoggedUser_viewer | null;
  needsRefresh: boolean;
}

function getUserRoutes(profile: UserProfile | null) {
  if (profile === UserProfile.ADVISOR) {
    return advisorRoutes;
  } else if (profile === UserProfile.TRANSCRIPTOR) {
    return transcriptorRoutes;
  } else {
    return [];
  }
}

class App extends React.Component<AppProps & WithStyles<typeof styles>, AppState> {

  state = {
    open: true,
    loadingUser: true,
    userData: null,
    needsRefresh: false,
  } as AppState;

  componentDidMount = async () => {
    if (this.props.userId) {
      const userData = await getUserProfile(this.props.userId);
      this.setState({ loadingUser: false, userData });
    } else {
      this.setState({ loadingUser: false });
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  serverChange = async () => {
    if (!this.state.needsRefresh) {
      const profile = this.state.userData ? this.state.userData.profile : null;
      if (profile === UserProfile.ADVISOR) {
        // For advisor only show refresh available floating button
        this.setState({ needsRefresh: true });
      } else {
        resetStore(); // For other profiles call resetStore automatically
      }
    }
  }

  refreshApp = () => {
    resetStore();
    this.setState({ needsRefresh: false });
  };

  render() {
    const { classes } = this.props;
    if (this.state.loadingUser) {
      return (<Loading />);
    }

    const profile = this.state.userData ? this.state.userData.profile : null;
    if (!profile) {
      return (
        <Grid
          container
          style={{ flexGrow: 1, height: '100vh' }}
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent style={{ alignContent: 'center' }}>
                <Typography gutterBottom align="center">Acceso denegado</Typography>
                {/* Helper to know userId to create new users */}
                <div style={{ display: 'none' }}>{this.props.userId}</div>
              </CardContent>
              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={logout}
                >
                  Salir
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid >
      );
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <Switch>
                  <Route path="/company/:id" component={Title} />
                  <Route children={() => (<span>Abaq</span>)} />
                </Switch>
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Sidebar profile={profile} />
          </Drawer>
          <main className={classes.content}>
            {
              getUserRoutes(profile).map((route, index) => (
                <Route
                  style={{ minHeight: '100%' }}
                  key={profile + '_' + index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))
            }
          </main>
        </div>
        {this.state.needsRefresh ? (
          <div className={classes.refreshToolbar}>
            <Fab size="small" color="secondary" onClick={this.refreshApp}>
              <ReplayIcon />
            </Fab>
          </div>
        ) : null}
        <Notifications onServerChange={this.serverChange} />
        <ErrorReporter />
      </React.Fragment>
    );
  }
}

const QUERY = gql`
  query QCompanyTitle($company: ID!) {
    company(id: $company) {
      id
      name,
      taxId,
      docs(type: STANDALONE) {
        items {
          id
        }
      }
    }
  }
`;

class QueryCompanyTitle extends Query<QCompanyTitle, QCompanyTitleVariables> { }

const Title = ({ match }: RouteComponentProps<{ id: string }>) =>
  (
    <QueryCompanyTitle query={QUERY} variables={{ company: match.params.id }}>
      {({ data }) => {
        if (!data || !data.company) {
          return <span>Abaq</span>;
        }
        const name = `${data.company.name || 'Empresa'} - ${data.company.taxId}`;
        if (data.company.docs.items.length > 0) {
          return (
            <span>
              <MUILink
                component={Link}
                color="inherit"
                to={`/company/${match.params.id}/documents/${data.company.docs.items[0].id}`}
              >
                {name}
              </MUILink>
              <Tooltip title="Empresa en Intercom">
                <IconButton
                  disabled={data.company == null || data.company.taxId == null}
                  color="inherit"
                  onClick={(event) => {
                    if (data.company != null && data.company.taxId != null) {
                      const w = window.open(getIntercomCompanyUrl(data.company.taxId), '_blank');
                      if (w) {
                        w.focus();
                      }
                    }
                  }}
                >
                  <Comment />
                </IconButton>
              </Tooltip>
            </span>
          );
        } else {
          return <span>{name}</span>;
        }
      }}
    </QueryCompanyTitle>
  );

export default withStyles(styles)(App);