import gql from 'graphql-tag';
import * as React from 'react';
import { ButtonBase, Card, Typography, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { QCompanyCount, QCompanyCountVariables } from '../gen/QCompanyCount';
import { Query } from 'react-apollo';
import { CompanyStatus, PaperworkStatus, PaymentStatus } from '../gen/globalTypes';
import { Loading } from '../widgets/loading';

const styles = (theme: Theme) =>
    createStyles({
        cardAction: {
            display: 'block',
            textAlign: 'initial',
            width: '100%',
            padding: '12px',
            minWidth: '250px',
        }
    });

const COMPANY_COUNT_QUERY = gql`
query QCompanyCount(
  $status: CompanyStatus
  $paperworkStatus: PaperworkStatus
  $paymentStatus: PaymentStatus
) {
  companies(
    status: $status
    paperworkStatus: $paperworkStatus
    paymentStatus: $paymentStatus
  ) {
    totalCount
  }
}
`;

class CompanyCountQuery extends Query<QCompanyCount, QCompanyCountVariables> { }

interface CompanyCountProps extends WithStyles<typeof styles> {
    title?: string; // Optional, use only to load initial state in main companies page
    subtitle?: string; // Optional, use only to load initial state in main companies page
    status?: CompanyStatus;
    paperworkStatus?: PaperworkStatus;
    paymentStatus?: PaymentStatus;
    selected?: boolean;
    onClick?: () => void;
}

class CompanyCount extends React.Component<CompanyCountProps & WithStyles<typeof styles>> {
    render = () => (
        <CompanyCountQuery
            query={COMPANY_COUNT_QUERY}
            variables={{
                status: this.props.status,
                paperworkStatus: this.props.paperworkStatus,
                paymentStatus: this.props.paymentStatus,
            }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <Loading />;
                }
                if (error) {
                    return (
                        <pre style={{ whiteSpace: 'pre-line' }}>
                            {error.message}
                        </pre>
                    );
                }
                if (!data || !data.companies) {
                    // La comprobacion de !data.allTasks no debería ser necesaria 
                    // pero sin esto al hacer un resetStore hay un render con un data = {}
                    return <div />;
                }
                return (
                    <Card style={{ padding: 0 }} elevation={this.props.selected ? 8 : 1} >
                        <ButtonBase className={this.props.classes.cardAction} onClick={this.props.onClick}>
                            <Typography variant="h6">{this.props.title}</Typography>
                            <Typography variant="overline" color="textSecondary">
                                {this.props.subtitle ? this.props.subtitle : 'Empresas'}
                            </Typography>
                            <Typography variant="h6">{data.companies.totalCount || 0}</Typography>
                        </ButtonBase>
                    </Card>
                );
            }}
        </CompanyCountQuery>
    )
}

export default withStyles(styles)(CompanyCount);