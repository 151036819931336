import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { TextAnnotation, BoundingPoly, Vertex, } from '../../lib/gvision';
import classnames from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    region: {
      mixBlendMode: 'multiply',
      fill: 'white',
      '&:hover': {
        fill: 'rgba(255,255,200,0.8)'
      }
    },
    highlight: {
      fill: 'rgba(200,200,255,0.5)'
    },
    selected: {
      fill: 'yellow'
    },
    selectionRect: {
      fill: 'none',
      stroke: 'black',
      strokeDasharray: '12',
      strokeOpacity: 0.2,
      strokeWidth: 4
    }
  });

interface Props extends WithStyles<typeof styles> {
  data: TextAnnotation;
  image: string;
  selected: number[];
  highlighted: number[];
  rotation: number;
  zoom: boolean;
  onSelected: (a: { selected: number[], raw: string }, addToSelection: boolean) => void;
  onNext: () => void;
}
interface State {
  start: Vertex;
  end: Vertex;
  isSelecting: boolean;
}

export const VisionViewer = withStyles(styles)(class extends React.Component<Props, State> {
  state = {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    isSelecting: false,
  };
  svgRef: React.RefObject<SVGSVGElement>;
  gRef: React.RefObject<SVGGElement>;
  point: SVGPoint;
  constructor(props: Props) {
    super(props);
    this.svgRef = React.createRef();
    this.gRef = React.createRef();
  }
  coordsOf = (e: React.MouseEvent): number[] => {
    this.point.x = e.clientX;
    this.point.y = e.clientY;
    const p = this.point.matrixTransform(this.gRef.current!.getScreenCTM()!.inverse());
    return [p.x, p.y];
  }
  toClientCoords = (x: number, y: number): number[] => {
    this.point.x = x;
    this.point.y = y;
    const p = this.point.matrixTransform(this.gRef.current!.getScreenCTM()!);
    return [p.x, p.y];
  }
  startSelection = (e: React.MouseEvent) => {
    const [x, y] = this.coordsOf(e);
    const start = { x, y };
    const end = { x, y };
    this.setState({
      start,
      end,
      isSelecting: true
    });
  };
  getSelection = (bbox: BBox): { selected: number[], raw: string } => {
    const selected = [];
    const page = this.props.data.pages[0];
    if (!page) {
      return { selected: [], raw: '' };
    }
    let i = 0;
    let raw = '';
    for (const block of page.blocks) {
      for (const paragraph of block.paragraphs) {
        for (const word of paragraph.words) {
          if (intersects(toBBox(word.bounding_box.vertices), bbox)) {
            selected.push(i);
            for (const symbol of word.symbols) {
              raw += symbol.text;
              if (symbol.property && symbol.property.detected_break && symbol.property.detected_break.type !== 0) {
                raw += ' ';
              }
            }
          }
          i++;
        }
      }
    }
    raw = raw.trim();
    return { selected, raw };
  }
  endSelection = (e: React.MouseEvent) => {
    const bbox = toBBox([this.state.start, this.state.end]);
    this.setState({
      isSelecting: false
    });
    this.props.onSelected(this.getSelection(bbox), e.shiftKey);
  }
  updateSelection = (e: React.MouseEvent) => {
    if (this.state.isSelecting) {
      const [x, y] = this.coordsOf(e);
      this.setState({ end: { x, y } });
    }
  }
  componentDidMount = () => {
    if (this.svgRef.current) {
      this.point = this.svgRef.current!.createSVGPoint();
    }
  }
  render = () => {
    const page = this.props.data.pages[0];
    if (!page) {
      return <img src={this.props.image} />;
    }
    const classes = this.props.classes;
    const polys = [];
    const selectedWords = [];
    const selectingBBox = toBBox([this.state.start, this.state.end]);
    let selectedBBox = { x1: Infinity, y1: Infinity, x2: -Infinity, y2: -Infinity };
    for (const block of page.blocks) {
      for (const paragraph of block.paragraphs) {
        for (const word of paragraph.words) {
          const i = polys.length;
          const selected = this.props.selected.includes(i);
          const wordBB = toBBox(word.bounding_box.vertices);
          if (selected) {
            selectedWords.push(word);
            selectedBBox = mergeBBox(selectedBBox, wordBB);
          }
          const selecting = this.state.isSelecting && intersects(wordBB, selectingBBox);
          polys.push(
            <polygon
              key={polys.length}
              points={boundinBoxToSvg(word.bounding_box)}
              className={classnames(
                classes.region,
                (this.state.isSelecting ? selecting : selected) && classes.selected,
                this.props.highlighted.includes(i) && classes.highlight
              )}
              style={{ opacity: word.confidence }}
            />
          );
        }
      }
    }

    const showRect = this.state.isSelecting ? selectingBBox : this.props.selected.length ? selectedBBox : null;
    const isLandscape = this.props.rotation === 90 || this.props.rotation === 270;
    const rotations = [0, 90, 180, 270];
    const rotationIndex = rotations.indexOf(this.props.rotation);
    const rotationTransX = [0, 0, -page.width, -page.width][rotationIndex];
    const rotationTransY = [0, -page.height, -page.height, 0][rotationIndex];
    const w = isLandscape ? page.height : page.width;
    const h = isLandscape ? page.width : page.height;
    return (
      <svg
        style={{ maxHeight: this.props.zoom ? undefined : '150%' }}
        ref={this.svgRef}
        viewBox={`0 0 ${w} ${h}`}
        onMouseDown={(e) => { if (e.button === 0) { this.startSelection(e); } }}
        onMouseUp={(e) => { if (e.button === 0) { this.endSelection(e); } }}
        onMouseMove={(e) => { this.updateSelection(e); }}
        onContextMenu={(e) => { e.preventDefault(); this.props.onNext(); }}
      >
        <g transform={`rotate(${this.props.rotation})`}>
          <g transform={`translate(${rotationTransX},${rotationTransY})`}>
            <g ref={this.gRef} />
            <image width={page.width} height={page.height} href={this.props.image} />
            {polys}
            {showRect ?
              <rect
                className={classes.selectionRect}
                x={showRect.x1}
                y={showRect.y1}
                width={showRect.x2 - showRect.x1}
                height={showRect.y2 - showRect.y1}
              /> : null
            }
          </g>
        </g>
      </svg>
    );
  }
});

function boundinBoxToSvg(b: BoundingPoly): string {
  return b.vertices.map((v) => `${v.x},${v.y}`).join(' ');
}

interface BBox {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

function toBBox(vertices: Vertex[]): BBox {
  let x1 = Infinity;
  let y1 = Infinity;
  let x2 = -Infinity;
  let y2 = -Infinity;
  for (const v of vertices) {
    x1 = Math.min(v.x, x1);
    y1 = Math.min(v.y, y1);
    x2 = Math.max(v.x, x2);
    y2 = Math.max(v.y, y2);
  }
  return { x1, x2, y1, y2 };
}

function intersects(a: BBox, b: BBox): boolean {
  return (a.x1 < b.x2 &&
    a.x2 > b.x1 &&
    a.y1 < b.y2 &&
    a.y2 > b.y1);
}

function mergeBBox(a: BBox, b: BBox): BBox {
  return {
    x1: Math.min(a.x1, b.x1),
    y1: Math.min(a.y1, b.y1),
    x2: Math.max(a.x2, b.x2),
    y2: Math.max(a.y2, b.y2),
  };
}
