// @ts-ignore
window.setImmediate = (c: any) => {
  setTimeout(c, 0);
}; // shim for monaco-languageclient
import { listen, MessageConnection } from 'vscode-ws-jsonrpc';
import {
  MonacoLanguageClient, CloseAction, ErrorAction,
  createConnection,
  MonacoToProtocolConverter,
  ProtocolToMonacoConverter,
  MonacoLanguages,
  MonacoWorkspace,
  ConsoleWindow,
  Commands,
  Disposable
} from 'monaco-languageclient';
const normalizeUrl = require('normalize-url');
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Services } from 'monaco-languageclient';

class MyMonacoCommands implements Commands {  // FIXME this should dispatch to editors
  public registerCommand(command: string, callback: (...args: any[]) => any, thisArg?: any): Disposable {
    return { dispose: () => {/**/ } };
  }
}

(() => {
  const m2p = new MonacoToProtocolConverter();
  const p2m = new ProtocolToMonacoConverter();
  Services.install({
    commands: new MyMonacoCommands(),
    languages: new MonacoLanguages(p2m, m2p),
    workspace: new MonacoWorkspace(p2m, m2p, undefined), // rootUri
    window: new ConsoleWindow()
  });
})();

// create the web socket
const webSocket = createWebSocket(createUrl());
// listen when the web socket is opened
listen({
  webSocket,
  onConnection: connection => {
    // create and start the language client
    const languageClient = createLanguageClient(connection);
    const disposable = languageClient.start();
    connection.onClose(() => disposable.dispose());
  }
});

function createLanguageClient(connection: MessageConnection): MonacoLanguageClient {
  return new MonacoLanguageClient({
    name: 'Accounting',
    clientOptions: {
      // use a language id as a document selector
      documentSelector: ['acc'],
      // disable the default error handler
      errorHandler: {
        error: () => ErrorAction.Continue,
        closed: () => CloseAction.DoNotRestart
      }
    },
    // create a language client connection from the JSON RPC connection on demand
    connectionProvider: {
      get: (errorHandler, closeHandler) => {
        // @ts-ignore
        return Promise.resolve(createConnection(connection, errorHandler, closeHandler));
      }
    }
  });
}

function createUrl(): string {
  const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
  // tslint:disable-next-line
  const WS_URL = process.env['WS_URL'];
  if (WS_URL) {
    return normalizeUrl(`${WS_URL}/lsp`);
  } else {
    return `${protocol}://${location.hostname}:5000/lsp`;
  }
}

function createWebSocket(urlParam: string): WebSocket {
  const socketOptions = {
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 1000,
    reconnectionDelayGrowFactor: 1.3,
    connectionTimeout: 10000,
    maxRetries: Infinity,
    debug: false
  };
  // @ts-ignore
  return new ReconnectingWebSocket(urlParam, undefined, socketOptions);
}