import * as React from 'react';
import monaco from '../../monaco/monaco';

interface Props {
  doc: string;
  company: string;
  value: string;
}

export class Editor extends React.Component<Props> {
  private containerElement?: any;
  private editor?: monaco.editor.IStandaloneCodeEditor;
  constructor(props: any) {
    super(props);
    this.containerElement = undefined;
  }
  insertText = (text: string) => {
    if (this.editor) {
      this.editor.trigger('keyboard', 'type', { text });
      // TODO remove usage of private api to move to next tabstob. 
      // alternatives: editor.trigger('keyboard', 'tab')
      interface SnipperController2Fake extends monaco.editor.IEditorContribution {
        isInSnippet: () => boolean;
        next: () => void;
      }
      const snippetController2: SnipperController2Fake = this.editor.getContribution('snippetController2');
      if (snippetController2.isInSnippet()) {
        snippetController2.next();
      }
      this.editor.focus();
    }
  }
  replaceText = (text: string) => {
    if (this.editor) {
      this.editor.setValue(text);
    }
  }
  value = () => {
    if (this.editor) {
      return this.editor.getValue();
    } else {
      return undefined;
    }
  }
  _getModel = () => {
    const uri = monaco.Uri.parse(`inmemory://${this.props.company}/${this.props.doc}`);
    return monaco.editor.getModel(uri) || monaco.editor.createModel(this.props.value, 'acc', uri);
  }
  assignRef = (component: any) => {
    this.containerElement = component;
  }
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.company !== this.props.company || prevProps.doc !== this.props.doc) {
      if (this.editor) {
        this.editor.setModel(this._getModel());
      }
    }
  }
  componentDidMount = () => {
    if (this.containerElement) {
      this.editor = monaco.editor.create(this.containerElement, {
        language: 'acc',
        glyphMargin: true,
        folding: true,
        lightbulb: {
          enabled: true
        },
        theme: 'accTheme',
        automaticLayout: true
      });
      this.editor.setModel(this._getModel());
    }
  }
  componentWillUnmount = () => {
    if (this.editor) {
      this.editor.getModel().dispose();
      this.editor.dispose();
    }
  }
  render() {
    return (
      <div
        ref={this.assignRef}
        style={{ flex: '1' }}
        className="react-monaco-editor-container"
      />);
  }
}