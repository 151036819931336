import * as React from 'react';
import { getCompanyName } from '../lib/utilBrowser';

interface State {
  companyName?: string;
}
class CompanyName extends React.Component<{ company: string }, State> {
  state = {} as State;
  componentDidMount = async () => {
    getCompanyName(this.props.company)
      .then((companyName: string) => {
        this.setState({ companyName });
      });
  }
  render = () => {
    return (
      this.state.companyName || '...'
    );
  }
}

export default CompanyName;