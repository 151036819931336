import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { UserProfile } from '../gen/globalTypes';
import { client as apolloClient } from '../client';
import { QCompanyNameVariables, QCompanyName } from '../gen/QCompanyName';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { QLoggedUser, QLoggedUser_viewer } from '../gen/QLoggedUser';

export interface AlertProps {
  body: string | React.ReactNode;
  title?: string;
  ok?: string;
}

export async function Alert(props: AlertProps): Promise<void> {
  return new Promise((resolve, reject) => {
    const wrapper = document.body.appendChild(document.createElement('div'));
    function cleanup() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => {
          if (wrapper.parentNode) { // wrapper.remove() does not work in IE
            wrapper.parentNode.removeChild(wrapper);
          }
        });
      });
    }
    ReactDOM.render(
      React.createElement(
        MuiAlert,
        {
          ...props,
          onResult: (result: boolean) => {
            cleanup();
            resolve();
          }
        } as AlertDialogProps,
        null
      ),
      wrapper
    );
  });
}

interface AlertDialogProps extends AlertProps {
  onResult: () => void;
}

class MuiAlert extends React.Component<AlertDialogProps> {
  render = () => {
    // Allow to pass empty title ('') VS default one
    const title = this.props.title !== undefined ? this.props.title : 'Abaq';
    return (
      <Dialog open={true} onClose={() => { this.props.onResult(); }}>
        {
          (() => {
            if (title.length > 0) {
              return (
                <DialogTitle>{title}</DialogTitle>
              );
            }
            return null;
          })()
        }
        <DialogContent>
          <DialogContentText>{this.props.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onResult(); }}>{this.props.ok || 'Aceptar'}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export interface ConfirmProps {
  body: string | React.ReactNode;
  title?: string;
  cancel?: string;
  confirm?: string;
}
export async function Confirm(props: ConfirmProps): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const wrapper = document.body.appendChild(document.createElement('div'));
    function cleanup() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => {
          if (wrapper.parentNode) { // wrapper.remove() does not work in IE
            wrapper.parentNode.removeChild(wrapper);
          }
        });
      });
    }
    ReactDOM.render(
      React.createElement(
        MuiConfirm,
        {
          ...props,
          onResult: (result: boolean) => {
            cleanup();
            resolve(result);
          }
        } as ConfirmDialogProps,
        null
      ),
      wrapper
    );
  });
}

interface ConfirmDialogProps extends ConfirmProps {
  onResult: (result: boolean) => void;
}

class MuiConfirm extends React.Component<ConfirmDialogProps> {
  render = () => {
    // Allow to pass empty title ('') VS default one
    const title = this.props.title !== undefined ? this.props.title : '¿Estás seguro?';
    return (
      <Dialog open={true} onClose={() => { this.props.onResult(false); }}>
        {
          (() => {
            if (title.length > 0) {
              return (
                <DialogTitle>{title}</DialogTitle>
              );
            }
            return null;
          })()
        }
        <DialogContent>
          <DialogContentText>{this.props.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onResult(false); }}>{this.props.cancel || 'Cancelar'}</Button>
          <Button onClick={() => { this.props.onResult(true); }}>{this.props.confirm || 'Continuar'}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export function formatUserProfile(userProfile: UserProfile): string {
  switch (userProfile) {
    case UserProfile.ADVISOR: {
      return 'Asesor';
    }
    case UserProfile.TRANSCRIPTOR: {
      return 'Transcriptor';
    }
    case UserProfile.USER: {
      return 'Usuario';
    }
    default: {
      return userProfile;
    }
  }
}

export async function getCompanyName(companyId: string): Promise<string> {
  const result = await apolloClient.query<QCompanyName, QCompanyNameVariables>({
    query: gql`
      query QCompanyName($company: ID!) {
        company(id: $company) {
          id
          name,
          taxId,
        }
      }
    `,
    variables: { company: companyId }
  });
  if (result.data.company) {
    return result.data.company.name || 'Empresa ' + result.data.company.taxId;
  }
  return '';
}

export async function getUserProfile(uuid: string): Promise<QLoggedUser_viewer | null> {
  return await apolloClient.query<QLoggedUser>({
    query: gql`
      query QLoggedUser {
        viewer {
          id, profile, name, email
        }
      }
    `
  }).then((result: ApolloQueryResult<QLoggedUser>) => {
    return result.data.viewer;
  });
}