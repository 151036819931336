import * as React from 'react';
import {
  Table, TableBody, TableRow, TableCell, TableHead
} from '@material-ui/core';
import { QueryResult } from '../../api';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Loading } from '../../widgets/loading';
import { QDocInfo, QDocInfoVariables } from '../../gen/QDocInfo';
import { renderAmount } from '../../lib/util';

class MyQuery extends Query<QDocInfo, QDocInfoVariables> { }

const QUERY = gql`
  query QDocInfo($company: ID!, $sql: String!) {
    company(id: $company) {
      id,
      sql(sql: $sql)
    }
  }
`;

interface Props {
  company: string;
  doc: string;
  onAccountClicked: (oid: number, account: string) => void;
}

export const Journal = ({ doc, company, onAccountClicked }: Props) => (
  <MyQuery
    query={QUERY}
    variables={{
      company, sql:
        `select date,options,amount,currency,report_amount,balance from journal where doc='${doc}'`
    }}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return <Loading />;
      }
      if (error || !data) {
        return <div>error {JSON.stringify(error)}</div>;
      }
      if (data.company == null) {
        return <div />;
      }
      const q = data.company.sql as QueryResult;
      return (
        <React.Fragment>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Opciones</TableCell>
                <TableCell align={'right'}>Importe</TableCell>
                <TableCell align={'right'}>EUR</TableCell>
                <TableCell align={'right'}>Balance (EUR)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                q.rows.map(([date, options, amount, currency, reportAmount, balance], i) =>
                  (
                    <TableRow key={i}>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>{date}</TableCell>
                      <TableCell>{options}</TableCell>
                      <TableCell align={'right'} style={{ whiteSpace: 'nowrap' }}>
                        {renderAmount(amount as number)} {currency}
                      </TableCell>
                      <TableCell align={'right'} style={{ whiteSpace: 'nowrap' }}>
                        {renderAmount(reportAmount as number)} €
                      </TableCell>
                      <TableCell align={'right'} style={{ whiteSpace: 'nowrap' }}>
                        {renderAmount(balance as number)} €
                      </TableCell>
                    </TableRow>
                  )
                )
              }
            </TableBody>
          </Table>
        </React.Fragment>
      );
    }}
  </MyQuery>
);