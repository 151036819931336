import gql from 'graphql-tag';
import * as React from 'react';
import { Grid, ButtonBase, Card, Typography, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Query } from 'react-apollo';
import { Loading } from '../widgets/loading';

const styles = (theme: Theme) =>
    createStyles({
        cardAction: {
            display: 'block',
            textAlign: 'initial',
            width: '100%',
            padding: '12px',
            minWidth: '250px',
        }
    });

const PAPERWORK_COUNT_QUERY = gql`
query QPaperworkCount {
    gatheringInfoCompleted: companies(
    paperworkStatus: NEW_FREELANCE_GATHERING_INFO_COMPLETED
  ) {
    totalCount
  },
  reviewingProposal: companies(
    paperworkStatus: NEW_FREELANCE_REVIEWING_PROPOSAL
  ) {
    totalCount
  },
  reviewingProposalCompleted: companies(
    paperworkStatus: NEW_FREELANCE_REVIEWING_PROPOSAL_COMPLETED
  ) {
    totalCount
  }
}
`;

class PaperworkCountQuery extends Query { }

interface PaperworkCountProps extends WithStyles<typeof styles> {
    title?: string; // Optional, use only to load initial state in main companies page
    onClick?: () => void;
}

class PaperworkCount extends React.Component<PaperworkCountProps & WithStyles<typeof styles>> {
    render = () => (
        <PaperworkCountQuery
            query={PAPERWORK_COUNT_QUERY}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <Loading />;
                }
                if (error) {
                    return (
                        <pre style={{ whiteSpace: 'pre-line' }}>
                            {error.message}
                        </pre>
                    );
                }
                if (!data) {
                    return <div />;
                }
                return (
                    <Card style={{ padding: 0 }}>
                        <ButtonBase className={this.props.classes.cardAction} onClick={this.props.onClick}>
                            <Typography variant="h6">{this.props.title}</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={4}>
                                    <Typography variant="overline" color="textSecondary">
                                        Esperando Propuesta
                                    </Typography>
                                    <Typography variant="h6">{data.gatheringInfoCompleted.totalCount || 0}</Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography variant="overline" color="textSecondary">Propuesta enviada</Typography>
                                    <Typography variant="h6">{data.reviewingProposal.totalCount || 0}</Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography variant="overline" color="textSecondary">
                                        Esperando Alta en PAE
                                    </Typography>
                                    <Typography variant="h6">
                                        {data.reviewingProposalCompleted.totalCount || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ButtonBase>
                    </Card>
                );
            }}
        </PaperworkCountQuery>
    )
}

export default withStyles(styles)(PaperworkCount);