import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Dialog
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, Mutation, FetchResult } from 'react-apollo';
import { Loading } from '../../widgets/loading';
import { ExtractionEditor } from './editor';
import { onMutationError } from '../../lib/errorReporter';
import { QExtraction } from '../../gen/QExtraction';
import { Extraction } from '../../gen/globalTypes';
import { client as apolloClient } from '../../client';
import { MCancelExtraction, MCancelExtractionVariables } from '../../gen/MCancelExtraction';
import { ApolloError } from 'apollo-client';
import { MResolveExtraction, MResolveExtractionVariables } from '../../gen/MResolveExtraction';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
      display: 'flex',
    },
    flex: {
      flex: 1,
    },
  });

class ExtractionQuery extends Query<QExtraction> { }

const TRANSCRIPTOR_EXTRACTION_QUERY = gql`
  query QExtraction($doc: ID!) {
    extraction(id: $doc) {
      id,
      company {
        id,
        name,
        taxId
      }
      title,
      text,
      mimeType,
      docType,
      thumbnailUrl,
      contentUrl, 
      imageOriginalPages,
      imagePages {
        imageUrl,
        visionUrl,
        width,
        height,
      },
      extraction,
      extractionMessage,
      lockDate,
      lockedBy {
        id
        name
      }
    }
  }
  `;

class ResolveExtractionMutation extends Mutation<MResolveExtraction, MResolveExtractionVariables> { }

const CANCEL_EXTRACTION_MUTATION = gql`
  mutation MCancelExtraction($id: ID!) {
    cancelExtraction(id: $id )
  }
`;

const RESOLVE_EXTRACTION_MUTATION = gql`
  mutation MResolveExtraction($id: ID!, $extraction: Extraction!, $lockNewExtraction: Boolean) {
    resolveExtraction(id: $id, extraction: $extraction, lockNewExtraction: $lockNewExtraction) {
      resolved
      id
    }
  }
`;

interface ExtractionDialogProps {
  handleNavigate: (id: string | null) => void;
  transcriptorMode?: boolean;
}

interface ExtractionDialogCompleteProps extends
  RouteComponentProps<{
    doc: string;
  }>,
  ExtractionDialogProps,
  WithStyles<typeof styles> { }

interface State {
  loadedMessage: string | null;
}

class ExtractionDialog extends React.Component<ExtractionDialogCompleteProps, State> {
  constructor(props: ExtractionDialogCompleteProps) {
    super(props);
    this.state = {
      loadedMessage: null,
    };
  }
  handleClose = async () => {
    apolloClient.mutate<MCancelExtraction, MCancelExtractionVariables>({
      mutation: CANCEL_EXTRACTION_MUTATION,
      variables: { id: this.props.match.params.doc }
    }).then((result: FetchResult<MCancelExtraction>) => {
      this.props.handleNavigate(null);
    }).catch((error: ApolloError) => {
      onMutationError(error);
    });
  }
  render = () => {
    const doc = this.props.match.params.doc;
    const classes = this.props.classes;
    return (
      <Dialog
        fullScreen
        open={true}
        onClose={this.handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <ExtractionQuery
          query={TRANSCRIPTOR_EXTRACTION_QUERY}
          variables={{ doc }}
          onCompleted={(data: QExtraction) => {
            if (!data || !data.extraction) {
              // Si intentamos acceder a un documento que no podemos cargar, volvemos al inicio
              this.props.handleNavigate(null);
            }
          }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (<Loading />);
            }
            if (error || !data) {
              return (<div>Error {JSON.stringify(error) || 'no data'}</div>);
            }
            if (data.extraction == null) {
              return <div />;
            }
            const docData = data.extraction;
            return (
              <ResolveExtractionMutation
                mutation={RESOLVE_EXTRACTION_MUTATION}
                onError={(e) => { onMutationError(e); }}
              >
                {(saveFn, { loading: saving }) => (
                  <ExtractionEditor
                    editor
                    transcriptorMode={this.props.transcriptorMode ? true : false}
                    companyId={docData.company.id}
                    companyName={docData.company.name}
                    taxId={docData.company.taxId}
                    close={this.handleClose}
                    key={docData.id}
                    initialValue={docData.extraction}
                    lockedBy={docData.lockedBy ? docData.lockedBy.name : null}
                    lockDate={docData.lockDate}
                    imageUrl={docData.imagePages[0].imageUrl}
                    visionUrl={docData.imagePages[0].visionUrl}
                    onSave={async (extraction: Extraction, lockNewExtraction: boolean) => {
                      const res = await saveFn({
                        variables: {
                          id: this.props.match.params.doc,
                          extraction,
                          lockNewExtraction
                        }
                      });
                      if (res) {
                        if (res.data && res.data.resolveExtraction.id) {
                          this.props.handleNavigate(res.data.resolveExtraction.id);
                        } else {
                          this.props.handleNavigate(null);
                        }
                      }
                    }}
                  />
                )}
              </ResolveExtractionMutation>
            );
          }}
        </ExtractionQuery>
      </Dialog >
    );
  }
}
export default withStyles(styles)(ExtractionDialog);