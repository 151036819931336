import moment from 'moment';
import { UserProfile } from '../gen/globalTypes';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import * as accounting from 'accounting';
import { History } from 'history';
import { Decimal } from 'decimal.js';

export const ACTIVE_ROW_BGCOLOR = 'rgba(0,0,0,0.14)';

export function formatISODate(date: string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDate(isoDate: string): string {
  return moment(isoDate).format('DD-MM-YYYY');
}

export function formatDateTime(isoDateTime: string): string {
  return moment(isoDateTime).format('DD-MM-YYYY HH:mm:ss');
}

export function formatUserProfile(userProfile: UserProfile): string {
  switch (userProfile) {
    case UserProfile.ADVISOR: {
      return 'Asesor';
    }
    case UserProfile.TRANSCRIPTOR: {
      return 'Transcriptor';
    }
    case UserProfile.USER: {
      return 'Usuario';
    }
    default: {
      return userProfile;
    }
  }
}

export function sqlToRows(jsonResult: { columns: any[], rows: any[] }): Array<{}> {
  let rows = jsonResult.rows.map((r) => {
    let index = 0;
    const res = {};
    for (const c of r) {
      res[jsonResult.columns[index]] = c;
      index += 1;
    }
    return res;
  });
  return rows;
}

export function renderAmount(amount: number) {
  return (amount / 100.0).toFixed(2);
}

export function formatDateLikeMobile(date: string): string {
  let result: string = '';
  const d = dayjs(date).locale('es');
  if (d.year === dayjs().year) {
    result = d.format('D MMM');
  } else {
    result = d.format('D MMM YYYY');
  }
  return result.toUpperCase();
}

function _round(value: number, ndec: number = 2): number {
  // why 6? Because in classic was working that way, I really don't know the minimun needed (blush)
  return new Decimal(value.toFixed(ndec + 6)).toDP(ndec).toNumber();
}

export function formatCurrencyLikeMobile(amount: number, currency: string, ndec: number = 2): string {
  return accounting.formatMoney(_round(amount, ndec), {
    symbol: currency === 'EUR' ? '€' : currency,
    format: '%v%s',
    decimal: ',',
    thousand: '.',
    precision: ndec
  });
}

export function getWindowState<T>(history: History): T | null {
  return (history.location.state as T) || null;
}

export function navigateWithState(history: History, pathname: string, state?: any): void {
  if (state) {
    history.replace({
      pathname: window.location.pathname,
      state
    });
  }
  history.push(pathname);
}

export type FilterBoolean = 'checked' | 'unchecked';

const STRIPE_DASHBOARD_PREFIX = process.env.STRIPE_ENV === 'production' ? '' : '/test';
export function stripeCustomerUrl(customerId: string): string {
  return `https://dashboard.stripe.com${STRIPE_DASHBOARD_PREFIX}/customers/${customerId}`;
}
export function stripeSubscriptionUrl(subscriptionId: string): string {
  return `https://dashboard.stripe.com${STRIPE_DASHBOARD_PREFIX}/subscriptions/${subscriptionId}`;
}

export function getPreviousQuarter(date: dayjs.Dayjs): { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } {
  const startDate = date.startOf('month').subtract(3 + date.get('month') % 3, 'month');
  const endDate = startDate.add(3, 'month').subtract(1, 'day');
  return { startDate, endDate };
}

export type DateRange = { startDate: string, endDate: string };
export type FiscalPeriod = '1T' | '2T' | '3T' | '4T';

export function getDateRangeForFiscalPeriod(year: number, periodo: FiscalPeriod): DateRange {
  switch (periodo) {
    case '1T': {
      return { startDate: `${year}-01-01`, endDate: `${year}-03-31` };
    }
    case '2T': {
      return { startDate: `${year}-04-01`, endDate: `${year}-06-30` };
    }
    case '3T': {
      return { startDate: `${year}-07-01`, endDate: `${year}-09-30` };
    }
    case '4T': {
      return { startDate: `${year}-10-01`, endDate: `${year}-12-31` };
    }
    default:
      throw new Error('Invalid period: ' + periodo);
  }
}
