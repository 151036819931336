import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getMainDefinition } from 'apollo-utilities';
// import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { OperationDefinitionNode } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';

// tslint:disable-next-line
const WS_URL = process.env['WS_URL'] || `ws://${location.hostname}:5000`;
const HTTP_URL = WS_URL.replace(/^ws/, 'http');

const GRAPHQL_WS_URL = `${WS_URL}/graphql`;
const GRAPHQL_HTTP_URL = `${HTTP_URL}/graphql`;

async function getAuth(): Promise<String | undefined> {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    // const token = 'playground ' + JSON.stringify({ sub: auth.currentUser.email });
    return `Bearer ${token}`;
  } else {
    return undefined;
  }
}

const subscriptionClient = new SubscriptionClient(
  GRAPHQL_WS_URL,
  {
    reconnect: true,
    connectionParams: async () => {
      return { authorization: await getAuth() };
    },
  }
);

subscriptionClient.onReconnected(() => { resetStore(); });
const wsLink = new WebSocketLink(subscriptionClient);

const httpLink = createUploadLink({
  uri: GRAPHQL_HTTP_URL,
  fetch: async (uri: RequestInfo, options: RequestInit) => {
    // tslint:disable-next-line: no-string-literal
    options.headers!['Authorization'] = await getAuth();
    return fetch(uri, options);
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation, name } = getMainDefinition(query) as OperationDefinitionNode;
    return (kind === 'OperationDefinition' && operation === 'mutation' &&
      name && name.value.includes('Upload')) as boolean;
  },
  httpLink,
  wsLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          // tslint:disable-next-line:no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        // tslint:disable-next-line:no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    link
  ]),
  cache: new InMemoryCache()
});

export const resetClientAuth = async () => {
  (wsLink as any).subscriptionClient.close(false, false);
  client.resetStore();
};

export const resetStore = async () => {
  // https://github.com/apollographql/apollo-client/issues/3555
  await client.cache.reset();
  await client.reFetchObservableQueries();
};