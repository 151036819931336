import MaterialTable, { Column, Query as TableQuery, Options as TableOptions } from 'material-table';
import * as React from 'react';
import { WatchServerUpdates } from '../notifications';
import { ApolloClient } from 'apollo-client';
import { ApolloConsumer } from 'react-apollo';
import { RouterProps } from 'react-router';
import { tableIcons } from '../lib/tableIcons';
import { onGenericError } from '../lib/errorReporter';
import { navigateWithState } from '../lib/util';

export { Query as TableQuery } from 'material-table';

type FetchQuery =
  (client: ApolloClient<any>, query: TableQuery) => Promise<{ items: any[], totalCount: number } | undefined>;

interface PathWithState<T> {
  pathname: string;
  state?: T;
}

interface Props extends Partial<RouterProps> {
  title: string;
  columns: Column[];
  fetchQuery: FetchQuery;
  onRowClick?: (rowData: any) => void;
  rowToPathAndState?: (rowData: any) => PathWithState<unknown>;
  initialPage?: number;
  options?: Partial<TableOptions>;
}

export class LiveTable extends React.Component<Props> {
  tableRef: React.RefObject<any>;
  initialPage: number | null;
  constructor(props: Props) {
    super(props);
    this.tableRef = React.createRef();
    if (this.props.initialPage != null) {
      this.initialPage = this.props.initialPage;
    }
  }
  onServerChanged = () => {
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }
  render = () => (
    <React.Fragment>
      <WatchServerUpdates onServerChanged={this.onServerChanged} />
      <ApolloConsumer>
        {(client) =>
          <MaterialTable
            tableRef={this.tableRef}
            icons={tableIcons}
            title={this.props.title}
            columns={this.props.columns}
            data={async (query: TableQuery) => {
              if (this.initialPage != null) {
                query.page = this.initialPage;
                this.initialPage = null;
              }
              try {
                let data = await this.props.fetchQuery(client, query);
                if (!data) {
                  return { data: [], page: 0, totalCount: 0 };
                }
                return { data: data.items, page: query.page, totalCount: data.totalCount };
              } catch (err) {
                onGenericError(err);
                return { data: [], page: 0, totalCount: 0 };
              }
            }}
            options={{
              pageSize: 500,
              pageSizeOptions: [10, 50, 100, 200, 500],
              filtering: true,
              emptyRowsWhenPaging: false,
              search: false,
              padding: 'dense',
              ...this.props.options
            }}
            onRowClick={(event, rowData) => {
              if (event && event.ctrlKey) {
                if (this.props.rowToPathAndState) {
                  const navigationWithState = this.props.rowToPathAndState(rowData);
                  window.open(navigationWithState.pathname, '_blank');
                }
              } else {
                if (this.props.rowToPathAndState && this.props.history) {
                  const navigationWithState = this.props.rowToPathAndState(rowData);
                  navigateWithState(this.props.history, navigationWithState.pathname, navigationWithState.state);
                } else if (this.props.onRowClick) {
                  this.props.onRowClick(rowData);
                }
              }
            }}
          />
        }
      </ApolloConsumer>
    </React.Fragment>

  )
}