import dayjs from 'dayjs';
// @ts-ignore
import chrono from 'chrono-node';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parseAmount } from './parseAmount';
import { validateNIF } from './nif';
import { InvoiceCategory, InvoiceRectification, InvoiceType } from '../../../gen/globalTypes';

const countryCodes: { [s: string]: string } = require('./countryCodes.json');
const countryNames: { [s: string]: string } = require('./countryNames.json');
const currencyNames: { [s: string]: string } = require('./currencyNames.json');
const currencyCodes: { [s: string]: string } = require('./currencyCodes.json');

dayjs.extend(customParseFormat);

export interface Field {
  id: string;
  name: string;
  isValid: (s: string) => boolean;
  parse: (raw: string) => string;
  choices?: string[];
  header?: string;
}

const codeRE = /^[0-9A-Z/]+$/;
const spaceRE = /\s/g;
const forbiddenInCodeRE = /[^0-9A-Z/]/g;

const isValidCode = (s: string): boolean => s.match(codeRE) != null;
const parseCode = (s: string): string => {
  return s.toUpperCase().replace(spaceRE, '').replace(forbiddenInCodeRE, '/'); //
};

const dateRE = /^\d{4}\-\d{2}-\d{2}$/;
export const isValidDate = (s: string): boolean => {
  if (s.match(dateRE) == null) {
    return false;
  }
  return dayjs(s, { format: 'YYYY-MM-DD' }).format('YYYY-MM-DD') === s;
};

// TODO use https://github.com/wanasit/chrono or similar
export const parseDate = (s: string): string => {
  let parsed = chrono.es.parseDate(s);
  if (!parsed) {
    parsed = chrono.parseDate(s);
    if (!parsed) {
      return s;
    }
  }
  return dayjs(parsed).format('YYYY-MM-DD');
};

const nifRE = /^[0-9A-Z/]+$/;
const notnifRE = /[^0-9A-Z]/g;

export const isValidNIF = (s: string): boolean => {
  if (s.startsWith('ES')) {
    return false;
  }
  if (s.length === 9) {
    return validateNIF(s);
  } else {
    return s.match(nifRE) != null;
  }
};

export const parseNIF = (s: string): string => {
  s = s.toUpperCase().replace(notnifRE, '');
  if (s.startsWith('ES')) {
    s = s.substr(2);
  }
  return s;
};

const nameRE = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \d\-&]+$/u;
const forbiddenInNameRE = /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \d\-&]/ug;
const doubleSpaceRE = /\s\s+/g;
export const isValidName = (s: string): boolean => {
  if (s.startsWith(' ') || s.endsWith(' ')) {
    return false;
  }
  return s.match(nameRE) != null && s.match(doubleSpaceRE) == null;
};
export const parseName = (s: string): string => {
  return s.trim().replace(forbiddenInNameRE, '').replace(doubleSpaceRE, ' ');
};

const collator = new Intl.Collator(undefined, { ignorePunctuation: true, usage: 'search', sensitivity: 'base' });
export const isValidCountry = (s: string): boolean => countryCodes[s] != null;
export const parseCountry = (s: string): string => {
  for (const [key, value] of Object.entries(countryNames)) {
    if (collator.compare(s, key) === 0) {
      return value;
    }
  }
  return s;
};

const validAmountRE = /^[0-9]+(\.[0-9]{2})?$/;
export const isValidAmount = (s: string): boolean => s.match(validAmountRE) != null;

export const isValidCurrency = (s: string): boolean => currencyCodes[s] != null;
export const parseCurrency = (s: string): string => {
  for (const [key, value] of Object.entries(currencyNames)) {
    if (collator.compare(s, key) === 0) {
      return value;
    }
  }
  return s;
};

const validPercentRE = /^[0-9]{0,3}(\.[0-9]{1,2})?$/;
export const isValidPercent = (s: string): boolean => {
  if (s.match(validPercentRE) != null) {
    const v = parseFloat(s);
    return v >= 0 && v <= 100;
  }
  return false;
};

const forbiddenInPercent = /[^0-9.]/;
export const parsePercent = (s: string): string => {
  const str = s.replace(',', '.').replace(forbiddenInPercent, '');
  const splitted = str.split('.');
  if (splitted.length === 1) {
    return splitted[0];
  } else if (splitted.length === 2) {
    let zeros = 0;
    const decimalPart = splitted[1];
    for (let i = 0; i < decimalPart.length; i++) {
      if (decimalPart[decimalPart.length - 1 - i] !== '0') {
        break;
      }
      zeros++;
    }
    const numNotZeros = decimalPart.length - zeros;
    if (zeros === decimalPart.length) {
      return splitted[0];
    } else {
      const decimalPartNoZeros = decimalPart.substr(0, numNotZeros);
      return splitted[0] + '.' + decimalPartNoZeros;
    }
  } else {
    return s;
  }
};

const zipcodeRE = /^[0-9]+$/;
const forbiddenInZipcodeRE = /[^0-9]/g;

export const isValidZipcode = (s: string): boolean => s.match(zipcodeRE) != null;
export const parseZipcode = (s: string): string => s.replace(forbiddenInZipcodeRE, '');

const CATEGORY_CHOICES = [
  InvoiceCategory.NO_CATEGORY,
  InvoiceCategory.PRODUCT,
  InvoiceCategory.SERVICE,
  InvoiceCategory.GASOLINE
];

const RECTIFICATION_CHOICES = [InvoiceRectification.NO, InvoiceRectification.REPLACEMENT];

const INVOICETYPE_CHOICES = [
  InvoiceType.NORMAL,
  InvoiceType.ISP,
  // InvoiceType.CAJA,
  // InvoiceType.TRANSITARIO,
];

const isValidChoice = (choices: string[]) => (v: string) => choices.includes(v);
const parseChoice = (choices: string[]) => (v: string) => choices[0];

export const invoiceFields: Field[] = [
  {
    id: 'category', name: 'Categoria', choices: CATEGORY_CHOICES,
    isValid: isValidChoice(CATEGORY_CHOICES), parse: parseChoice(CATEGORY_CHOICES)
  },
  {
    id: 'rectification', name: 'Rectificativa', choices: RECTIFICATION_CHOICES,
    isValid: isValidChoice(RECTIFICATION_CHOICES), parse: parseChoice(RECTIFICATION_CHOICES)
  },
  {
    id: 'invoiceType', name: 'Tipo', choices: INVOICETYPE_CHOICES,
    isValid: isValidChoice(INVOICETYPE_CHOICES), parse: parseChoice(INVOICETYPE_CHOICES)
  },

  { id: 'number', name: 'Núm. Factura', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'senderId', name: 'NIF emisor', isValid: isValidNIF, parse: parseNIF, header: 'Emisor' },
  { id: 'senderName', name: 'Nombre emisor', isValid: isValidName, parse: parseName },
  { id: 'senderCountry', name: 'País emisor', isValid: isValidCountry, parse: parseCountry },
  { id: 'senderZipcode', name: 'Código postal emisor', isValid: isValidZipcode, parse: parseZipcode },

  { id: 'receiverId', name: 'NIF receptor', isValid: isValidNIF, parse: parseNIF, header: 'Receptor' },
  { id: 'receiverName', name: 'Nombre receptor', isValid: isValidName, parse: parseName },
  { id: 'receiverCountry', name: 'País receptor', isValid: isValidCountry, parse: parseCountry },
  { id: 'receiverZipcode', name: 'Código postal receptor', isValid: isValidZipcode, parse: parseZipcode },

  { id: 'grossTotal', name: 'Total sin impuestos', isValid: isValidAmount, parse: parseAmount, header: 'Totales' },
  { id: 'netTotal', name: 'Total con impuestos', isValid: isValidAmount, parse: parseAmount },
  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency },

  { id: 'iva21Amount', name: 'Cuota IVA 21%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva10Amount', name: 'Cuota IVA 10%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva4Amount', name: 'Cuota IVA 4%', isValid: isValidAmount, parse: parseAmount },

  // { id: 'ivaForeign', name: 'Cuota IVA Extranjero' },

  { id: 'irpfAmount', name: 'Retención IRPF', isValid: isValidAmount, parse: parseAmount },
  { id: 'irpfPercent', name: 'Retención IRPF porcentaje', isValid: isValidPercent, parse: parsePercent },
];