import { Confirm } from './lib/utilBrowser';
import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import SettingsIcon from '@material-ui/icons/Settings';
import BookIcon from '@material-ui/icons/Book';
import PeopleIcon from '@material-ui/icons/People';
import CompaniesIcon from '@material-ui/icons/Domain';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HowToVoteIcon from '@material-ui/icons/HowToVote';

import Divider from '@material-ui/core/Divider';
import { logout } from './auth';
import { Route, Link } from 'react-router-dom';
import { List } from '@material-ui/core';
import { UserProfile } from './gen/globalTypes';

interface PropsType {
  icon: JSX.Element;
  title: string;
  route: string;
  exact?: boolean;
}
class MenuItemExt extends React.Component<PropsType, {}> {
  render() {
    return (
      <Route
        path={this.props.route}
        exact={this.props.exact}
        children={({ match, history }) => (
          // @ts-ignore
          <ListItem button selected={!!match} to={this.props.route} component={Link}>
            <ListItemIcon>
              {this.props.icon}
            </ListItemIcon>
            <ListItemText primary={this.props.title} />
          </ListItem>
        )}
      />
    );
  }
}

const LogoutOption = () => {
  return (
    <ListItem
      button
      onClick={async function () {
        if (await Confirm({ body: 'Vas a salir del programa. ¿Continuar?' })) {
          logout();
        }
      }}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Salir" />
    </ListItem>
  );
};

const DocumentationOption = () => {
  return (
    <ListItem button onClick={() => window.open('http://api.abaq.app/docs/', '_blank')}>
      <ListItemIcon>
        <LibraryBooksIcon />
      </ListItemIcon>
      <ListItemText primary="Documentación" />
    </ListItem>
  );
};

export class Sidebar extends React.Component<{ profile: UserProfile | null }> {
  render = () => {
    if (this.props.profile === UserProfile.ADVISOR) {
      return (
        <List style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <MenuItemExt icon={<AllInboxIcon />} title="Todas las tareas" route="/" exact />
          <MenuItemExt icon={<PhotoLibraryIcon />} title="Extracción" route="/extractions" exact />
          <MenuItemExt icon={<PeopleIcon />} title="Usuarios" route="/user" exact />
          <MenuItemExt icon={<CompaniesIcon />} title="Empresas" route="/company" exact />
          <Divider />
          <Route
            path="/company/:id"
            render={({ match }) => {
              const id = match.params.id;
              return (
                <React.Fragment >
                  <ListSubheader inset>Empresa</ListSubheader>
                  <MenuItemExt
                    icon={<DashboardIcon />}
                    title="Dashboard"
                    route={`/company/${id}`}
                    exact
                  />
                  <MenuItemExt
                    icon={<BookIcon />}
                    title="Documentos"
                    route={`/company/${id}/documents`}
                  />
                  <MenuItemExt
                    icon={<AssignmentIcon />}
                    title="Informes"
                    route={`/company/${id}/reports`}
                  />
                  <MenuItemExt
                    icon={<SettingsIcon />}
                    title="Configuración"
                    route={`/company/${id}/settings`}
                  />
                  <MenuItemExt
                    icon={<HowToVoteIcon />}
                    title="Trámites"
                    route={`/company/${id}/paperwork`}
                  />
                </React.Fragment>
              );
            }}
          />
          <Divider />
          <div style={{ flex: '1' }} />
          <Divider />
          <DocumentationOption />
          <Divider />
          <LogoutOption />
        </List>
      );
    } else {
      return (
        <List style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <MenuItemExt icon={<HomeIcon />} title="Inicio" route="/" exact />
          <div style={{ flex: '1' }} />
          <LogoutOption />
        </List>
      );
    }
  }
}