import * as React from 'react';
import { createStyles, WithStyles, withStyles, Button } from '@material-ui/core';
import { MutationFn, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { notificationsClient } from '../notifications';
import { onGenericError } from '../lib/errorReporter';
import { ApolloError } from 'apollo-client';
import { QUpload, QUploadVariables } from '../gen/QUpload';

const UPLOAD_MUTATION = gql`
mutation QUpload($company: ID! $file: Upload!) {
  upload(company: $company, file: $file) {
    id
  }
}
`;

class UploadMutation extends Mutation<QUpload, QUploadVariables> { }

interface UploadEvidencesProps extends WithStyles<typeof styles> {
  company: string;
}

interface UploadEvidencesState {
  loading: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(2),
    }
  });

class UploadImageInternal extends React.Component<UploadEvidencesProps, UploadEvidencesState> {
  private input?: HTMLInputElement;
  state = {
    loading: false
  } as UploadEvidencesState;

  uploadFiles = async (uploadFn: MutationFn<QUpload, QUploadVariables>, company: string, files: FileList) => {
    const uploaded: File[] = [];
    this.setState({ loading: true });
    for await (const file of Array.from(files)) {
      await uploadFn({ variables: { company, file } })
        .then((_) => {
          uploaded.push(file);
        })
        .catch((e: ApolloError) => {
          onGenericError(new Error(e.message + ` (Archivo: ${file.name})`));
        });
    }
    if (uploaded.length === 1) {
      notificationsClient.addMessage('Archivo subido y procesado correctamente: ' + uploaded[0].name);
    } else if (uploaded.length > 1) {
      notificationsClient.addMessage('Se han subido y procesado ' + uploaded.length + ' archivos correctamente');
    }
    this.setState({ loading: false });
    if (this.input) {
      this.input.value = '';
    }
  }
  assignInputRef = (input: HTMLInputElement) => {
    this.input = input;
  }
  render = () => {
    return (
      <UploadMutation
        mutation={UPLOAD_MUTATION}
      // we do not use onError here to allow the upload handler to receive the error
      >
        {(uploadFile) => {
          return (
            <Button
              variant="contained"
              component="label"
              disabled={this.state.loading}
              className={this.props.classes.button}
            >
              {this.state.loading ? 'Subiendo' : 'Subir archivo'}
              <input
                multiple={true}
                ref={this.assignInputRef}
                type="file"
                accept="application/pdf,image/*"
                required={true}
                disabled={this.state.loading}
                style={{ display: 'none' }}
                onChange={({ target: { validity, files } }) => {
                  if (!files || files.length === 0 || !validity.valid) {
                    return;
                  }
                  this.uploadFiles(
                    uploadFile,
                    this.props.company,
                    files as FileList,
                  );
                }}
              />

            </Button>
          );
        }}
      </UploadMutation>

    );
  }
}
export const UploadImage = withStyles(styles)(UploadImageInternal);