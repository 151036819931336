import * as React from 'react';
import {
  createStyles, WithStyles, withStyles, Dialog, AppBar, Button, Typography, Toolbar,
  DialogContent, Grid, TextField, DialogActions
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { RouteComponentProps, Link } from 'react-router-dom';
import { formatUserProfile } from '../lib/util';
import { Confirm } from '../lib/utilBrowser';
import gql from 'graphql-tag';
import { QUser, QUserVariables, QUser_user_companies_items, QUser_user } from '../gen/QUser';
import { Query, FetchResult } from 'react-apollo';
import { Loading } from '../widgets/loading';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { Pagination } from '../gen/globalTypes';
import CompaniesList from '../widgets/companiesList';
import { QCompaniesQuery_companies_items } from '../gen/QCompaniesQuery';
import { client as apolloClient, resetClientAuth } from '../client';
import { MUpdateCompanyUserRelationVariables, MUpdateCompanyUserRelation } from '../gen/MUpdateCompanyUserRelation';
import { ApolloError } from 'apollo-client';
import { onMutationError } from '../lib/errorReporter';
import MaterialTable from 'material-table';
import { tableIcons } from '../lib/tableIcons';
import MUILink from '@material-ui/core/Link';

class UserQuery extends Query<QUser, QUserVariables> { }

const QUERY = gql`
  query QUser($id: ID!, $companiesPagination: Pagination) {
    user(id: $id) {
      id
      profile
      name
      email
      phone
      companies(pagination: $companiesPagination) {
        totalCount
        items {
          id
          name
          taxId
          status
          paymentStatus
        }
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
      display: 'flex',
    },
    wrapper: {
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(10),
    },
    flex: {
      flex: 1,
    },
    userData: {
      marginBottom: theme.spacing(4),
    },
    userActions: {
      paddingLeft: 0,
      marginBottom: theme.spacing(2),
    }
  });

interface UserPageProps extends
  RouteComponentProps<{
    userId: string;
  }>,
  WithStyles<typeof styles> { }

interface UserPageState {
  companiesPagination: Pagination;
}

function getQueryVariables(props: UserPageProps, state: UserPageState): QUserVariables {
  return {
    id: props.match.params.userId,
    companiesPagination: state.companiesPagination
  };
}

const UPDATE_COMPANY_USER_RELATION_MUTATION = gql`
  mutation MUpdateCompanyUserRelation(
    $company: ID!
    $user: ID!
    $value: Boolean!
  ) {
    result: updateCompanyUserRelation(
      company: $company
      user: $user
      value: $value
    ) 
  }
`;

async function updateUserToCompanyRelation(company: string, user: string, value: boolean): Promise<boolean> {
  return await apolloClient.mutate<MUpdateCompanyUserRelation, MUpdateCompanyUserRelationVariables>({
    mutation: UPDATE_COMPANY_USER_RELATION_MUTATION,
    variables: { company, user, value }
  }).then((result: FetchResult<MUpdateCompanyUserRelation>) => {
    if (result.data) {
      return true;
    }
    return false;
  }).catch((error: ApolloError) => {
    onMutationError(error);
    return false;
  });
}

class UserPage extends React.Component<UserPageProps, UserPageState> {
  constructor(props: UserPageProps) {
    super(props);
    this.state = {
      companiesPagination: { limit: 500, offset: 0 },
    };
  }
  handleClose = async () => {
    this.props.history.goBack();
  }
  render = () => {
    const classes = this.props.classes;
    return (
      <Dialog
        fullScreen
        open={true}
        onClose={this.handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <UserQuery
          query={QUERY}
          variables={getQueryVariables(this.props, this.state)}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (<Loading />);
            }
            if (error || !data) {
              return (<div>Error {JSON.stringify(error) || 'no data'}</div>);
            }
            if (data.user == null) {
              return (<div>No existe el usuario</div>);
            }

            return (
              <UserData
                user={data.user}
                classes={classes}
                companiesPagination={this.state.companiesPagination}
                onClose={this.handleClose}
                onPaginationChange={(companiesPagination: Pagination) => {
                  this.setState({ companiesPagination });
                }}
              />
            );
          }}
        </UserQuery>
      </Dialog >
    );
  }
}

export default withStyles(styles)(UserPage);

interface UserDataProps extends WithStyles<typeof styles> {
  user: QUser_user;
  companiesPagination: Pagination;
  onClose: () => void;
  onPaginationChange: (pagination: Pagination) => void;
}

interface UserDataState {
  selectCompanyOpen: boolean;
}

class UserData extends React.Component<UserDataProps, UserDataState> {
  constructor(props: UserDataProps) {
    super(props);
    this.state = {
      selectCompanyOpen: false
    };
  }
  handleAddCompany = async (company: QCompaniesQuery_companies_items) => {
    const continueWithAdd = await Confirm({
      title: 'Conceder acceso a empresa',
      body: 'Se concederá acceso al usuario a la empresa: "' + company.name + '". ¿Quieres continuar?'
    });
    if (continueWithAdd) {
      await updateUserToCompanyRelation(company.id, this.props.user.id, true);
      resetClientAuth();
      this.setState({ selectCompanyOpen: false });
    }
  }
  handleRemoveCompanies = async (companyIds: string[]) => {
    for await (const companyId of companyIds) {
      await updateUserToCompanyRelation(companyId, this.props.user.id, false);
    }
    resetClientAuth();
  }
  render = () => {
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <AppBar>
          <Toolbar>
            <Button size="small" color="secondary" onClick={this.props.onClose}>
              <ArrowBackIcon />
            </Button>
            <Typography variant="h6" color="inherit" className={classes.flex}>Abaq</Typography>
            <Button size="small" color="secondary" onClick={this.props.onClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent className={classNames(classes.wrapper)}>
          <Typography variant="h6">Datos del usuario</Typography>
          <Grid container={true} spacing={8} className={classes.userData}>
            <Grid item={true} xs={6}>
              <TextField
                label="Nombre"
                value={this.props.user.name || ''}
                fullWidth={true}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Correo electrónico"
                value={this.props.user.email || ''}
                fullWidth={true}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Teléfono"
                value={this.props.user.phone || ''}
                fullWidth={true}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Perfil"
                value={formatUserProfile(this.props.user.profile)}
                fullWidth={true}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Toolbar className={classes.userActions}>
            <Button
              variant="contained"
              onClick={() => { this.setState({ selectCompanyOpen: true }); }}
            >
              Dar acceso a otra empresa
            </Button>
          </Toolbar>
          <MaterialTable
            icons={tableIcons}
            title="Empresas a las que tiene acceso"
            columns={[
              {
                title: 'Nombre',
                field: 'name',
                filtering: false,
                sorting: false,
                render: (rowData) => (
                  <div>
                    <MUILink component={Link} color="inherit" to={`/company/${rowData.id}`}>{rowData.name}</MUILink>
                  </div>
                )
              },
              { title: 'NIF', field: 'taxId', filtering: false, sorting: false },
              { title: 'Estado', field: 'status', filtering: false, sorting: false },
              { title: 'Estado suscripción', field: 'paymentStatus', filtering: false, sorting: false },
            ]}
            data={this.props.user ? this.props.user.companies.items : []}
            options={{
              pageSize: 500,
              pageSizeOptions: [100, 200, 500],
              filtering: false,
              emptyRowsWhenPaging: false,
              search: false,
              selection: true,
              padding: 'dense'
            }}
            actions={[
              {
                tooltip: 'Eliminar acceso a las empresas seleccionadas',
                icon: () => <DeleteIcon />,
                onClick: async (evt, data) => {
                  const companies = data as QUser_user_companies_items[];
                  const names = companies.map((value) => '"' + value.name + '"');
                  const continueWithRemove = await Confirm({
                    title: 'Eliminar acceso a empresas',
                    body: 'Se eliminará el acceso del usuario a las siguientes empresas: ' + names.join(', ') + '. ¿Quieres continuar?'
                  });
                  if (continueWithRemove) {
                    this.handleRemoveCompanies(companies.map((c) => c.id));
                  }
                }
              }
            ]}
          />
        </DialogContent>
        {
          this.state.selectCompanyOpen ? (
            <Dialog
              open={true}
              onClose={() => { this.setState({ selectCompanyOpen: false }); }}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogContent>
                <CompaniesList
                  title="Selecciona una empresa para dar acceso al usuario a la misma"
                  onCompanyRowClick={(company: QCompaniesQuery_companies_items) => {
                    this.handleAddCompany(company);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { this.setState({ selectCompanyOpen: false }); }}>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          ) : null
        }
      </React.Fragment>
    );
  }
}