import { AnnotateImageResponse as Annotate } from '../../lib/textAnnotation';
import { AnnotateImageResponse, TextAnnotation } from '../../lib/gvision';
import {
  Link as MUILink, Button, InputAdornment, FormControl, RadioGroup, FormControlLabel,
  Radio, Checkbox, CardHeader, CardContent, Card, Typography, MenuItem
} from '@material-ui/core';
import { Loading } from '../../widgets/loading';
import { VisionViewer } from './visionViewer';
import * as React from 'react';
import Pbf from 'pbf';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import TextField from '@material-ui/core/TextField';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';

import { Extraction, ExtractionType, ExtractionValue, InvoiceExtraction } from '../../gen/globalTypes';
import { onGenericError } from '../../lib/errorReporter';
import { invoiceFields, Field } from './lib/fields';
import { formatDateTime, formatDate } from '../../lib/util';
import { Link } from 'react-router-dom';

interface Props {
  imageUrl: string;
  visionUrl: string;
  initialValue: Extraction;
  lockDate?: string | null;
  lockedBy?: string | null;
  editor: boolean;
  transcriptorMode?: boolean;
  companyId: string;
  companyName: string | null;
  taxId: string | null;
  close: () => void;
  onSave: (extraction: Extraction, lockNewExtraction: boolean) => void;
  onSelected?: (text: string) => void;
}

interface State {
  data?: TextAnnotation;
  extraction: Extraction;
  rotation: number;
  zoom: boolean;
  currentField: Field | null;
  currentValue: ExtractionValue | null;
  fields: Field[];
  values: ExtractionValue[];
  lockNewOnResolve: boolean;
}

export class ExtractionEditor extends React.Component<Props, State> {
  state: State = {
    data: undefined,
    rotation: 0,
    zoom: false,
    extraction: { extractionType: ExtractionType.UNKNOWN },
    currentField: null,
    currentValue: null,
    fields: [],
    values: [],
    lockNewOnResolve: this.props.transcriptorMode ? true : false,
  };
  fieldsRef: React.RefObject<ExtractionFields>;
  initialMessage: string | null;
  constructor(props: Props) {
    super(props);
    this.fieldsRef = React.createRef();
    const extraction: Extraction = JSON.parse(JSON.stringify(this.props.initialValue)); // Copy
    this.initialMessage = this.props.initialValue.message || null;
    if (this.initialMessage && this.props.transcriptorMode) {
      extraction.message = null; // Clear message when visible on top
    }
    this.state.extraction = extraction;
    if (this.state.extraction.extractionType === ExtractionType.INVOICE) {
      const firstNonChoiceField = invoiceFields.findIndex((f) => f.choices == null);
      this.state.currentField = invoiceFields[firstNonChoiceField];
      this.state.values = invoiceFields.map((f) => this.state.extraction.invoice![f.id]);
      this.state.fields = invoiceFields;
      this.state.currentValue = this.state.values[firstNonChoiceField];
    }
  }
  componentDidMount = async () => {
    try {
      if (this.props.editor) {
        document.addEventListener('keydown', this.onKeyDown, false);
      }
      this.setState({ data: await loadVision(this.props.visionUrl) });
    } catch (e) {
      onGenericError(e);
    }
  }
  componentWillUnmount = () => {
    if (this.props.editor) {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }
  }
  onSelected = (v: { selected: number[], raw: string }, addToSelection: boolean) => {
    if (this.props.onSelected) {
      this.props.onSelected(v.raw);
    }
    if (!this.props.editor) { return; }
    const value = this.state.currentValue;
    if (value == null) { return; }
    if (v.selected.length === 0) {
      value.raw = '';
      value.page = 0;
      value.positions = [];
      value.value = null;
    } else {
      if (addToSelection) {
        value.raw = value.raw + ' ' + v.raw;
        value.page = 0;
        value.positions.push(...v.selected);
        value.value = this.state.currentField!.parse(value.raw);
      } else {
        value.raw = v.raw;
        value.page = 0;
        value.positions = v.selected;
        value.value = this.state.currentField!.parse(v.raw);
      }
    }
    this.setState({ extraction: this.state.extraction }, () => {
      this.fieldsRef.current!.focus();
    });
  }
  onChange = (field: Field, v: ExtractionValue) => {
    if (v.value === '') {
      v.value = null;
      v.raw = '';
      v.positions = [];
    }
    v.extracted = true;
    this.setState({ extraction: this.state.extraction, currentField: field, currentValue: v });
  }
  onFocus = (field: Field, v: ExtractionValue) => {
    if (this.state.currentValue !== v) {
      if (this.state.currentValue != null) {
        this.state.currentValue.extracted = true;
      }
      this.setState({ extraction: this.state.extraction, currentField: field, currentValue: v });
    }
  }
  onFocusButton = () => {
    if (this.state.currentValue != null) {
      this.state.currentValue.extracted = true;
    }
    this.setState({ extraction: this.state.extraction, currentValue: this.state.currentValue });
  }
  nextField = (dir?: number) => {
    const fields = this.state.fields;
    if (fields.length === 0) { return; }
    const currentIndex = fields.findIndex((f) => f === this.state.currentField);
    if (this.state.currentValue != null) {
      this.state.currentValue.extracted = true;
    }
    if (currentIndex === fields.length - 1 && !this.hasErrors()) {
      this.setState({ extraction: this.state.extraction });
      this.props.onSave(this.state.extraction, this.state.lockNewOnResolve);
      return;
    }
    const nextIndex = mod(currentIndex + (dir || 1), fields.length);
    const nextField = fields[nextIndex];
    const nextValue = this.state.values[nextIndex];
    this.setState(
      { extraction: this.state.extraction, currentField: nextField, currentValue: nextValue },
      () => { this.fieldsRef.current!.focus(); }
    );
  }
  nextRotation = (dir: number) => {
    const rots = [0, 90, 180, 270];
    const currentIndex = rots.indexOf(this.state.rotation);
    const nextIndex = mod(currentIndex + dir, rots.length);
    this.setState({ rotation: rots[nextIndex] });
  }
  nextZoom = () => {
    this.setState((prev) => { return { zoom: !prev.zoom }; });
  }
  onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 40) { this.nextField(); }
    if (e.keyCode === 38) { this.nextField(-1); }
  }
  changeType = (e: ExtractionType) => {
    if (e === ExtractionType.INVOICE) {
      const fields = invoiceFields;
      const invoice = {};
      const values = [];
      const emptyValue = (f: Field): ExtractionValue => (
        {
          extracted: false,
          raw: '',
          page: 0,
          positions: [],
          value: f.choices ? f.choices[0] : undefined,
        }
      );
      for (const f of fields) {
        const v = emptyValue(f);
        invoice[f.id] = v;
        values.push(v);
      }
      const extraction: Extraction = {
        extractionType: ExtractionType.INVOICE,
        invoice: invoice as InvoiceExtraction,
        receivedDate: this.state.extraction.receivedDate
      };
      this.setState({ extraction, fields, values, currentField: fields[0], currentValue: values[0] });
    } else {
      const extraction: Extraction = {
        extractionType: e,
        receivedDate: this.state.extraction.receivedDate
      };
      if (e === ExtractionType.UNKNOWN || e === ExtractionType.REVIEW) {
        extraction.message = this.state.extraction.message;
      }
      this.setState({ extraction, fields: [], values: [], currentField: null, currentValue: null });
    }
  }
  hasErrors = () => {
    return this.state.values.some((v, i) => {
      const field = this.state.fields[i];
      return v.extracted && v.value != null && !field.isValid(v.value);
    });
  }
  render = () => {
    let highlighted: number[] = [];
    let canSave = true;
    const hasError = this.state.values.map((v, i) => {
      highlighted = highlighted.concat(v.positions);
      const field = this.state.fields[i];
      const err = v.extracted && v.value != null && !field.isValid(v.value);
      canSave = canSave && !err;
      return err;
    });
    if (canSave && this.props.transcriptorMode && this.state.extraction.extractionType === ExtractionType.UNKNOWN) {
      canSave = false; // Los transcriptores no pueden intentar guardar sin clasificar (les volvería a salir)
    }

    const showMessageInput =
      (this.state.extraction.extractionType === ExtractionType.REVIEW) ||
      (!this.props.transcriptorMode && this.state.extraction.extractionType === ExtractionType.UNKNOWN);
    return (
      <div style={{ display: 'flex', flex: '1', flexDirection: 'row', height: '100%' }}>
        {this.props.editor ?
          <div style={{ flex: '0.1 0 200px', overflow: 'auto', padding: '8px' }}>
            <Typography variant="subtitle2">
              {
                this.props.transcriptorMode ? (
                  <div>{this.props.companyName} {this.props.taxId}</div>
                ) : (
                    <MUILink component={Link} color="inherit" to={`/company/${this.props.companyId}`}>
                      {this.props.companyName} {this.props.taxId}
                    </MUILink>
                  )
              }
            </Typography>
            <Typography variant="caption" paragraph>
              Fecha de recepción:&nbsp;
              {
                this.state.extraction.receivedDate ?
                  formatDate(this.state.extraction.receivedDate) :
                  'No especificada'
              }
            </Typography>
            {
              this.initialMessage && this.props.transcriptorMode ? (
                <Card>
                  <CardHeader title="Aviso" />
                  <CardContent>
                    <Typography variant="body1">
                      {this.initialMessage}
                    </Typography>
                  </CardContent>
                </Card>
              ) : null
            }
            {
              (!this.props.transcriptorMode && this.props.lockedBy && this.props.lockDate) ? (
                <Card>
                  <CardHeader title="Documento bloqueado" />
                  <CardContent>
                    <Typography variant="body1">
                      Transcriptor: {this.props.lockedBy}
                      <br />
                      Fecha de bloqueo: {formatDateTime(this.props.lockDate)}
                    </Typography>
                  </CardContent>
                </Card>

              ) : null
            }
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Tipo"
                name="tipo"
                value={this.state.extraction.extractionType}
                onChange={(e, v) => this.changeType(v as ExtractionType)}
              >
                <FormControlLabel value={ExtractionType.INVOICE} control={<Radio />} label="Factura" />
                <FormControlLabel value={ExtractionType.IRRELEVANT} control={<Radio />} label="Irrelevante" />
                <FormControlLabel value={ExtractionType.UNCLEAR} control={<Radio />} label="Ilegible" />
                {
                  !this.props.transcriptorMode ? (
                    <FormControlLabel value={ExtractionType.UNKNOWN} control={<Radio />} label="Sin clasificar" />
                  ) : null
                }
                <FormControlLabel value={ExtractionType.REVIEW} control={<Radio />} label="Revisión" />
              </RadioGroup>
            </FormControl>
            {this.state.currentField ?
              <ExtractionFields
                ref={this.fieldsRef}
                values={this.state.values}
                hasError={hasError}
                fields={this.state.fields}
                currentField={this.state.currentField}
                onChange={this.onChange}
                onFocus={this.onFocus}
              /> : null
            }
            {
              showMessageInput ?
                (
                  <TextField
                    label="Motivo"
                    value={this.state.extraction.message || ''}
                    helperText="Indica brevemente el motivo por el solicitas la revisión del documento"
                    onChange={(e) => {
                      this.state.extraction.message = e.target.value;
                      this.setState({ extraction: this.state.extraction });
                    }}
                    multiline={true}
                    rowsMax={4}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    fullWidth
                  />
                ) : null
            }
            <Button
              onClick={() => {
                this.props.onSave(this.state.extraction, this.state.lockNewOnResolve);
              }}
              disabled={!canSave}
              onFocus={this.onFocusButton}
              variant="contained"
              size="large"
              color="primary"
              style={{ marginTop: 24 }}
              fullWidth
            >
              Resolver
            </Button>
            {
              this.props.transcriptorMode ? (
                <FormControlLabel
                  value={ExtractionType.REVIEW}
                  control={
                    <Checkbox
                      checked={this.state.lockNewOnResolve}
                      onChange={(e) => { this.setState({ lockNewOnResolve: e.target.checked }); }}
                    />
                  }
                  label="Abrir otro al resolver"
                />
              ) : null
            }
            <Button
              onClick={() => this.props.close()}
              variant="contained"
              size="large"
              color="default"
              style={{ marginTop: 24 }}
              fullWidth
            >
              Salir
            </Button>

          </div> : null}
        <div
          style={{
            flex: '1', display: 'flex', flexDirection: 'column',
            position: 'relative', // backgroundColor: 'rgba(0,0,0,0.09)'
          }}
        >
          <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              size="small"
              onClick={() => this.nextRotation(-1)}
            >
              <RotateLeftIcon />
            </Button>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              size="small"
              onClick={() => this.nextRotation(1)}
            >
              <RotateRightIcon />
            </Button>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              size="small"
              onClick={() => this.nextZoom()}
            >
              <ZoomOutMapIcon />
            </Button>
          </div>
          <div style={{ flex: '1 0', overflow: 'auto', paddingBottom: 40 }}>
            {this.state.data ?
              <VisionViewer
                data={this.state.data}
                image={this.props.imageUrl}
                selected={(this.state.currentValue && this.state.currentValue.positions) || []}
                highlighted={highlighted}
                onSelected={this.onSelected}
                rotation={this.state.rotation}
                zoom={this.state.zoom}
                onNext={() => this.nextField()}
              /> : <Loading />
            }
          </div>
        </div>
      </div >);
  }
}

interface ExtractionFieldsProps {
  values: ExtractionValue[];
  hasError: boolean[];
  currentField: Field;
  fields: Field[];
  onChange: (field: Field, v: ExtractionValue) => void;
  onFocus: (field: Field, v: ExtractionValue) => void;
}
class ExtractionFields extends React.Component<ExtractionFieldsProps> {
  inputs = {};
  focus() {
    this.inputs[this.props.currentField.id].focus();
  }
  render = () => {
    return (
      <form style={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        {this.props.fields.map((field, i) => {
          const value = this.props.values[i];
          return (
            <React.Fragment key={field.id}>
              {field.header ? <Typography variant="subtitle2">{field.header}</Typography> : undefined}
              <TextField
                inputRef={(el) => this.inputs[field.id] = el}
                key={field.id}
                select={field.choices != null}
                id={field.id}
                label={field.name}
                value={(value.value) || ''}
                error={this.props.hasError[i]}
                helperText={value.raw || ' '}
                onFocus={(e) => { this.props.onFocus(field, value); }}
                onChange={(e) => { value.value = e.target.value; this.props.onChange(field, value); }}
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: this.props.currentField === field ? { backgroundColor: 'rgba(200,200,255,0.5)' } : undefined,
                  endAdornment: !value.extracted ?
                    <InputAdornment position="end">< VisibilityIcon /></InputAdornment>
                    : <InputAdornment position="end">< CheckIcon /></InputAdornment>
                  ,
                }}
                variant="filled"
                autoComplete="false"
                autoCapitalize="false"
                autoCorrect="false"
                fullWidth
              >

                {
                  field.choices != null
                    ?
                    (field.choices.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>)))
                    : undefined
                }
              </TextField>
            </React.Fragment>
          );
        })}
      </form>
    );
  }
}

function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

async function loadVision(url: string): Promise<TextAnnotation> {
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error('Status code downloading vision: ' + response.status);
  }
  const buffer = await response.arrayBuffer();
  const bytes = new Uint8Array(buffer);
  const a = Annotate.read(new Pbf(bytes)) as AnnotateImageResponse;
  if (!a.full_text_annotation) {
    return { pages: [], text: '' };
  } else {
    return a.full_text_annotation;
  }
}