import * as React from 'react';
import { createStyles, WithStyles, withStyles, Card, Grid, Typography, Button } from '@material-ui/core';
import { Query, Mutation, MutationFn, FetchResult } from 'react-apollo';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withRouter, RouteComponentProps } from 'react-router';
import { Loading } from '../widgets/loading';
import { QHomeTranscriptor } from '../gen/QHomeTranscriptor';
import { MStartExtraction } from '../gen/MStartExtraction';
import { onMutationError } from '../lib/errorReporter';
import { Alert } from '../lib/utilBrowser';

// TODO: See this to refresh count https://www.apollographql.com/docs/react/advanced/caching#refetchQueries
// https://www.apollographql.com/docs/react/essentials/queries#refetching
const HOME_TRANSCRIPTOR_QUERY = gql`
  query QHomeTranscriptor {
    totalPendingDocuments {
      locked
      unlocked
    }
    reservedExtractions {
      id
    }
  }
`;

const START_TRANSACTION_MUTATION = gql`
  mutation MStartExtraction {
    startExtraction {
      id
    }
  }
`;

class PendingDocumentsQuery extends Query<QHomeTranscriptor, {}> { }
class StartTransationMutation extends Mutation<MStartExtraction, {}> { }

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2)
    }
  });

class TranscriptorHome extends React.Component<RouteComponentProps & WithStyles<typeof styles>> {
  enterToExtractions = async (mutationFn: MutationFn<MStartExtraction, {}>) => {
    const result = await mutationFn({ variables: {} }) as FetchResult<MStartExtraction>;
    if (result.data && result.data.startExtraction) {
      this.props.history.push(`/extractions/${result.data.startExtraction.id}`);
    } else {
      Alert({ title: '', body: 'No existen documentos pendientes de extraer' });
    }
  }
  componentWillUnmount = () => {
    document.title = 'abaq';
  }
  render = () => (
    <PendingDocumentsQuery
      query={HOME_TRANSCRIPTOR_QUERY}
      onCompleted={(data: QHomeTranscriptor) => {
        if (data.reservedExtractions.length > 0) {
          this.props.history.push(`/extractions/${data.reservedExtractions[0].id}`);
        }
      }}
    >
      {({ loading, error, data }) => {
        const classes = this.props.classes;
        if (loading) { return <Loading />; }
        if (error) { return <p>Error :( {error.message}</p>; }
        const pendingDocs = data ? data.totalPendingDocuments.unlocked : 0;
        document.title = `abaq - ${pendingDocs}`;
        return (
          <Grid container>
            <Grid item xs={12} md={4}>
              <Card className={classes.card}>
                <Typography variant="subtitle1">
                  {pendingDocs} documentos pendientes
                </Typography>
                <StartTransationMutation
                  mutation={START_TRANSACTION_MUTATION}
                  onError={(e) => onMutationError(e)}
                >
                  {(mutationFn, { loading: loadingMutation }) => (
                    <Button
                      variant="contained"
                      onClick={() => { this.enterToExtractions(mutationFn); }}
                      disabled={loadingMutation || (data ? (data.totalPendingDocuments.unlocked === 0) : true)}
                    >
                      Entrar
                    </Button>
                  )}
                </StartTransationMutation>
              </Card>
            </Grid>
          </Grid>
        );
      }}
    </PendingDocumentsQuery>
  )
}
export default withStyles(styles)(withRouter(TranscriptorHome));