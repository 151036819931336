import * as React from 'react';
import {
  createStyles, WithStyles, withStyles
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Loading } from '../widgets/loading';
import { QDocsReview, QDocsReviewVariables } from '../gen/QDocsReview';

const DOCSREVIEW_QUERY = gql`
query QDocsReview($company: ID!) {
  company(id: $company) {
    id,
    name, 
    docs(
      deleted: false, 
      type: IMAGE,
      status: ACCOUNTED,
      order: CREATION_DATE,
      orderDirection: ASC,
      pagination: {limit: 1000, offset: 0}
    ) {
      totalCount,
      items {
        id,
        accounting,
        imagePages {
          imageUrl
        }
      }
    }
  }
}
`;

const styles = (theme: Theme) =>
  createStyles({
    acc: {
      overflow: 'hidden',
      fontSize: '14px', // Fix monospace font-size issue
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      display: 'block',
      maxHeight: '256px',
      textOverflow: 'ellipsis',
      marginTop: '1em',
    },
    date: {
      whiteSpace: 'nowrap'
    },
    toolbar: {
      paddingLeft: 0,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(2),
    },
  });

interface DocsProps {
  company: string;
}
interface Props extends RouteComponentProps<DocsProps>, WithStyles<typeof styles> { }

interface DocsState {
}

class QueryDocsReview extends Query<QDocsReview, QDocsReviewVariables> { }

class DocsReview extends React.Component<Props, DocsState> {
  constructor(props: Props) {
    super(props);
  }
  render = () => {
    const company = this.props.match.params.company;
    return (
      <QueryDocsReview query={DOCSREVIEW_QUERY} variables={{ company }}>
        {({ data, loading, client }) => {
          if (!data) {
            return <Loading />;
          }
          if (loading || !data.company) {
            return <Loading />;
          }
          return <div>
            {data.company.docs.items.map((d) => {
              return <div key={d.id} style={{ display: 'flex' }}>
                <a href={`/company/${company}/documents/${d.id}`} target="_blank" style={{ flex: '1' }}>
                  <img src={d.imagePages[0].imageUrl} style={{ maxWidth: '100%' }} />
                </a>
                <pre style={{ flex: '1', whiteSpace: 'pre-wrap' }}>{d.accounting}</pre>
              </div>;
            })}
          </div>;
        }}
      </QueryDocsReview>
    );
  }
}

export default withStyles(styles)(DocsReview);